import {
    _getEntityRoleBasedParams,
    saveToCache,
    GenerateProviders,
} from '@/errorHandler';
import {
    EntityDefinition,
    FetchMultipleQueryParams,
    APIMetadataResponse,
    SingleAlarmDefinition,
    SingleAlarmDefSensorComposition,
} from '@/types';

import { ExactumCache } from '@/internal';
import { SingleSensor } from '@/types';
import { RoleParams } from '@/authorization';

const providers = GenerateProviders<SingleAlarmDefSensorComposition>('alarms', {
    entity: 'Alarm',
    params: {
        attributes: [
            'id',
            'active',
            'alarmLow',
            'alarmHigh',
            'alarmOffline',
            'delayHighValue',
            'delayLowValue',
            'delayOffline',
            'delayBackToNormal',
            'subscribersLevel1',
            'subscribersLevel2',
            'subscribersLevel3',
            'relSensor',
            'relCustomer',
            'deltaInterval',
            'alarmDelta',
            'delaySubscriberLevel',
        ],
    },
});

const origFetchMultiple = providers.fetchMultiple;
const newFetchMultiple = async (q: FetchMultipleQueryParams) => {
    const data = await origFetchMultiple(q);

    const rawData = data.data as unknown as SingleAlarmDefinition[];
    // const allSensors = await ExactumCache.GetAllEntityRefs('sensor');

    data.data = rawData.map((d) => {
        return {
            relAlarm: d.id,
            relSensor: d.relSensor,
            relCustomer: d.relCustomer,
            sensorName: 'AAA',
        };
    });

    return data;
};

providers.fetchMultiple = newFetchMultiple;
providers.overrideFetchMultiple(newFetchMultiple);

const roleBasedFilters = {
    ROLE_USER: RoleParams.UserCustomer,
    ROLE_OWNER: RoleParams.UserCustomer,
};

export const AlarmDefSensorComposite: EntityDefinition<SingleAlarmDefSensorComposition> =
    {
        primaryKey: 'id',
        endpointName: 'alarms',
        isPrimaryResolver: false,
        getPrimaryKey(row: any) {
            if (row) {
                return row.id;
            }

            return 'unknown';
        },
        name: 'Alarm',
        pluralName: 'Alarms',
        routes: {
            single: 'settingsAlarmDefinitionsAddEdit',
            grid: 'settingsAlarmDefinitions',
        },
        providers,
        lookupFields: {
            title: 'sensorName',
        },
        roleBasedFilters,
    };
