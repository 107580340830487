import { Module } from 'vuex';
import { DashboardState, DashboardViewState, LatLngZoom } from '@/types';

import Vue from 'vue';

const dashboardState: DashboardState = {

    stateRestoredAt: -1,

    viewState: {
        selectedView: 'map',
        mapPosition: null,
    },

    savedViewState: null,
};

const DashboardStore: Module<any, any> = {

    state: dashboardState,

    mutations: {

        dashboardMapMoved(state: DashboardState, newPos: LatLngZoom) {
            state.viewState.mapPosition = newPos;
        },
        dashboardSelectedViewChanged(state: DashboardState, newView: 'map'|'list') {
            state.viewState.selectedView = newView;
        },

        mutation_saveDashboardViewState(state: DashboardState, newState: DashboardViewState) {
            Vue.set(state, 'savedViewState', {...newState});
        },

        mutation_restoreDashboardViewState(state: DashboardState) {
            Vue.set(state, 'viewState', {...state.savedViewState});
            state.stateRestoredAt = (new Date()).getTime();
        },
    },

    getters: {

        getDashboardSelectedView(state: DashboardState) {
            return state.viewState.selectedView;
        },

        getDashboardViewState(state: DashboardState) {
            return state.viewState;
        },

        getDashboardSavedState(state: DashboardState) {
            return state.savedViewState;
        },

        getDashboardStateRestoredNotification(state: DashboardState) {
            return state.stateRestoredAt;
        },
    },

    actions: {
        saveDashboardState({state, getters, commit}) {
            commit('mutation_saveDashboardViewState', {
                ...state.viewState,
                appMenuOpened: getters.isAppMenuOpened,
            });
        },
        restoreDashboardState({state, commit}) {
            if (state.savedViewState) {
                commit('mutation_restoreDashboardViewState');
            }
        },
    },

};

export default DashboardStore;
