import { Module } from 'vuex';
import {
    HierarchyState,
    HierarchyItemDefinition,
    HierarchyNodeType,
    MeasurementsData,
    DashboardViewState,
    HierarchyViewState,
    HierarchyContextMenuState,
    SingleSensor,
} from '@/types';

import moment from 'moment-timezone';

import Vue from 'vue';

import HierarchyBuilding, {
    RefreshHierarchyData,
    RefreshUpstreamHierarchyData,
} from '@/store/hierarchyBuilding';
import { ExactumCache } from '@/internal';

const hierarchyState: HierarchyState = {
    tree: [],

    viewState: {
        openedTabs: {},
        scrollPos: '(0,0,0)',
        appMenuOpened: true,
        focusedElement: null,
    },

    savedViewState: null,
    stateRestoredAt: -1,

    measurementsUpdatedAt: null,

    contextMenu: null,

    sensorSelectionEnabled: false,
    hierarchySensorSelectionListener: null,

    hierarchyTreeDefinitionNeedsReloading: false,
};

const HierarchyStore: Module<any, any> = {
    state: hierarchyState,

    mutations: {
        clearHierarchyViewState(state: HierarchyState) {
            state.viewState = {
                openedTabs: {},
                scrollPos: '(0,0,0)',
                appMenuOpened: true,
                focusedElement: null,
            };

            state.tree = [];
            state.savedViewState = null;
            state.hierarchyTreeDefinitionNeedsReloading = false;
            state.contextMenu = null;
        },
        setHierarchyTree(state: HierarchyState, newTree: any[]) {
            state.tree = newTree;
        },
        setFocusedElement(state: HierarchyState, focusedElement: string) {
            state.viewState.focusedElement = focusedElement;
        },
        hierarchyChildToggle(
            state: HierarchyState,
            obj: { state: boolean; path: string },
        ) {
            if (!obj.state) {
                Vue.delete(state.viewState.openedTabs, obj.path);
            } else {
                Vue.set(state.viewState.openedTabs, obj.path, true);
            }
        },
        setHierarchyScrollView(state: HierarchyState, newScroll: string) {
            state.viewState.scrollPos = newScroll;
        },
        mutation_saveHierarchyViewState(
            state: HierarchyState,
            newState: HierarchyViewState,
        ) {
            Vue.set(state, 'savedViewState', { ...newState });
        },
        mutation_restoreHierarchyViewState(state: HierarchyState) {
            Vue.set(state, 'viewState', { ...state.savedViewState });
            state.stateRestoredAt = new Date().getTime();
        },
        showContextMenu(
            state: HierarchyState,
            context: HierarchyContextMenuState,
        ) {
            Vue.set(state, 'contextMenu', context);
        },
        closeContextMenu(state: HierarchyState) {
            Vue.set(state, 'contextMenu', null);
        },
        setHierarchyTreeDefinitionNeedsReloading(
            state: HierarchyState,
            reset?: true,
        ) {
            if (reset) {
                state.hierarchyTreeDefinitionNeedsReloading = false;
            } else {
                state.hierarchyTreeDefinitionNeedsReloading = true;
            }
        },
        measurementsJustUpdated(state: HierarchyState) {
            state.measurementsUpdatedAt = +new Date() + '';
        },

        setHierarchySensorSelectionEnabled(
            state: HierarchyState,
            isEnabled: boolean,
        ) {
            state.sensorSelectionEnabled = isEnabled;
        },

        setHierarchySensorSelectionListener(
            state: HierarchyState,
            listener: (i: HierarchyItemDefinition) => void,
        ) {
            state.hierarchySensorSelectionListener = listener;
        },
    },

    getters: {
        isHierarchySensorSelectionEnabled(state: HierarchyState) {
            return state.sensorSelectionEnabled;
        },

        getHierarchySensorSelectionListener(state: HierarchyState) {
            return state.hierarchySensorSelectionListener;
        },

        hierarchyContextMenu(state: HierarchyState) {
            return state.contextMenu;
        },

        getHierarchyTree(state: any) {
            return state.tree;
        },

        getHMUNotification(state: HierarchyState) {
            return state.measurementsUpdatedAt;
        },

        getHierarchyLocations(state: HierarchyState) {
            const tree = state.tree;
            const locs = [];
            for (const c of tree) {
                if (c.children && c.children[0] && c.children[0].children) {
                    const custLocs = c.children[0].children;
                    for (const cl of custLocs) {
                        locs.push(cl);
                    }
                }
            }

            return locs;
        },

        getFocusedElement(state: HierarchyState) {
            return state.viewState.focusedElement;
        },

        getHierarchyViewState(state: HierarchyState) {
            return state.viewState;
        },

        getHierarchySavedState(state: HierarchyState) {
            return state.savedViewState;
        },

        getHierarchyStateRestoredNotification(state: HierarchyState) {
            return state.stateRestoredAt;
        },

        doesHierarchyTreeDefinitionNeedReloading(state: HierarchyState) {
            return state.hierarchyTreeDefinitionNeedsReloading;
        },

        getTreeObjectById(state: HierarchyState) {
            return (theID: string) => {
                const queue = [state.tree];
                while (queue.length > 0) {
                    const itemChildren = queue.shift();
                    if (!itemChildren || itemChildren.length === 0) {
                        continue;
                    }

                    for (const item of itemChildren) {
                        if (!item) {
                            continue;
                        }

                        if (item.data && item.data.id === theID) {
                            return item;
                        }
                        queue.push(item.children);
                    }
                }

                return null;
            };
        },
    },

    actions: {
        saveHierarchyState({ state, getters, commit }) {
            commit('mutation_saveHierarchyViewState', {
                ...state.viewState,
                appMenuOpened: getters.isAppMenuOpened,
            });
        },
        async restoreHierarchyState({ state, commit }) {
            if (state.savedViewState) {
                commit('mutation_restoreHierarchyViewState');
            } else {
                const storedState = await ExactumCache.RetrieveViewState(
                    'hierarchy',
                );
                if (storedState && Object.keys(storedState).length > 0) {
                    commit('mutation_saveHierarchyViewState', storedState);
                    commit('mutation_restoreHierarchyViewState');
                }
            }
        },

        async refreshSubtreeDataByRef({ getters }, recordRef: string) {
            const node = getters.getTreeObjectById(recordRef);
            await RefreshHierarchyData(node);
        },

        async refreshUpstreamTreeDataByRef({ getters }, recordRef: string) {
            const node = getters.getTreeObjectById(recordRef);
            await RefreshUpstreamHierarchyData(node);
        },

        async rebuildTree({ state, commit }) {
            const tree = await HierarchyBuilding.BuildHierarchyData();
            _updateNodePaths(tree, '/');

            commit('setHierarchyTree', tree);
        },

        updateSubtreeMeasurements(
            { commit, getters },
            node: HierarchyItemDefinition,
        ) {
            _updateTreeMeasurements(getters, [node]);
            commit('measurementsJustUpdated');
        },
    },
};

function _updateTreeMeasurements(
    getters: any,
    tree: HierarchyItemDefinition[],
): MeasurementsData {
    // Go one level deeper
    let tempData: MeasurementsData = {
        numAlarms: 0,
        numHiAlarms: 0,
        numLoAlarms: 0,
        numOffline: 0,
        numSensors: 0,
    };
    for (const node of tree) {
        // Convert to Promise.all() !
        let msmtData: MeasurementsData | null = null;
        if (node.nodeType === HierarchyNodeType.Sensor) {
            msmtData = _updateSensorMeasurementsData(getters, node);
        } else {
            msmtData = _updateTreeMeasurements(getters, node.children);
            Vue.set(node, 'measurementsData', msmtData);
        }
        if (msmtData) {
            tempData = _sumMeasurementsData(tempData, msmtData);
        }
    }

    return tempData;
}

function _sumMeasurementsData(d1: MeasurementsData, d2: MeasurementsData) {
    return {
        numAlarms: d1.numAlarms + d2.numAlarms,
        numHiAlarms: d1.numHiAlarms + d2.numHiAlarms,
        numLoAlarms: d1.numLoAlarms + d2.numLoAlarms,
        numOffline: d1.numOffline + d2.numOffline,
        numSensors: d1.numSensors + d2.numSensors,
    };
}

function _updateSensorMeasurementsData(
    getters: any,
    node: HierarchyItemDefinition,
): MeasurementsData {
    // Get latest measurement
    const sensorId: string = node.data.id;
    const sensorData: SingleSensor = node.data;
    const latestMsmt = getters.latestMeasurement(sensorId);

    if (latestMsmt) {
        // Construct new sensor measurements data
        // const a = moment;

        const now = moment().unix();
        const then = moment(latestMsmt.readTimestamp).unix();
        const secondsDiff = now - then;

        let numHiAlarms = 0;
        let numLoAlarms = 0;
        let numOffline = 0;

        // Senzor pošilja meritve vsakih <sensorData.frequency> sekund
        // Če meritve ne pošlje v
        //      <sensorData.frequency> + <alarmDefinition.alarmOffline> sekundah, je offline

        let freq = 60;
        let alTh = 60 * 19;
        if (sensorData.frequency) {
            freq = parseInt(sensorData.frequency + '', 10);
        }

        if (sensorData.alarmDef && sensorData.alarmDef.alarmOffline) {
            alTh = parseInt(sensorData.alarmDef.alarmOffline + '', 10);
        }

        const alarmThresholdSeconds = freq + alTh;

        if (secondsDiff > alarmThresholdSeconds) {
            numOffline++;
        }

        if (sensorData.alarmDef) {
            if (numOffline === 0 && sensorData.alarmDef.active) {
                if (latestMsmt.value > sensorData.alarmDef.alarmHigh) {
                    numHiAlarms++;
                }

                if (latestMsmt.value < sensorData.alarmDef.alarmLow) {
                    numLoAlarms++;
                }
            }
        }

        const numAlarms = Math.min(numHiAlarms + numLoAlarms + numOffline, 1);

        const data: MeasurementsData = {
            numSensors: 1,
            value: latestMsmt.value,
            rssi: latestMsmt.rssi,
            batteryStatus: latestMsmt.batteryStatus,
            readTimestamp: latestMsmt.readTimestamp,
            numHiAlarms,
            numLoAlarms,
            numOffline,
            numAlarms,
        };

        // Save new sensor measurements data
        Vue.set(node, 'measurementsData', data);

        return data;
    } else {
        return {
            numSensors: 1,
            numOffline: 1,
            numAlarms: 0,
            numHiAlarms: 0,
            numLoAlarms: 0,
        };
    }
}

function _updateNodePaths(
    tree: HierarchyItemDefinition[],
    currentPath: string,
): void {
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        node.hierarchyPath = currentPath + i;

        if (node.children) {
            _updateNodePaths(node.children, currentPath + i + '/');
        }
    }
}

export default HierarchyStore;
