import Vue from 'vue';
import './plugins/fontawesome';
import Router, { RouteConfig } from 'vue-router';

import { store, GetRouter, ExactumCache } from '@/internal';

import App from './App.vue';
import i18n from './i18n';
import Toasted from 'vue-toasted';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

import { Capitalize, Uppercase } from '@/util';

import 'bootstrap/dist/css/bootstrap.min.css';

import Icon from './components/Icon.vue';
import IButton from './components/input/IButton.vue';
import IDateTime from './components/input/IDateTime.vue';
import ICheckbox from './components/input/ICheckbox.vue';
import Loading from './components/util/Loading.vue';
import vSelect from 'vue-select';
import IForm from './components/input/IForm.vue';
import InlineLoading from './components/util/InlineLoading.vue';
import InlineResolver from './components/util/InlineResolver.vue';
import BlockResolver from './components/util/BlockResolver.vue';
import Modal from './components/util/Modal.vue';
import 'vue-select/dist/vue-select.css';
import { Settings } from 'luxon';

async function main() {
    Vue.use(Router);

    Vue.config.productionTip = false;
    Vue.use(Toasted, {
        position: 'bottom-right',
        duration: 3000,
        iconPack: 'fontawesome',
    });

    Vue.component('icon', Icon);
    Vue.component('ICheckbox', ICheckbox);
    Vue.component('IButton', IButton);
    Vue.component('IDateTime', IDateTime);
    Vue.component('Loading', Loading);
    Vue.component('v-select', vSelect);
    Vue.component('IForm', IForm);
    Vue.component('InlineLoading', InlineLoading);
    Vue.component('InlineResolver', InlineResolver);
    Vue.component('BlockResolver', BlockResolver);
    Vue.component('Modal', Modal);
    Vue.filter('capitalize', Capitalize);
    Vue.filter('uppercase', Uppercase);

    Settings.defaultLocale = 'sl';

    const baseURL = process.env.VUE_APP_API_BASE_URL;
    axios.defaults.baseURL = baseURL;

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('sw.js')
                .then((registration) => {
                    // tslint:disable-next-line: no-console
                    console.log(
                        `service worker registered succesfully ${registration}`,
                    );
                })
                .catch((err) => {
                    // tslint:disable-next-line: no-console
                    console.log(`Error registring ${err}`);
                });
        });
    } else {
        // tslint:disable-next-line: no-console
        console.log(`Service worker is not supported in this browser.`);
    }

    const instance = await ExactumCache.InitCache()
        .catch(() => {
            const engString =
                'Your browser cannot run Exactum Cloud properly. (e. IndexedDB failed to initialize)\n\nIf you are in Incognito or InPrivate browser window, try to exit it and open the app in a normal window.\n\nThere may also be a problem with your browser. Do you have the newest version?';
            const sloString =
                'Vaš brskalnik ne more pravilno izvajati aplikacije Exactum Cloud. (e. Brskalnik ni mogel pravilno nastaviti komponente IndexedDB.)\n\nČe ste v načinu Brez beleženja zgodovine (Incognito) ali v zasebnem načinu (InPrivate) poskusite odpreti aplikacijo v navadnem oknu.\n\nMožno je, da je vaš spletni brskalnik prestar (Internet Explorer ni podprt). Namestite najnovejšo različico brskalnika, ali pa uporabite Chrome / Firefox / Edge.';
            alert(engString + '\n\n-----\n\n' + sloString);
            return Promise.reject();
        })
        .then(() => {
            const router = GetRouter();

            const that = new Vue({
                router,
                store,
                i18n,
                render: (h) => h(App),
            }).$mount('#app');

            // that.$toasted.show('IndexedDB ready');
            that.$i18n.locale = 'sl_SI';

            return that;
        });

    axios.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => {
            return response;
        },
        (error: AxiosError) => {
            if (error && error.response && error.response.status === 401) {
                if (instance.$router.currentRoute.name !== 'login') {
                    store.dispatch('logout', 'EUNAUTHORIZED');

                    instance.$router.push({ name: 'login' });
                    throw new Error('EUNAUTHORIZED');
                }
            }

            throw error;
        },
    );
}

main();
