
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { HierarchyItemDefinition, LocationBarActionType, LocationBarState, SingleFormInputDefinition, SingleUserGroup } from '@/types';
import BaseFormInput from './BaseFormInput';
import HierarchyTreeView from '@/components/hierarchy/HierarchyTree.vue';
import InlineLoading from '@/components/util/InlineLoading.vue';

@Component({
    components: {
        HierarchyTreeView
    },
})
export default class UserAccessDefinitionFormInput extends BaseFormInput {

    @Prop() private dataLoaded!: boolean;

    private lockItem(nodeData: HierarchyItemDefinition) {

        const itemId = nodeData.data.id;

        const formData = this.formData as SingleUserGroup;
        const restrictions = formData.userAccessRestrictions || [];
        const idx = restrictions.indexOf(nodeData.data.id);
        if (idx < 0) {
            restrictions.push(itemId);
        } else {
            restrictions.splice(idx, 1);
        }
    }

    @Watch('dataLoaded')
    private formDataLoaded() {
        this.unwrapRestrictedItems();

        const formData = this.formData as SingleUserGroup;
        if (!formData.userAccessRestrictions) {
            this.$set(this.formData, 'userAccessRestrictions', []);
        }
    }

    /**
     * This function walks through all restricted items and unwraps them on the hierarchy, so that they are clearly visible.
     */
    private unwrapRestrictedItems() {
        const treeView = this.$refs.treeView;
        const formData = this.formData as SingleUserGroup;
        const restrictionIds = formData.userAccessRestrictions || [];
        const restrictedNodeIds: HierarchyItemDefinition[] = restrictionIds.map(id => this.$store.getters.getTreeObjectById(id)).filter(n => !!n);

        for (const node of restrictedNodeIds) {
            (treeView as any)._unwrapFocusedElement(treeView, node.hierarchyPath, 0);
        }
    }

}
