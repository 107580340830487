
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { FormulaColumnDefinition, GridFormDefinition, ReferenceColumnDefinition } from '@/types';



@Component({})
export default class FormulaColumn extends Vue {

    @Prop() public columnDefinition!: FormulaColumnDefinition;
    @Prop() public columnData!: any;
    @Prop() public formDefinition!: GridFormDefinition;

    private data: {label?: string, link?: string} | null = null;

    private mounted() {
        this.calculate();
    }

    private async calculate() {
        this.data = await this.columnDefinition.formula(this.columnData, this.formDefinition);
    }

}
