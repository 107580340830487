
import Component from 'vue-class-component';

import RenderValue from '../RenderValue.vue';
import { Prop, Vue } from 'vue-property-decorator';
import { SingleAuditLogEntry, SingleCustomer } from '@/types';
import BlockResolver from '@/components/util/BlockResolver.vue';
import InlineResolver from '@/components/util/InlineResolver.vue';
import { ExactumCache, User } from '@/internal';


@Component({
})
export default class DelaySubscriberLevelAuditExplainer extends Vue {

    @Prop() public selectedRow!: SingleAuditLogEntry;

    public get changes() {
        return (this.selectedRow as any).changes.delaySubscriberLevel;
    }

}
