import {
    HierarchyItemDefinition,
    SingleLocation,
    SinglePlace,
    SingleSensor,
} from '@/types';
import VueRouter from 'vue-router';

export default (
    format: 'grouped_current' | 'grouped_delta',
    nodeData: HierarchyItemDefinition,
    router: VueRouter,
) => {
    const recordData = nodeData.data as
        | SingleLocation
        | SinglePlace
        | SingleSensor;

    router.push({
        name: 'exportWaiter',
        params: {
            format,
            relCustomer: recordData.relCustomer,
            recordId: recordData.id,
            recordTitle: recordData.title,
        },
    });
};
