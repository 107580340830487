
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { GridColumnDefinition, ReferenceColumnDefinition, EntityDefinition, MediaObjectData } from '@/types';
import { Location } from 'vue-router';
import { Entities, MediaObject } from '@/internal';
import { ParseEntityReference, GetEntityForReference } from '@/util';
import Axios from 'axios';



@Component({})
export default class StaticLinkColumn extends Vue {

    @Prop() public columnDefinition!: ReferenceColumnDefinition;
    @Prop() public columnData!: any;


    // THIS WILL BREAK IF THE VALUE CHANGES!

    private async linkClicked() {

        const e: EntityDefinition = MediaObject;
        const er = ParseEntityReference(this.columnData[this.columnDefinition.route as string]);
        const baseUrl = Axios.defaults.baseURL;

        if (er) {
            const id = er.id;
            const object: MediaObjectData = await e.providers.fetchSingle(id);
            if (object) {
                window.open(baseUrl + object.contentUrl);
            } else {
                alert('Error fetching the link. Contact the administrator.');
            }
        } else {
            alert('Link faulted. Contact the administrator');
        }
    }

    private get getRoute() {

        const data = this.columnDefinition.route;

        if (typeof data === 'string') {

            let url = '';

            if (this.columnDefinition.routePrefix) {
                url += this.columnDefinition.routePrefix;
            }

            url += this.columnData[data];

            return url;

        } else if (typeof data === 'object') {
            const location: Location = data as Location;
            const routeObj: Location = { name: location.name, params: {} };

            if (location.params) {
                for (const key in location.params) {
                    if (location.params.hasOwnProperty(key)) {
                        const columnDataKey = location.params[key];
                        routeObj.params![key] = this.columnData[columnDataKey];
                    }
                }
            }

            return routeObj;
        }
    }

}
