import { Module } from 'vuex';
import { MeasurementsState, APIMetadataResponse, EntityReferenceString, Dictionary } from '@/types';
import { ParseEntityReference } from '@/util';

import { Measurement, Sensor } from '@/internal';

import moment from 'moment-timezone';

import Vue from 'vue';

const measurementsState: MeasurementsState = {
    sensorsLatestMeasurement: {},
};

import { ExactumCache, MeasurementLatest } from '@/internal';
import { SingleMeasurement, SingleCustomer } from '@/types';
import { GET } from '@/errorHandler';

const MeasurementsStore: Module<any, any> = {

    state: measurementsState,

    getters: {
        latestMeasurement(state: MeasurementsState) {
            return (sensorId: string) => {
                if (state.sensorsLatestMeasurement[sensorId]) {
                    return state.sensorsLatestMeasurement[sensorId];
                }

                return null;
            };
        },
    },

    mutations: {
        updateSensorLatestMeasurement(state: MeasurementsState, data: SingleMeasurement[]) {

            const mappedSensors = Object.fromEntries(data.map((sm) => ['/sensors/' + sm.relSensor, sm]));
            Vue.set(state, 'sensorsLatestMeasurement', {
                ...state.sensorsLatestMeasurement,
                ...mappedSensors,
            });
        },
    },

    actions: {

        async loadLatestCustomerMeasurements({commit}, relCustomer: string) {
            const custEr = ParseEntityReference(relCustomer);
            if (custEr) {
                const apiResp = await MeasurementLatest.providers.fetchMultiple({
                    page: 1,
                    itemsPerPage: 60,
                    relCustomer: custEr.id,
                });

                const msmts = apiResp.data;

                interface BatteryStatusResponse {
                    readTimestamp: string;
                    relCustomer: string;
                    relSensor: string;
                    status: string;
                }

                const batteryResp = await GET(`/battery_status_latests?relCustomer=${custEr.id}`, undefined,
                    {dontDeserialize: true},
                    true,
                ) as BatteryStatusResponse[];

                if (batteryResp.length) {
                    const mapped = Object.fromEntries(batteryResp.map((r) => [r.relSensor, r.status]));
                    for (const msmt of msmts) {
                        if (mapped[msmt.relSensor]) {
                            msmt.batteryStatus = mapped[msmt.relSensor];
                        }
                    }
                }

                interface RSSIResponse {
                    readTimestamp: string;
                    relCustomer: string;
                    relSensor: string;
                    value: -92;
                }

                const rssiResp = await GET(`/rssi_latests?relCustomer=${custEr.id}`, undefined,
                    {dontDeserialize: true},
                    true,
                ) as RSSIResponse[];

                if (rssiResp.length) {
                    const mapped = Object.fromEntries(rssiResp.map((r) => [r.relSensor, r.value]));
                    for (const msmt of msmts) {
                        if (mapped[msmt.relSensor]) {
                            msmt.rssi = mapped[msmt.relSensor];
                        }
                    }
                }

                commit('updateSensorLatestMeasurement', msmts);
            }
        },
    },
};

export default MeasurementsStore;
