import { GenerateProviders, GET } from '@/errorHandler';
import { EntityDefinition, SingleMediaObject } from '@/types';
import { ParseEntityReference } from '@/util';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const MediaObject: EntityDefinition = {
    primaryKey: 'contentUrl',
    name: 'MediaObject',
    pluralName: 'MediaObjects',
    endpointName: 'media_objects',
    isPrimaryResolver: true,
    routes: {
        grid: '',
        single: '',
    },
    providers: GenerateProviders('media_objects', {
        entity: 'MediaObject',
        params: {
            attributes: ['contentUrl'],
        },
    }),
    lookupFields: {
        title: ['contentUrl'],
    },
};

export async function GetImageUrl(
    mediaObjectId: string,
): Promise<string | null> {
    const metaER = ParseEntityReference(mediaObjectId);
    if (metaER) {
        const meta = (await MediaObject.providers.fetchSingle(
            metaER.id,
        )) as SingleMediaObject;
        return meta.contentUrl;
    }

    return null;
}

export async function DownloadImage(
    mediaObjectId: string,
): Promise<string | null> {
    const url = await GetImageUrl(mediaObjectId);
    if (!url) {
        return null;
    }

    const response = await axios.get(url, {
        responseType: 'arraybuffer',
    });

    return Buffer.from(response.data, 'binary').toString('base64');
}
