import { ExactumCache } from '@/internal';
import { CustomerFeature } from '@/typeDefs/features';
import { SingleCustomer, SingleSensor } from '@/types';
import { union } from 'lodash';

export const GetAllCustomerFeatures = async (relCustomer: string) => {
    const customerData: SingleCustomer | null =
        await ExactumCache.ResolveReference(relCustomer);

    if (customerData && customerData.features) {
        return customerData.features;
    }

    return [];
};

export const GetCustomerFeaturesUnionBySensorList = async (
    sensors: SingleSensor[],
): Promise<CustomerFeature[]> => {
    const customers = await Promise.all(
        sensors.map((c) => ExactumCache.ResolveReference(c.relCustomer)),
    );
    return union(...Object.values(customers).map((c) => c.features));
};
