
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SensorSignalIndicator extends Vue {
    @Prop({default: null}) private rssi!: number;

    // Mapping
    // [-90, -80]
    // [0, 100]

    private get mappedSignal() {

        if (!this.rssi) {
            return 0;
        }

        const x = Math.max(Math.min(-80, this.rssi), -90);
        const k = 10;
        const n = 900;

        return k * x + n;
    }

    private get signalIcon() {
        if (this.mappedSignal < 25) {
            return 'app/signal0';
        } else if (this.mappedSignal >= 25 && this.mappedSignal < 50) {
            return 'app/signal25';
        } else if (this.mappedSignal >= 50 && this.mappedSignal < 75) {
            return 'app/signal50';
        } else if (this.mappedSignal >= 75 && this.mappedSignal < 100) {
            return 'app/signal75';
        } else {
            return 'app/signal100';
        }
    }

}
