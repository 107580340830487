import Component from 'vue-class-component';

import Vue from 'vue';

import { Prop } from 'vue-property-decorator';

import {
    HierarchyItemDefinition,
    HierarchyNodeType,
    HierarchyContextMenuState,
    SecurityRole,
    SingleLocation,
    SinglePlace,
    ContextMenuOption,
    SingleCustomer,
    SingleAlarmDefinition,
} from '@/types';

import { ParseEntityReference } from '@/util';

import { SingleSensor } from '@/types';
import { ExactumCache } from '@/internal';
import { mapGetters } from 'vuex';
import { DateTime, Duration } from 'luxon';
import { HierarchyMenuCommand } from './hierarchyTypes';
import {
    DefaultHistoryTimeIntervalQuery,
    MaxPerciseHistoryRangeHours,
} from '@/const';
import LocationHierarchyMenuOptions from './menus/LocationHierarchyMenuItems';
import { AvailableCustomerFeatures } from '@/typeDefs/features';
import { HierarchyItemAction } from '@/typeDefs/hierarchyItemActions';
import PlaceHierarchyMenuItems from './menus/PlaceHierarchyMenuItems';
import SensorHierarchyMenuItems from './menus/SensorHierarchyMenuItems';
import CustomerHierarchyMenuItem from './menus/CustomerHierarchyMenuItem';
import { SettingsLink } from './menus/util/settingsLink';

@Component({
    computed: {
        ...mapGetters(['IsViewerUser']),
    },
})
export default class HierarchyItemBase extends Vue {
    @Prop() public nodeData!: HierarchyItemDefinition;
    @Prop() public execute!: (
        command: HierarchyMenuCommand,
        payload?: any,
    ) => boolean;

    public IsViewerUser!: boolean;
    public showChildren: boolean = false;
    public childrenLoaded: boolean = false;

    public numberOfClicks: number = 0;
    public clickCallbackTimeout: any = null;

    public loadingMeasurementData: boolean = false;
    public CanUserAccessLink!: (link: string) => boolean;

    private pollTimeout: number = -1;
    private continuePolling: boolean = false;

    private IsUserAtLeast!: (role: SecurityRole) => boolean;

    private get shallShowToggleArrow() {
        return (
            (this.nodeData.children.length > 0 &&
                this.nodeData.nodeType !== HierarchyNodeType.Sensor &&
                this.nodeData.nodeType !== HierarchyNodeType.Customer) ||
            (this.nodeData.nodeType === HierarchyNodeType.Customer &&
                this.nodeData.measurementsData &&
                this.nodeData.measurementsData.numSensors > 0)
        );
    }

    public performItemAction(actionName: HierarchyItemAction) {
        if (actionName === HierarchyItemAction.UpdateSubtreeMeasurements) {
            this.updateSubtreeMeasurements();
        } else {
            const performAction = (this.$refs.payload as any).performAction;

            if (performAction) {
                performAction(actionName);
            }
        }
    }

    private itemClicked() {
        this.doClickAction();
    }

    private doClickAction() {
        if (this.nodeData.nodeType !== HierarchyNodeType.Sensor) {
            this.toggleChildren();
        }

        if (!this.execute('hierarchyItemClicked', this.nodeData)) {
            if (this.nodeData.nodeType !== HierarchyNodeType.Sensor) {
                if (
                    (this.nodeData.nodeType === HierarchyNodeType.Location ||
                        this.nodeData.nodeType === HierarchyNodeType.Place) &&
                    this.nodeData.data.relImage
                ) {
                    // Show hierarchy image
                    this.openHierarchyImageView();
                }
            }
        }
    }

    private openHierarchyImageView() {
        const ref = ParseEntityReference(this.nodeData.data.id);
        if (ref) {
            this.$router.push({
                name: 'hierarchyImage',
                params: {
                    objectType: ref.type,
                    objectId: ref.id,
                },
            });
        }
    }

    private async openHamburgerMenuWithOptions(
        e: MouseEvent,
        opts: ContextMenuOption[],
    ) {
        const out = this;

        const customerFeatures: AvailableCustomerFeatures[] =
            await this.GetCustomerFeatures();

        const menu: HierarchyContextMenuState = {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
            options: opts
                .filter(
                    (entry) =>
                        (!entry.minRole || out.IsUserAtLeast(entry.minRole)) &&
                        (!entry.showIf ||
                            entry.showIf({ nodeData: this.nodeData })),
                )
                .filter(
                    (entry) =>
                        !entry.requiredFeature ||
                        customerFeatures.indexOf(entry.requiredFeature) >= 0,
                ),
        };

        this.$store.commit('showContextMenu', menu);
    }

    private async GetCustomerFeatures(): Promise<AvailableCustomerFeatures[]> {
        let customerRef = '';
        if (this.nodeData.nodeType === HierarchyNodeType.Customer) {
            customerRef = (this.nodeData.data as SingleCustomer).id;
        } else {
            customerRef = (this.nodeData.data as SingleLocation).relCustomer;
        }

        const customer = (await ExactumCache.ResolveReference(
            customerRef,
        )) as SingleCustomer;
        return customer.features;
    }

    private HamburgerClick(e: MouseEvent) {
        switch (this.nodeData.nodeType) {
            case HierarchyNodeType.Sensor:
                this.openHamburgerMenuWithOptions(
                    e,
                    SensorHierarchyMenuItems(this),
                );
                break;
            case HierarchyNodeType.Location:
                this.openHamburgerMenuWithOptions(
                    e,
                    LocationHierarchyMenuOptions(this),
                );
                break;
            case HierarchyNodeType.Place:
                this.openHamburgerMenuWithOptions(
                    e,
                    PlaceHierarchyMenuItems(this),
                );
                break;
            case HierarchyNodeType.Customer:
                this.openHamburgerMenuWithOptions(
                    e,
                    CustomerHierarchyMenuItem(this),
                );
                break;
            default:
                this.$router.push(this.settingsLink(this.nodeData) as string);
                break;
        }
    }

    public get settingsLink() {
        return SettingsLink;
    }

    private toggleChildren(): void {
        if (!this.shallShowToggleArrow) {
            return;
        }

        if (this.showChildren) {
            this.showChildren = false;
            this.noteChildrenOpen(false);
        } else {
            this.doShowChildren();
        }
    }

    private doShowChildren(): void {
        this.childrenLoaded = true;
        this.showChildren = true;
        this.noteChildrenOpen(true);
    }

    private noteChildrenOpen(state: boolean) {
        const id = this.nodeData.hierarchyPath + ':' + this.nodeData.data.id;
        this.$store.commit('hierarchyChildToggle', { path: id, state });
    }

    private showChart(): void {
        this.$router.push({
            name: 'chart',
            params: {
                sensorId: Math.floor(Math.random() * 10) + '',
            },
        });
    }

    private async mounted() {
        if (this.nodeData.nodeType === HierarchyNodeType.Customer) {
            // Load this measurements
            await this.updateSubtreeMeasurements();
        }
    }

    private async updateSubtreeMeasurements() {
        this.loadingMeasurementData = true;
        const customerData: SingleCustomer = this.nodeData.data;

        if (
            this.nodeData.measurementsData &&
            this.nodeData.measurementsData.numSensors === 0
        ) {
            return;
        }

        await this.$store.dispatch(
            'loadLatestCustomerMeasurements',
            customerData.id,
        );
        await this.$store.dispatch('updateSubtreeMeasurements', this.nodeData);

        let pollInterval = 120;
        if (this.IsViewerUser) {
            pollInterval = 10;
        }

        clearTimeout(this.pollTimeout);
        this.pollTimeout = setTimeout(
            (() => {
                this.updateSubtreeMeasurements();
            }).bind(this),
            pollInterval * 1000,
        ) as unknown as number;

        this.loadingMeasurementData = false;
    }

    private beforeDestroy() {
        clearTimeout(this.pollTimeout);
    }
}
