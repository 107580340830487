import { AxiosError, AxiosResponse } from 'axios';

import { Location } from 'vue-router';

import { Moment } from 'moment';
import SingleForm from './components/input/forms/BaseSingleForm';
import { DateTimeInterval } from './components/dygraphs/dygraphsTypes';
import { DateTime } from 'luxon';
import {
    AvailableCustomerFeatures,
    CustomerFeature,
} from './typeDefs/features';

export type GUID = string;
export type EntityReferenceString = string;
export type DateTimeString = string;

export const DashboardViewTypes = ['list', 'map'];

export interface RootModules {
    LoginStore: LoginStoreState;
    AppMenusStore: AppMenusStoreState;
    LoadingStore: LoadingStoreState;
    HierarchyStore: HierarchyState;
    MeasurementsStore: MeasurementsState;
    DashboardStore: DashboardState;
    MeasurementHistoryStore: MeasurementHistoryState;
    RecentAlarmsStore: RecentAlarmsState;
}

export interface LoadingStoreState {
    form: LoadingInfo;

    init: boolean;
    initForceFullReload: boolean;
    initLoginChange: {
        type: LoginChangeType;
    } | null;
    initLoadingReason: string;
}

export type LoginChangeType = 'login' | 'logout';

export interface AppMenusStoreState {
    topBarMenu: TopBarMenuInfo;
    showAppMenu: boolean;
    locationBar: LocationBarState;
    appLoading: number;
    appLoadingPercents: number;
}

export interface LoginStoreState {
    inactivityWatchdog: null | number;
    loginInfo: LoginInfo;

    inactivityWatchdogOverrideEnabled: boolean;

    currentUserCustomerFeatures: AvailableCustomerFeatures[];

    currentUserInfo: null | SingleUser;
}

export type ErrorHandlerFunction = (error: AxiosError) => Promise<any>;

export interface Dictionary<T> {
    [key: string]: T;
}

export interface SensorAlarmInfo {
    id: string;
    alarmHigh: boolean;
    alarmLow: boolean;
    offline: boolean;
}

export interface MeasurementsState {
    sensorsLatestMeasurement: Dictionary<SingleMeasurement>;
}

export interface IdObject {
    id: string;
}

export interface MeasurementHistoryState {
    chosenSensors: IdObject[];
    hierarchySensorSelectorOpened: boolean;
    chosenDateInterval: DateTimeInterval;

    lastRefresh: number;
}

export interface RecentAlarmsState {
    recentAlarmsViewEnabled: boolean;
    recentAlarms: SingleAlarmEvent[];

    recentAlarmsSummary: AlarmEventSummary;

    lastAlarmEventOccuredAt: number;

    lastAlarmPollAt: number | null;
    alarmPollingEnabled: boolean;

    alarmsAlreadyShownCache: string[];
}

export interface AlarmEventSummary {
    lower: number;
    upper: number;
    offline: number;
}

export enum AlarmEventType {
    UPPER = 'high_value',
    LOWER = 'low_value',
    OFFLINE = 'offline',
    NORMAL = 'normal',
}

export interface ViolationEntry {
    propertyPath: string;
    message: string;
}

export class ValidationError extends Error {
    public violations: ViolationEntry[] = [];
}

export interface TopBarMenuInfo {
    user: boolean;
    settings: boolean;

    [key: string]: boolean;
}

export interface LocationBarState {
    text?: string;
    entity?: EntityDefinition | null;
    recordId?: string;
    actions?: LocationBarAction[];
}

export interface LocationBarAction {
    type: LocationBarActionType;
    text?: string;
    icon?: string;
    func?: () => void;
    options?: any;
    securityOverride?: {
        minRole?: SecurityRole;
        excludingFeature?: CustomerFeature;
    };
}

export enum LocationBarActionType {
    New = 'create',
    Delete = 'delete',
    Update = 'update',
    Deactivate = 'deactivate',
    Activate = 'activate',
    Custom = 'custom',
    GridExportPDF = 'gridExportPDF',
    GridExportXLSX = 'gridExportXLSX',
}

export interface TokenResponse {
    token: string;
}

export interface DefaultSettings {
    loginInfo: LoginInfo;
}

export interface UserInfo {
    id?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    roles?: string[];
    relCustomer?: string;
    password?: string;
}

export interface TokenInfo {
    exp: number;
    lat: number;
    roles: string[];
    email: string;
    userId: string;
    customerId: string;
    features: string[];
}

export interface LoginInfo {
    user: UserInfo;
    success: boolean;
    token?: string;
    error?: string;
    timeout?: any;
}

export interface EntityReference {
    type: string;
    id: string;
    singular: string;
}

export interface Position {
    x: number;
    y: number;
}

export interface Dimensions {
    width: number;
    height: number;
}

export interface AccessRightDefinition {
    [key: string]: SecurityRole;

    read: SecurityRole;
    create: SecurityRole;
    update: SecurityRole;
    delete: SecurityRole;
    hierarchySettings: SecurityRole;
}
export interface AccessRightsRootState {
    entities: { [key: string]: AccessRightDefinition };
}

export type EntityPluginStepMessage =
    | 'LoadSingle'
    | 'LoadMultiple'
    | 'LoadAll'
    | 'Update'
    | 'Delete'
    | 'Create';
export type EntityPluginStepStage = 'Pre' | 'Post' | 'Core';

export interface EntityPluginStep<T = any> {
    message: EntityPluginStepMessage;
    stage: EntityPluginStepStage;
    priority: number;
    action: (data: T) => Promise<T>;
}

export interface EntityDefinition<R = any> {
    primaryKey: string;
    /** Application entity name - make sure it's properly capitalized.
     * Example: User, UserGroup, Customer */
    name: string;

    /** Application entity's plural name - make sure it's properly capitalized.
     * Example: Users, UserGroups, Customers */
    pluralName: string;

    /**
     * API Endpoint. Used when plural name doesn't match the API endpoint name.
     */
    endpointName: string;

    /** The app contains many entities that source data from the same endpoint.
     * When resolving a reference by ref /<endpointname>/<id> it's important to decide
     * which entity is the primary resolver that will handle the request.
     */
    isPrimaryResolver: boolean;
    routes: {
        single: string;
        grid: string;
    };
    routeBase?: string;
    providers: DataProviders<R>;
    relationships?: RelationshipDefinition[];
    getPrimaryKey?: (row: any) => string;
    lookupFields: {
        // Strings here represent data row column mappings
        // Each string will map to the row field which has a name specified
        // in the value
        value?: string;
        title: string | string[]; // Primary string, title in the box
        detailLeft?: string | string[]; // smaller font under the title on the left
        detailRight?: string | string[];
        icon?: string;
    };
    settingsDisabled?: boolean;
    roleBasedFilters?: {
        [key in SecurityRole]?: () => Promise<{
            [key: string]: string | number;
        }>;
    };
    fetchMultipleOverrides?: SecurityRole[];
}

export interface EntityAttributesCollection {
    [key: string]: EntityAttribute;
}

export interface EntityAttribute {
    type: AttributeType;
    name: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    security?: {
        // Default: RoleUser
        minReadRole?: SecurityRole;
        // Default: RoleOwner
        minWriteRole?: SecurityRole;
    };
}

export interface OptionSetEntityAttribute extends EntityAttribute {
    options: string[];
    multiple: boolean;
}

export enum AttributeType {
    GUID = 'GUID',
    EntityReference = 'EntityReference',
    Text = 'Text',
    Number = 'Number',
    OptionSetValue = 'OptionSetValue',
    LocalizedOptionSetValue = 'OptionSetValue',
    DateTime = 'DateTime',
    EntityReferenceCollection = 'EntityReferenceCollection',
}

export interface RelationshipDefinition {
    id: string;
    foreignId: string;
    entity: EntityDefinition;
}

export interface DataProviders<R = any, I = any> {
    serializationData?: { entity: string };
    fetchSingle: (id: string) => Promise<R>;
    fetchMultiple: (
        q: FetchMultipleQueryParams,
    ) => Promise<APIMetadataResponse<R>>;
    fetchAll: (
        q: FetchMultipleQueryParams,
        progress?: ProgressTrackerData,
    ) => Promise<APIMetadataResponse<R>>;
    update: (id: string, data: any) => Promise<R>;
    delete: (id: string) => Promise<IdObject>;
    create: (data: any) => Promise<R>;

    export?: (q: FetchMultipleQueryParams) => Promise<APIMetadataResponse<R>>;

    overrideFetchMultiple?: any;
}

export interface MeasurementDataProviders extends DataProviders {
    fetchLatest(
        relCustomer: EntityReferenceString,
    ): Promise<APIMetadataResponse<SingleMeasurement>>;
}

export interface ProgressTrackerData {
    key: string;
    object: any;

    minVal?: number | null;
    maxVal?: number | null;
    currentVal?: number | null;
}

export interface FetchMultipleQueryParams {
    page: number;
    itemsPerPage: number;
    [key: string]: string | number;
}

export interface PaginationLinks {
    first: string;
    last: string;
    next?: string;
    prev?: string;
    self: string;
}

export interface PaginationInfo {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
}

export interface APIMetadataResponse<R = any> {
    links: PaginationLinks;
    meta: PaginationInfo;
    data: R[];
}

export interface LoadingInfo {
    isLoading: boolean;
    loadingFailed?: boolean;

    progress?: number;

    loadingText?: string;
    loadingFailureText?: string;
}

export interface HierarchyItemDefinition {
    nodeType: HierarchyNodeType;
    data: any;
    children: any[];
    measurementsData?: MeasurementsData;
    hierarchyPath?: string;
    parent: HierarchyItemDefinition | null;
}

export interface MeasurementsData {
    numSensors: number;
    numAlarms: number;
    numHiAlarms: number;
    numLoAlarms: number;
    numOffline: number;
    value?: string;
    rssi?: number;
    batteryStatus?: string;
    readTimestamp?: string;
}

export interface HierarchyState {
    tree: HierarchyItemDefinition[];

    viewState: HierarchyViewState;
    savedViewState: HierarchyViewState | null;
    stateRestoredAt: number;

    contextMenu: HierarchyContextMenuState | null;

    hierarchyTreeDefinitionNeedsReloading: boolean;

    measurementsUpdatedAt: string | null;

    sensorSelectionEnabled: boolean;

    hierarchySensorSelectionListener:
        | null
        | ((i: HierarchyItemDefinition) => void);
}

export interface HierarchyContextMenuState {
    position: {
        x: number;
        y: number;
    };
    options: ContextMenuOption[];
}

export interface ContextMenuOption {
    label: string;
    action: any;
    icon?: string;
    minRole?: SecurityRole;
    requiredFeature?: AvailableCustomerFeatures;
    showIf?: (context: { nodeData: HierarchyItemDefinition }) => boolean;
}

export interface DashboardState {
    viewState: DashboardViewState;
    savedViewState: DashboardViewState | null;
    stateRestoredAt: number;
}

export interface DashboardViewState {
    selectedView: 'map' | 'list';
    mapPosition: LatLngZoom | null;
}

export interface HierarchyViewState {
    scrollPos: string;
    openedTabs: { [key: string]: boolean };
    appMenuOpened?: boolean;
    focusedElement: string | null;
}

export interface LatLngZoom {
    lat: number;
    lng: number;
    zoom: number;
}

export enum HierarchyNodeType {
    Top = 'TopHierarchyItem',
    Location = 'LocationHierarchyItem',
    Place = 'PlaceHierarchyItem',
    Sensor = 'SensorHierarchyItem',
    Customer = 'CustomerHierarchyItem',
}

/***
 * FORM DEFS
 */
export interface FormInput {
    id: string;
    type: string;
    placeholder?: string;
}

export enum FormType {
    Grid = 'GridForm',
    Single = 'SingleForm',
    AlarmingSettings = 'AlarmingSettingsForm',
    MasterDetail = 'MasterDetailForm',
}

export interface FormDefinition {
    subtitle?: string;
    title?: string;
    type: FormType;
    role?: string;
    routes?: {
        single: string | null;
        grid: string | null;
    };

    locationBar?: {
        actions?: LocationBarAction[];
    };
}

/****
 * GRID FORM DEFS
 */
export enum GridColumnType {
    Text = 'TextColumn',
    Reference = 'ReferenceColumn',
    LocalizedText = 'LocalizedTextColumn',
    StaticLinkColumn = 'StaticLinkColumn',
    Progress = 'ProgressColumn',
    DateTime = 'DateTimeColumn',
    MediaDownload = 'MediaDownloadColumn',
    MappedValueColumn = 'MappedValueColumn',
    Checkbox = 'CheckboxColumn',
    Formula = 'FormulaColumn',
}

export interface GridColumnDefinition {
    name: string;
    label: string;
    type: GridColumnType;
    exactumIcon?: string;
    fontAwesomeIcon?: string;
    security?: {
        minRole: SecurityRole;
    };
    sortable?: true;
    defaultSort?: OrderDirection;
    sortKey?: string;
    pdfExportWidth?: number;
    requiredFeature?: CustomerFeature[];
}

export interface MappedGridColumnDefinition extends GridColumnDefinition {
    mapValue: (value: any, context: DynamicFormTitleContext) => any;
}

export type OrderDirection = 'DESC' | 'ASC';

export interface GridOrderSettings {
    enabled: boolean;
    orderBy: string;
    orderDirection: OrderDirection;
}

export interface AlarmingSettingsFormDefinition extends FormDefinition {
    relSensor: string | (() => string | null) | null;
}

export interface GridFormDefinition extends FormDefinition {
    hidePagination?: boolean;
    columns: GridColumnDefinition[];
    filters?: SingleFormInputDefinition[];
    dynamicTitle?: (context: DynamicFormTitleContext) => Promise<string>;
    defaultQueryParams?: { [key: string]: string };
    filteringAlwaysOn?: boolean;
    filterLayout?: GridFormFilterLayout;
    adjustableColumns?: { [key: string]: GridFormAdjustableColumnDefinition };
    expand?: QueryExpandRule[];
}

export interface QueryExpandRule {
    expandColumn: string;
    toKey?: string;
}

export interface DynamicFormTitleContext {
    filterData: any;
    formDefinition: FormDefinition;
    formData: any;
}

export enum GridFormFilterLayout {
    Simple,
    RowUntilBreak,
}

export interface GridFormAdjustableColumnDefinition {
    default?: boolean;
    always?: true;
}

/*****
 * GRID COLUMN TYPES DEF
 */
export interface ReferenceColumnDefinition extends GridColumnDefinition {
    routePrefix?: string;
    route: string | Location;
    resolveLabel?: boolean;
    referencePrefix?: string;

    emptyLabelText?: string;
}

export interface FormulaColumnDefinition extends GridColumnDefinition {
    formula: (
        rowData: any,
        formDefinition: GridFormDefinition,
    ) => Promise<{ label: string; link?: string }>;
}

export interface DateTimeColumnDefinition extends GridColumnDefinition {
    format?: string;
}

export interface CheckboxColumnDefinition extends GridColumnDefinition {
    onSelect?: (value: boolean, row: any) => any;
}

/*****
 * SINGLE FORM DEFS
 */
export interface SingleFormDefinition extends FormDefinition {
    inputs: SingleFormInputDefinition[];
    actions: FormActionDefinition[];
}

export interface FormActionDefinition<T = any> {
    label?: string;
    name: string;
    icon?: string;
    route?: any;
    action?: (context: FormActionContext<T>) => void;
    showIf?: (context: FormActionContext<T>) => boolean;
    security?: {
        minRole: SecurityRole;
    };
}

export enum SingleFormInputType {
    Text = 'TextFormInput',
    Textarea = 'TextareaFormInput',
    Boolean = 'BooleanFormInput',
    Lookup = 'LookupFormInput',
    MultiLookup = 'MultiLookupFormInput',
    Splitter = 'SplitterFormInput',
    Number = 'NumberFormInput',
    MapLocation = 'MapLocationFormInput',
    FileUpload = 'FileUploadFormInput',
    OptionSet = 'OptionSetFormInput',
    TaggableOptionSet = 'TaggableOptionSetFormInput',
    LocalizedOptionSet = 'LocalizedOptionSetFormInput',
    PlaceholderTitleText = 'PlaceholderTitleTextFormInput',
    Title = 'TitleFormInput',
    UnitNumber = 'UnitNumberInput',
    ConstUnitNumber = 'ConstUnitNumberFormInput',
    DateTime = 'DateTimeFormInput',
    Duration = 'DurationFormInput',
    AlarmReceivers = 'AlarmReceiverFormInput',
    CompoundNumberFilter = 'CompoundNumberFilterInput',
    CompoundLookupFilter = 'CompoundLookupFormInput',

    FeaturesSelect = 'FeaturesSelectFormInput',
    UserAccessDefinition = 'UserAccessDefinitionFormInput',
}

export enum SecurityRole {
    RoleViewer = 'ROLE_VIEWER',
    RoleUser = 'ROLE_USER',
    RoleOwner = 'ROLE_OWNER',
    RoleAdmin = 'ROLE_ADMIN',
    RoleGOD = 'ROLE_GOD',
    RoleQAManager = 'ROLE_QA_MANAGER',
    RoleNoAccess = 'ROLE_NO_ACCESS',
    RoleOwnerPlus = 'ROLE_OWNER_PLUS',
}

export interface FormActionContext<T> {
    record: T;
    form: SingleForm;
}

export interface SingleUser {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    roles: string[];
    relCustomer: EntityReferenceString;
    timezone: string;
    locale: string;
    adminCustomerIds?: string[];
    relUserGroup?: string;

    dashboard: DashboardConf;
}

export interface SingleCustomer extends SingleEntityRecord {
    title: string;
    erpId: string;
    notes: string;
    username: string;
    password: string;

    features: AvailableCustomerFeatures[];
}

export interface SingleUserGroup extends SingleEntityRecord {
    title: string;
    relCustomer: string;
    userAccessRestrictions: string[];
}

export interface APIUserGroup {
    id?: string;
    title: string;
    relCustomer: string;
    relUsers: [];
    entities: [
        {
            locations: string[];
            places: string[];
            sensors: string[];
        },
    ];
}

export interface SingleFormInputDefinition<T = any> {
    type: SingleFormInputType;
    name: string;
    label?: string;
    description?: string;
    required?: boolean;
    defaultIfDisabled?: (context: {
        userData: UserInfo;
        additionalUserInfo: SingleUser;
    }) => any;
    placeholder?: string;
    security?: {
        // Default: RoleUser
        minReadRole?: SecurityRole;
        // Default: RoleOwner
        minWriteRole?: SecurityRole;
        requiredFeatures?: AvailableCustomerFeatures[];
    };
    rowBreak?: true;
    renderIf?: (context: FormActionContext<T>) => boolean;
    visibleIf?: (context: FormActionContext<T>) => boolean;
    modifyFormValue?: (context: FormActionContext<T>) => void;

    /**
     * Inside this block, you can do anything.
     * This function does not cause reactive side effects,
     * but rather modifies the outputs before they are sent
     * to the server.
     */
    changeOutput?: (value: any) => any;
}

export type AlarmNotificationType = 'email' | 'sms' | '$delete';

export interface AlarmReceiversDefinition {
    userId: string;
    notifications: AlarmNotificationType[];
}

export interface MediaObjectData {
    contentUrl: string;
}

export interface UnitInput extends SingleFormInputDefinition {
    unitRef: string;
}

export interface ConstUnitInput extends SingleFormInputDefinition {
    sensorMeta: SingleSensor;
}

export interface LookupFilterContext<T> {
    thisLookupOption: {
        label: string;
        code: string;
    };
    thisRecord: T;
    formData: any;
    relatedFields: {
        [relatedName: string]: any;
    };
    form: any;
    loginInfo: LoginInfo;
}

export interface LookupFormInputDefinition<T>
    extends SingleFormInputDefinition {
    entity: EntityDefinition;
    mapOptions?: (option: T) => { code: string; label: string };
    multiple?: true;
    filter?: {
        resolve?: string[];
        track?: string[];
        conditions: Array<(context: LookupFilterContext<T>) => boolean>;
    };
}

export interface OptionSetFormInputContext<T> {
    record: T;
}

export interface OptionSetOption {
    code: string;
    label: string;
}

export interface OptionSetFormInputDefinition
    extends SingleFormInputDefinition {
    options:
        | string[]
        | OptionSetOption[]
        | (<T>(context: OptionSetFormInputContext<T>) => Promise<string[]>)
        | (<T>(
              context: OptionSetFormInputContext<T>,
          ) => Promise<OptionSetOption[]>);
    multiple: boolean;
    translateOptions?: boolean;
}

export const FilterOperator = {
    Equal: 'eq',
};

export const NumberFilterOperator: { [key: string]: string } = {
    ...FilterOperator,
    GreaterThan: 'gt',
    GreaterOrEqualThan: 'ge',
    LowerThan: 'lt',
    LowerOrEqualThan: 'le',
    Null: 'null',
    NotNull: 'notnull',
};

export const StringFilterOperator = {};

export const PlaceFilterOperator = {
    ThisPlaceOrSubplaces: 'thisPlaceOrSubplaces',
    ExactlyThisPlace: 'exactlyThisPlace',
};

export interface CompoundNumberFilterFormInputDefinition
    extends SingleFormInputDefinition {
    operators: { [key: string]: string };
    excludedOperators: string[];
}

export interface CompoundLookupFormInputDefinition<T>
    extends LookupFormInputDefinition<T> {
    operators: { [key: string]: string };
    excludedOperators: string[];
}

export enum AccessRightScope {
    Settings = 'settings',
    HierarchySettings = 'hierarchySettings',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Download = 'download',

    ChangeImageCoordinates = 'changeImageCoordinates',
}

export interface EntityNames {
    pluralUppercase: string;
    pluralLowercase: string;
    singularUppercase: string;
    singularLowercase: string;

    routeBase: string;
}

////////////////////////////
///////////////////////////

export interface SingleAlarmSubscriberInfo {
    [userId: string]: AlarmingChannelType;
}

export type AlarmingChannelType = 'sms' | 'email';

export interface SingleAlarmDefinition {
    Id: string;
    id: string;
    active: boolean;
    alarmLow: number;
    alarmHigh: number;
    alarmOffline: number;
    delayHighValue: number;
    delayLowValue: number;
    delayOffline: number;
    delayBackToNormal: number;
    subscribersLevel1: AlarmReceiversDefinition[];
    subscribersLevel2: AlarmReceiversDefinition[];
    subscribersLevel3: AlarmReceiversDefinition[];
    relSensor: string;
    relCustomer: string;

    deltaInterval: number;
    alarmDelta: number;

    sensorName?: string;
    delaySubscriberLevel?: AlarmSubscriberLevelDelay[];
}

export interface SingleAlarmDefSensorComposition {
    relAlarm: string;
    relSensor: string;
    relCustomer: string;

    sensorName: string;
}

export interface AlarmSubscriberLevelDelay {
    level: 2 | 3;
    delay: number;
}

export interface SingleAlarmBulkEditDefinition {
    Id?: string;
    id?: string;
    active?: boolean;
    alarmLow?: number;
    alarmHigh?: number;
    alarmOffline?: number;
    delayHighValue?: number;
    delayLowValue?: number;
    delayOffline?: number;
    delayBackToNormal?: number;
    subscribersLevel1?: AlarmReceiversDefinition[];
    subscribersLevel2?: AlarmReceiversDefinition[];
    subscribersLevel3?: AlarmReceiversDefinition[];
    relSensor: string;
}

export interface SingleAlarmEvent {
    id: string;
    _id: string;
    _type: string;
    initialValue: number;
    latestValue: number;
    createdTimestamp: string;
    updatedTimestamp: string;
    finishedTimestamp: string;
    confirmedTimestamp: string;
    reviewedTimestamp: string;
    confirmationNote: string;
    reviewNote: string;
    notifications: [];
    relConfirmationUser: string;
    relReviewUser: string;
    relAlarm: string;
    relCustomer: string;

    qaApprovedTimestamp: string;
    relQaApproveUser: string;
    qaApprovalNote: string;

    sensorMeta?: SingleSensor;
}

export interface SensorUnitDefinitionCollection {
    [sensorType: string]: SingleSensorUnitDefinition;
}

export interface SingleSensorTypeMeta {
    sensorType: string;
    data: SingleSensorUnitDefinition;
}

export interface SingleSensorUnitDefinition {
    baseUnit: string;
    units: {
        [unitName: string]: SingleChildUnitDefinition;
    };
}

export interface SingleChildUnitDefinition {
    symbol: string;
    conversion: string;
    reverseConversion: string;

    fromBase?: (value: number) => number;
    toBase?: (value: number) => number;
}

export interface SingleSensor extends SingleEntityRecord {
    title: string;

    unit: string;
    presentationUnit: string;

    Type: string;
    relPlace: string;

    frequency: number;
    deviceIds: string[];
    position: string;
    relCustomer: string;

    noAccessUsers: string[];

    titleInternal: string;

    alarmDef?: SingleAlarmDefinition;

    corrections: SingleCorrectionsMeta[];

    gmpEnabled: boolean;
}

export interface SingleCertificate extends SingleCorrectionsMeta {
    relSensor: string;
    relPlace: string;
}

export interface SingleCorrectionsMeta {
    inspectionId: number;
    inspectionDeadline: string;
    certificateId: string;
    valid: boolean;
    unit: string;
    values: SingleCorrectionsValue[];
    status: APICertificateStatus | AppCertificateStatus;
}

export type APICertificateStatus =
    | 'renewal'
    | 'extension'
    | 'valid'
    | 'expired'
    | null;

export type AppCertificateStatus =
    | 'inspections.unknownStatus'
    | 'inspections.expired'
    | 'inspections.valid'
    | 'inspections.expiresShortly'
    | 'inspections.recentlyExpired'
    | 'inspections.extensionRequested'
    | 'inspections.renewalRequested'
    | null;

export interface SingleCorrectionsValue {
    nominal: number;
    error: number;
}
export interface SubmitViolations {
    violations: InputViolation[];
}

export interface InputViolation {
    message: string;
    propertyPath: string;
}

export interface SingleMeasurement {
    id: string;
    readTimestamp: string;
    value: string;
    relSensor: GUID;
    relCustomer: EntityReferenceString;

    rssi?: number;
    batteryStatus?: string;
}

export interface SingleRSSIRecord {
    relSensor: string;
    relCustomer: string;
    readTimestamp: string;
    value: number;
}

export interface SingleBatteryStatusRecord {
    relSensor: string;
    relCustomer: string;
    readTimestamp: string;
    value: string;
}

export interface SingleMeasurementExport {
    'title': string;
    'locale': string;
    'format': string;
    'frequency': string;
    'startTimestamp': DateTimeString;
    'endTimestamp': DateTimeString;
    'relUser': EntityReferenceString;
    'relLocation'?: EntityReferenceString;
    'relExports'?: EntityReferenceString[];
    'relCustomer': EntityReferenceString;
    'relSensors'?: EntityReferenceString[];
    'timezone'?: string;

    'settings.exportingDisabled'?: boolean;
    'settings.xlsxInterval'?: number;
}

export interface SingleMediaObject {
    contentUrl: string;
}

export interface SingleEntityRecord {
    id: EntityReferenceString;
}

export interface SinglePlace extends SingleEntityRecord {
    title: string;
    position: string;
    relImage: EntityReferenceString;
    parent: EntityReferenceString;
    noAccessUsers: EntityReferenceString[];
    relLocation: EntityReferenceString;
    relCustomer: EntityReferenceString;

    gmpEnabled: boolean;
}

export interface SingleLocation extends SingleEntityRecord {
    title: string;
    address: string;
    latLon: string;
    relImage: EntityReferenceString;
    noAccessUsers: EntityReferenceString[];
    relManager: EntityReferenceString;
    relCustomer: EntityReferenceString;
    gmpEnabled: boolean;
}

export interface SingleAuditLogEntry extends SingleEntityRecord {
    relCustomer: string;
    timestamp: string;
    action: string;
    entityType: string;
    data: SingleAuditLogAdditionalData;
}

export interface SingleFlatAuditLogEntry extends SingleEntityRecord {
    relCustomer: string;
    timestamp: string;
    action: string;
    entityType: string;
    data?: SingleAuditLogAdditionalData;
    entityId?: string;
    userId?: string;
    userIp?: string;
    changes?: any;
}

export interface SingleAuditLogAdditionalData {
    entityId: string;
    userId: string;
    userIp: string;
    changes: any;
}

export interface SingleMeasurementAverage {
    ts: string;
    min: number | string;
    max: number | string;
    avg: number | string;
}

export interface SingleSensorAlarmDefinitionComposition
    extends SingleEntityRecord {
    title: string;
    unit: string;
    Type: string;
    relSensor: string;

    relPlace: string;
    placeHierarchy: string;
    relLocation: string;
    relCustomer: string;

    frequency: number;

    relAlarm: string;
    active: boolean;
    alarmLow: number;
    alarmHigh: number;
    alarmOffline: number;
    delayHighValue: number;
    delayLowValue: number;
    delayOffline: number;
    delayBackToNormal: number;

    subscribersLevel1: AlarmReceiversDefinition[];
    subscribersLevel2: AlarmReceiversDefinition[];
    subscribersLevel3: AlarmReceiversDefinition[];
}

export interface SingleMeasurementNote extends SingleEntityRecord {
    note: string;
    createdTimestamp: string;
    startTimestamp: string;
    endTimestamp: string;
    relSensor: string;
    relCustomer: string;
    relUser: string;
}

/**
 * DASHBOARDS
 */

export interface DashboardConf {
    uiSettings: DashboardUiSettings;
    blocks: DashboardBlockDef[];
}

export interface DashboardUiSettings {
    enableUserAudibleNotificationsManagement: boolean;
    enableUserVisualNotificationsManagement: boolean;
    enableUserAudibleNotifications: boolean;
    enableUserVisualNotifications: boolean;
    disableHierarchy: boolean;
}

export enum DashboardBlockAlarmState {
    Off = 0,
    Active = 1,
    Stale = 2,
}

export interface DashboardBlockDef {
    id?: string;
    type: DashboardBlockType;
    [k: string]: any;
}

export interface SensorDashBlockDef extends DashboardBlockDef {
    sensorId: string;
}

export enum DashboardBlockType {
    SensorDashBlock = 'SensorDashBlock',
}

export interface IDashboardEditor {
    moveItemLeft(d: DashboardBlockDef): void;
    moveItemRight(d: DashboardBlockDef): void;
    removeItem(d: DashboardBlockDef): void;
    addNewBlock(): void;
}

export interface DygraphsLegendData {
    dygraph: Dygraph;
    series: DygraphSeries[];
    x: number | undefined;
    /** Timestamp in a nice string */
    xHTML: string;
}

export interface DygraphSeries {
    /** Color of the graph */
    color: string;
    isVisible: boolean;

    /** Sensor name */
    label: string;

    /** Value */
    y: number;

    /** Formatted value */
    yHTML: number;
}

export interface ChartPoint {
    color: string;
    label: string;
    value: number;
    formattedValue: string | number;
}

export interface MeasurementDetails {
    series: ChartPoint[];
    notes: SingleChartNote[];
    timestamp: number | undefined;
    formattedTimestamp: string;
}

export interface SingleChartNote {
    id: string;
    note: string;
    createdTimestamp: DateTime;
    from: DateTime; // time millis
    to: DateTime; // time millis
    relUser: string;
    relUserName: string;
}

export interface APIMeasurementNote {
    createdTimestamp: string;
    endTimestamp: string;
    startTimestamp: string;
    id: string;
    note: string;
    relCustomer: string;
    relSensor: string;
    relUser: string;
}
