import { DateTime } from 'luxon';

export const CertificateExtensionPeriodDays = 31;

export const MaxPerciseHistoryRangeHours = 4 * 24 + 1; // 4 days and one hour

export const DefaultHistoryTimeInterval = () => ({
    from: DateTime.now().minus({ hours: MaxPerciseHistoryRangeHours }),
    to: DateTime.now(),
});

export const DefaultHistoryTimeIntervalQuery = () => ({
    from:
        DateTime.now()
            .minus({ hours: MaxPerciseHistoryRangeHours })
            .toMillis() + '',
    to: DateTime.now().toMillis() + '',
});
