var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row align-items-center sensor-hierarchy-item-wrap"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"hierarchy-sensor-item-icon"},[_c('icon',{attrs:{"icon":_vm.sensorIcon,"width":"40"}})],1),(
                !_vm.isOffline &&
                    _vm.nodeData.measurementsData &&
                    _vm.nodeData.measurementsData.rssi
            )?_c('SensorSignalIndicator',{attrs:{"rssi":_vm.nodeData.measurementsData.rssi}}):_vm._e()],1),_c('div',{staticClass:"hierarchy-sensor-item-text text-nowrap text-left font-weight-normal ml-2"},[_c('div',{staticClass:"hierarchy-sensor-item-text-first-line hierarchy-sensor-name"},[_vm._v(" "+_vm._s(_vm.nodeData.data.title)+" ")]),_c('div',{staticClass:"hierarchy-sensor-item-text-second-line hierarchy-sensor-item-position-name ml-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.nodeData.data.Type))+" ")]),_c('div',{staticClass:"hierarchy-sensor-item-text-third-line hierarchy-sensor-item-value font-weight-bold ml-1 d-flex flex-row"},[(!_vm.isOffline)?_c('div',{},[_vm._v(" "+_vm._s(_vm.sensorValue)+" "+_vm._s(_vm.sensorUnit)+" ")]):_vm._e(),(_vm.nodeData.data.corrections.length)?_c('div',{staticClass:"ml-2"},[_c('icon',{attrs:{"icon":"app/exactum_certificate","width":"20"}})],1):_vm._e(),(_vm.nodeData.measurementsData)?_c('div',{staticClass:"ml-3"},[(_vm.nodeData.measurementsData.numHiAlarms)?_c('div',[_c('icon',{attrs:{"icon":"app/alarm_up","width":"20"}})],1):_vm._e(),(_vm.nodeData.measurementsData.numLoAlarms)?_c('div',[_c('icon',{attrs:{"icon":"app/alarm_down","width":"20"}})],1):_vm._e(),(
                        !_vm.isOffline &&
                            _vm.nodeData.measurementsData &&
                            _vm.nodeData.measurementsData.batteryStatus
                    )?_c('SensorBatteryIndicator',{attrs:{"batteryStatus":_vm.nodeData.measurementsData.batteryStatus}}):_vm._e(),(_vm.nodeData.data.gmpEnabled)?_c('QAEnabledIndicator'):_vm._e()],1):_vm._e()]),(_vm.isOffline)?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"offline-icon mr-2"},[_c('icon',{attrs:{"icon":"app/offline","width":"20"}})],1),_c('div',{staticClass:"d-flex flex-column offline-data"},[_c('div',{},[_vm._v(_vm._s(_vm.$t('BREZ POVEZAVE')))]),(_vm.offlineDate)?_c('div',{},[_vm._v(" "+_vm._s(_vm.MM(_vm.offlineDate).format('DD. MM. YYYY HH:mm'))+" ")]):_vm._e()])]):_vm._e()]),(_vm.qaManageModalOpen)?_c('QAManageModal',{attrs:{"actionType":_vm.nodeData.data.gmpEnabled ? 'DisableSingle' : 'EnableSingle',"recordRef":_vm.nodeData.data.id,"closeMe":() => {
                _vm.qaManageModalOpen = false;
            }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }