

import { Sensor } from '@/internal';
import { SingleSensor } from '@/types';
import { ParseEntityReference } from '@/util';
import { DateTime } from 'luxon';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

// import '@/vue-popper';

import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

interface CorrectionInfo {
    sensorRef: string;
    certificateId: string;
    validTo: string;
}

@Component({
    components: {
        Popper,
    },
})
export default class SensorCertificateBadge extends Vue {

    @Prop({default: null}) private sensorRef!: string | SingleSensor | null;
    @Prop({default: DateTime.local().toISO()}) private dateFilter!: string | null;

    private sensorMeta: SingleSensor | null = null;

    private loadingInProgress: boolean = true;
    private loadingError: boolean = false;

    @Watch('sensorRef')
    private async sensorChanged() {
        this.loadingError = false; this.loadingInProgress = true;

        if (this.sensorRef) {
            if (typeof this.sensorRef === 'object') {
                this.sensorMeta = this.sensorRef;
            } else if (typeof this.sensorRef === 'string') {
                const er = ParseEntityReference(this.sensorRef);
                if (er) {
                    try {
                        this.sensorMeta = await Sensor.providers.fetchSingle(er.id);
                    } catch (err) {
                        this.loadingError = true;
                    }
                }
            }
        }

        this.loadingInProgress = false;
    }

    private openCorrectionsPage() {
        if (this.correction) {
            const er = ParseEntityReference(this.correction.sensorRef);
            if (er) {
                this.$router.push({
                    name: 'viewSensorCorrections',
                    params: {
                        uid: er.id,
                    },
                });
            }
        }
    }

    private get correction(): CorrectionInfo | null {

        if (this.sensorMeta && this.sensorMeta.corrections) {
            const s = this.sensorMeta;
            const v = s.corrections
                // .filter((c) => DateTime.fromISO(c.inspectionDeadline).diffNow().as('days') > 0)
                .map((k) => ({
                    sensorRef: s.id,
                    certificateId: k.certificateId,
                    validTo: k.inspectionDeadline,
                } as CorrectionInfo));
            if (this.dateFilter) {
                // Sort the series by ValidTo
                // Filter out all items where validTo < dateFilter
                // Get the first remaining item
                const filterValue = DateTime.fromISO(this.dateFilter).toMillis();
                const remaining = v.sort((a, b) => a.validTo.localeCompare(b.validTo))
                                   .filter( (a) => DateTime.fromISO(a.validTo).toMillis() > filterValue);
                if (remaining.length > 0) {
                    return remaining[0];
                }
            } else {
                return v[0];
            }
        }

        return null;
    }

    private getAppropriateCertificate(): CorrectionInfo | null {
        return null;
    }

    private mounted() {
        this.sensorChanged();
    }

}
