
import {
    HierarchyContextMenuState, HierarchyItemDefinition, HierarchyNodeType,
    SecurityRole, SingleCustomer, SingleLocation, SinglePlace, SingleSensor
} from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import HierarchyItem from './HierarchyItem.vue';

export enum FilteringStatus {
    Ready, InProgress, NoResults, Done,
}

@Component({})
export default class HierarchyFilterView extends Vue {

    @Prop({ default: '' }) public query!: string | null;
    @Prop({ default: '' }) public data!: HierarchyItemDefinition[];
    @Prop() public closeSearch!: () => void;

    public status: FilteringStatus = FilteringStatus.Ready;

    public flatData: HierarchyItemDefinition[] = [];

    public results: HierarchyItemDefinition[] = [];

    public get itemIcon() {
        return (item: HierarchyItemDefinition) => {
            if (item.nodeType === HierarchyNodeType.Sensor) {
                return 'broadcast-tower';
            } else if (item.nodeType === HierarchyNodeType.Location) {
                return 'map-marker-alt';
            } else if (item.nodeType === HierarchyNodeType.Place) {
                return 'map-marked-alt';
            } else if (item.nodeType === HierarchyNodeType.Customer) {
                return 'user-tie';
            }
        };
    }

    public mounted() {
        this.flatData = [];
        this.flattenData(this.data);

        this.search();
    }

    private get settingsLink() {
        return (nodeData: HierarchyItemDefinition) => {
            if (nodeData.data.id) {

                const id = nodeData.data.id;
                const p = '/settings';

                return p + id;
            }

            return null;
        };
    }

    private openContextMenu(e: MouseEvent, item: HierarchyItemDefinition) {

        const out = this;

        const menu: HierarchyContextMenuState = {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
            options: [
                {
                    label: 'Odpri nastavitve',
                    action: () => {
                        const path = out.settingsLink(item);
                        out.$router.push({ path } as Location);
                    },
                    minRole: SecurityRole.RoleOwner,
                    icon: 'cog',
                },
                {
                    label: 'hierarchy.showOnHierarchy',
                    action: () => {
                        out.closeSearch();
                        out.$store.commit('setFocusedElement', item.hierarchyPath);
                    },
                    icon: 'history',
                },
            ],
        };


        this.$store.commit('showContextMenu', menu);
    }

    private flattenData(coll: HierarchyItemDefinition[]) {

        for (const item of coll) {
            if (item.children) {
                this.flattenData(item.children);
            }

            this.flatData.push(item);
        }
    }

    @Watch('query')
    private search() {
        if (!this.query) {
            return;
        }

        const q = this.query.toLowerCase();

        this.results = this.flatData.filter((item: HierarchyItemDefinition) => {

            return this
                .getFieldValues(item)
                .map((currenValue) => currenValue && currenValue.toLowerCase().indexOf(q) >= 0)
                .reduce<boolean>((a, b) => a || b as boolean, false);
        });
    }

    private getFieldValues(item: HierarchyItemDefinition): string[] {
        if (item.nodeType === HierarchyNodeType.Customer) {
            const c = item.data as SingleCustomer;
            return [c.title, c.id];
        } else if (item.nodeType === HierarchyNodeType.Location) {
            const c = item.data as SingleLocation;
            return [c.title, c.address, c.id];
        } else if (item.nodeType === HierarchyNodeType.Place) {
            const c = item.data as SinglePlace;
            return [c.title, c.id];
        } else if (item.nodeType === HierarchyNodeType.Sensor) {
            const c = item.data as SingleSensor;
            return [c.title, c.titleInternal, JSON.stringify(c.deviceIds), c.id];
        }

        return [];
    }
}
