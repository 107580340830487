
import Vue from 'vue';
import { Prop, Watch, Component } from 'vue-property-decorator';

@Component({})
export default class BlockResolver extends Vue {
    @Prop() private promise!: Promise<any>;

    private value: any = null;
    private resolved: boolean = false;

    private mounted() {
        this.promiseChanged();
    }

    @Watch('promise')
    private promiseChanged() {
        this.resolved = false;

        if (!this.promise) {
            this.resolved = true;
            return;
        }

        // this.$toasted.show('Resolving...');
        const out = this;
        this.promise.then((val) => {
            out.value = val;
            out.resolved = true;
            // this.$toasted.show('Resolved.');
        });
    }
}
