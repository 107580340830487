import {
    EntityDefinition,
    EntityReference,
    HierarchyNodeType,
    HierarchyItemDefinition,
    Dictionary,
    SingleAlarmDefinition,
    SingleSensor,
} from './types';
import { ParseEntityReference } from '@/util';
import { openDB, DBSchema, IDBPDatabase, StoreNames } from 'idb';

import {
    Location,
    Place,
    Sensor,
    Customer,
    AlarmDefinition,
    User,
    UserGroup,
} from '@/internal';

export type ViewStateCategory = 'hierarchy' | 'dashboard';

export interface MyDB extends DBSchema {
    // Object stores
    refs: {
        value: any;
        key: string;
        indexes: {
            refType: string;
            relLocation: string;
            relPlace: string;
            relSensor: string;
            parent: string;
        };
    };

    login: {
        value: {
            key: string;
            value: string;
        };
        key: string;
        indexes: {
            key: string;
        };
    };

    viewState: {
        value: {
            id: string;
            key: string;
            value: any;
            type: string;
        };
        key: string;
        indexes: {
            type: ViewStateCategory;
        };
    };

    settings: {
        value: {
            key: string;
            value: string;
        };
        key: string;
        indexes: {
            key: string;
        };
    };
}

let db: IDBPDatabase<MyDB> | null = null;
let initialized: Promise<any> | null = null;

async function _getLatestSensorInfo(
    sensorId: string,
): Promise<SingleSensor | null> {
    const s: SingleSensor | null = await _resolveReference(sensorId);
    if (s) {
        const a: SingleAlarmDefinition | null = await _getSensorAlarmDefinition(
            sensorId,
        );
        if (a) {
            s.alarmDef = a;
        }
    }

    return s;
}

function _getDB(): IDBPDatabase<MyDB> {
    if (!db) {
        throw new Error('DB NOT AVAILABLE. Did initialization routine fail?');
    }

    return db;
}
function _saveRefs(rows: any, refType: string) {
    if (
        refType === 'measurement' ||
        refType === 'measurement_average' ||
        refType === 'alarm_event' ||
        refType === 'measurements' ||
        refType === 'measurement_averages' ||
        refType === 'alarm_events'
    ) {
        return Promise.resolve();
    }

    return Promise.all(
        rows.map((r: any) => {
            return db!.put('refs', {
                ...r,
                refType,
            });
        }),
    );
}
function _getAllEntityRefs(entity: string): Promise<any> {
    return _getDB().getAllFromIndex('refs', 'refType', entity);
}
async function _getLocationPlaces(relLocation: string): Promise<any> {
    const allPlaces = await _getDB().getAllFromIndex(
        'refs',
        'refType',
        'place',
    );
    return allPlaces.filter(
        (place: any) => place.relLocation === relLocation && !place.parent,
    );
}
async function _getPlacePlaces(relPlace: string): Promise<any> {
    const allPlaces = await _getDB().getAllFromIndex(
        'refs',
        'refType',
        'place',
    );
    return allPlaces.filter((place: any) => place.parent === relPlace);
}
async function _getPlaceSensors(relPlace: string): Promise<any> {
    const allSensors = await _getDB().getAllFromIndex(
        'refs',
        'refType',
        'sensor',
    );
    return allSensors.filter((sensor: any) => sensor.relPlace === relPlace);
}
async function _getSensorAlarmDefinition(relSensor: string): Promise<any> {
    const refResolve = await _getDB().getAllFromIndex(
        'refs',
        'relSensor',
        relSensor,
    );
    const filtered = refResolve.filter((def: any) => def.refType === 'alarm');
    if (filtered.length === 0) {
        return null;
    }
    return filtered[0];
}

function _resolveReference(ref: string): Promise<any> {
    return _getDB().get('refs', ref);
}

function _clearCache(): Promise<any> {
    // console.log('CLEARING CACHE! 💪💪');
    return Promise.all([
        _getDB().clear('refs'),
        _getDB().clear('viewState'),
        _getDB().clear('settings'),
    ]);
}

function _clearIfAvailable(): Promise<any> {
    if (db) {
        return _clearCache();
    } else if (initialized != null) {
        return initialized.then(() =>
            Promise.all([
                _getDB().clear('refs'),
                _getDB().clear('viewState'),
                _getDB().clear('settings'),
            ]),
        );
    } else {
        throw new Error('Could not clear refs cache');
    }
}

export interface DBUpdateQuery {
    [key: string]: any;
}

export default {
    /*
        Class Cache
        ---
        Shranjuje poizvedbe do entitet v IndexedDB in jih organizira v strukturo,
        da so podatki enostavno dostopni
    */

    InitCache(): Promise<any> {
        if (!('indexedDB' in window)) {
            alert('Cannot proceed. The browser does not support IndexedDB.');
            throw new Error('IDB not available.');
        }

        initialized = openDB<MyDB>('exactumCache', 7, {
            upgrade: (upgradeDb, oldVersion, newVersion, transaction) => {
                if (
                    oldVersion <= 4 &&
                    upgradeDb.objectStoreNames.contains('refs')
                ) {
                    // alert('Upgrading database');
                    upgradeDb.deleteObjectStore('refs');
                }

                if (!upgradeDb.objectStoreNames.contains('refs')) {
                    const refsStore = upgradeDb.createObjectStore('refs', {
                        keyPath: 'id',
                    });
                    refsStore.createIndex('refType', 'refType');
                    refsStore.createIndex('relLocation', 'relLocation');
                    refsStore.createIndex('relPlace', 'relPlace');
                    refsStore.createIndex('parent', 'parent');
                    refsStore.createIndex('relSensor', 'relSensor');
                }

                if (!upgradeDb.objectStoreNames.contains('login')) {
                    const loginStore = upgradeDb.createObjectStore('login', {
                        keyPath: 'key',
                    });
                }

                if (!upgradeDb.objectStoreNames.contains('viewState')) {
                    const viewStateStore = upgradeDb.createObjectStore(
                        'viewState',
                        { keyPath: 'id' },
                    );
                    viewStateStore.createIndex('type', 'type');
                }

                if (!upgradeDb.objectStoreNames.contains('settings')) {
                    const viewStateStore = upgradeDb.createObjectStore(
                        'settings',
                        { keyPath: 'key' },
                    );
                    viewStateStore.createIndex('key', 'key');
                }
            },
        }).then((theDatabase) => {
            db = theDatabase;
            return theDatabase;
        });

        return initialized;
    },

    // Alters hierarchy - CALL EVENT
    SaveRefs(rows: any[], singularName: string): Promise<any> {
        return _saveRefs(rows, singularName);
    },

    GetAllRefsEntries(): Promise<any> {
        return _getDB().getAll('refs');
    },

    StoreViewState(stateObject: any, type: ViewStateCategory) {
        const transaction = _getDB().transaction('viewState', 'readwrite');
        const store = transaction.store;
        return Promise.all([
            ...Object.entries(stateObject).map(([key, value]) => {
                return store.put({ id: key + type, key, value, type });
            }),
            transaction.done,
        ]);
    },

    async RetrieveViewState(type: ViewStateCategory) {
        const settings = await _getDB().getAllFromIndex(
            'viewState',
            'type',
            type,
        );
        return Object.fromEntries(
            settings.map((val) => {
                return [val.key, val.value];
            }),
        );
    },

    ClearViewState(type: ViewStateCategory) {
        return _getDB().clear('viewState');
    },

    // Alters hierarchy - CALL EVENT
    async ReloadWhole(): Promise<any> {
        await Promise.all([
            Location.providers.fetchAll({ itemsPerPage: 100, page: 1 }),
            Place.providers.fetchAll({ itemsPerPage: 500, page: 1 }),
            Sensor.providers.fetchAll({ itemsPerPage: 500, page: 1 }).then(() =>
                AlarmDefinition.providers.fetchAll({
                    itemsPerPage: 500,
                    page: 1,
                }),
            ),
            Customer.providers.fetchAll({ itemsPerPage: 100, page: 1 }),
            User.providers.fetchAll({ itemsPerPage: 100, page: 1 }),
            UserGroup.providers.fetchAll({ itemsPerPage: 100, page: 1 }),
        ]);

        _getDB().put('settings', {
            key: 'lastWholeReload',
            value: new Date().getTime().toString(),
        });
    },

    async IsCacheInitialized() {
        if (db) {
            const value = await _getDB().get('settings', 'lastWholeReload');

            return value;
        }

        return null;
    },

    // Alters hierarchy - CALL EVENT
    Clear: _clearCache,
    // Alters hierarchy - CALL EVENT
    ClearIfAvailable: _clearIfAvailable,

    ClearLoginData(): Promise<any> {
        if (db) {
            return _getDB().clear('login');
        } else if (initialized != null) {
            return initialized.then(() => _getDB().clear('login'));
        } else {
            throw new Error('Could not clear login data');
        }
    },

    UpdateLoginData(data: DBUpdateQuery): Promise<any> {
        const promises: Array<Promise<any>> = [];
        for (const key in data) {
            if (data[key] == null) {
                // Delete key
                promises.push(_getDB().delete('login', key));
            } else {
                // Update value
                promises.push(_getDB().put('login', { key, value: data[key] }));
            }
        }

        return Promise.all(promises);
    },

    GetLoginData(): Promise<any> {
        if (initialized) {
            return initialized
                .then(() => {
                    return _getDB().getAll('login');
                })
                .then((data) => {
                    const v: any = {};
                    for (const obj of data) {
                        v[obj.key] = obj.value;
                    }

                    return v;
                });
        } else {
            throw new Error('');
        }
    },

    GetLatestSensorInfo: _getLatestSensorInfo,

    GetAllEntityRefs: _getAllEntityRefs,

    GetPlacePlaces: _getPlacePlaces,

    GetPlaceSensors: _getPlaceSensors,

    GetLocationPlaces: _getLocationPlaces,

    ResolveReference: _resolveReference,

    GetSensorAlarmDef: _getSensorAlarmDefinition,
};
