
import Vue from 'vue';
import Component from 'vue-class-component';
import NiceBlock from '@/components/elements/NiceBlock.vue';
import IDateTime from '@/components/input/IDateTime.vue';
import { Prop } from 'vue-property-decorator';

@Component({
    components: { NiceBlock, IDateTime },
})
export default class NiceInput extends Vue {
    @Prop({ default: () => ({}) }) private blockStyles!: any;
    @Prop({ default: '' }) private label!: string;

    @Prop({ default: false }) private disabled!: boolean;
}
