export const ExportFrequencyOptionSet = [
    'single',
    'daily',
    'on_monday',
    'on_tuesday',
    'on_wednesday',
    'on_thursday',
    'on_friday',
    'on_saturday',
    'on_sunday',
];

export const ExportFrequencyNeedsTimestamp = ['single'];

export const ExportFormatOptionSet = ['pdf', 'pdf_detailed'];

export const ExportFormatBlagovneRezerve = ['grouped_current', 'grouped_delta'];

export const ExportFormatXLSX = ['xlsx'];

export const LanguageOptionSet = ['sl-SI', 'en-GB'];

export const MeasurementExportStateOptionSet = [
    'received',
    'in_progress',
    'complete',
];

export const LocaleToAPILocale = {
    sl_SI: 'sl-SI',
    en: 'en-GB',
};

export const SensorUnitOptionSet = ['rh', 'mbar', 'celsius'];

export const YesNoOptionSet = ['yes', 'no'];

export const SensorTypeOptionSet = ['temperature', 'humidity', 'pressure'];

export const TimezoneOptionSet = [
    'UTC',
    'Europe/Ljubljana',
    'Europe/Vienna',
    'Europe/Zagreb',
    'Europe/Budapest',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/London',
    'Europe/Rome',
    'Europe/Sarajevo',
    'Europe/Istanbul',
    'Europe/Sofia', // Bulgaria
    'Europe/Kiev',
    'Europe/Bucharest',
    'Europe/Athens',
    'America/New_York',
    'America/Los_Angeles',
    'Asia/Dubai', // United Arab Emirates
    'Asia/Riyadh', // Saudi Arabia
    'Africa/Cairo',
    'Africa/Johannesburg',
    'Africa/Khartoum',
    'Asia/Jerusalem',
];
