import { HierarchyItemDefinition, SingleSensor } from '@/types';
import UnitManager from '@/units';
import { RoundNumber } from '@/util';

export function HierarchyNodeSensorValue(data: HierarchyItemDefinition) {

    if (data && data.measurementsData && data.measurementsData.value !== undefined) {
        const value = parseFloat(data.measurementsData.value);
        return ConvertHierarchyNodeSensorValue(data, value);
    }
    return '??';
}

export function ConvertHierarchyNodeSensorValue(data: HierarchyItemDefinition, value: number) {
    if (data
        && data.measurementsData
        && data.measurementsData.value !== null
        && data.measurementsData.value !== undefined) {

            const sensorMeta = data.data as SingleSensor;

            let c = value;
            if (sensorMeta.unit && sensorMeta.presentationUnit) {
                c = UnitManager.GetInstance().ConvertValueFromBaseUnit(
                    value,
                    sensorMeta.Type,
                    sensorMeta.presentationUnit,
                );
            }
            return RoundNumber(c, 3);
    } else {
        return '??';
    }
}

export function HierarchyNodeSensorUnit(data: SingleSensor): string {
    if (data.unit === 'undefined' || !data.unit) {
        return '?';
    } else {

        const sensorMeta = data as SingleSensor;
        if (sensorMeta.presentationUnit) {
            return UnitManager.GetInstance().GetDisplaySymbol(
                sensorMeta.Type,
                sensorMeta.presentationUnit,
            );
        } else {
            return UnitManager.GetInstance().GetDisplaySymbol(
                sensorMeta.Type,
                sensorMeta.unit,
            );
        }
    }
}
