
import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop } from 'vue-property-decorator';
import { CleaveOptions } from 'cleave.js/options';
import { debounce } from 'lodash';
import Cleave from 'cleave.js';

@Component({})
export default class DTMaskedInputSingle extends Vue {
    private displayValue: string = '';

    private instance: Cleave | null = null;

    @Prop() private options!: CleaveOptions;
    @Prop() private value!: string | null;

    private emitChange = debounce(
        this.x_emitChange.bind(this),
        200,
    );

    private get inputValue() {
        return this.value;
    }

    private set inputValue(val: any) {
        // console.log("Emmiting single");
        this.emitChange();
    }

    private x_emitChange() {
        let newValue = null;
        if (this.instance) {
            if (this.options.date) {
                const dt = this.instance.getISOFormatDate();
                if (dt) {
                    newValue = dt;
                }
            }
            if (this.options.time) {
                const dt = this.instance.getFormattedValue();
                if (dt && dt.length === 5) {
                    newValue = dt;
                }
            }

        }

        if (newValue) {
            this.$emit('input', newValue);
        }
    }

    private mounted() {
        const cleaveEL = this.$refs.cleaveanchor as HTMLInputElement;
        this.instance = new Cleave(cleaveEL, this.options);
    }
}
