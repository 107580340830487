
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import {HierarchyMenuCommand} from '@/components/hierarchy/hierarchyTypes';

import SensorCertificateBadge from '@/components/functional/SensorCertificateBadge.vue';
import { HierarchyItemDefinition, SingleSensor } from '@/types';
import { DateTime } from 'luxon';
import { ExactumCache } from '@/internal';
import { DefaultHistoryTimeIntervalQuery } from '@/const';

@Component({
    components: {
        SensorCertificateBadge,
    },
})
export default class MeasurementHistoryHierarchyMenu extends Vue {

    @Prop() private execute!: (command: HierarchyMenuCommand, payload?: any) => any;

    @Prop() private menuOpened!: boolean;

    private selectedSensors: SingleSensor[] = [];

    private get params() {
        return this.$route.query;
    }

    private get sensorIds() {
        if (typeof this.params.sensors === 'string') {
            const sensorString = this.params.sensors;
            return sensorString.split(',');
        }
        return [];
    }

    private toggleMenu() {

        this.execute('closeAllMenus');
        if (!this.menuOpened) {
            this.execute('openMenu', 'measurementHistory');
        }
    }

    private focus(id: string) {
        const node = this.execute('getTreeObjectById', id) as HierarchyItemDefinition | null;
        if (node) {
            this.execute('setFocusedElement', node.hierarchyPath);
        }
    }

    private beforeDestroy() {
        this.execute('disableSensorSelection');
    }

    private openMeasurementHistory() {
        this.$router.push({
            name: 'measurementHistory',
            query: {
                sensors: this.selectedSensors.map((s) => s.id).join(','),
                ...DefaultHistoryTimeIntervalQuery(),
            },
        });
    }

    private removeSensorFromSelection(sensor: SingleSensor) {
        this.selectedSensors = this.selectedSensors.filter((s) => s.id !== sensor.id);
    }

    private async loadSensors() {

        this.selectedSensors = await Promise.all(this.sensorIds.map(async (id) => {
            return await ExactumCache.ResolveReference(id);
        }));

        this.selectedSensors = this.selectedSensors.filter((s) => !!s);
    }

    @Watch('menuOpened')
    private menuOpenChange() {
        if (!this.menuOpened) {
            // Closing Menu
            this.execute('disableSensorSelection');
        } else {
            // Opening menu
            this.execute('enableSensorSelection', (sensorData: HierarchyItemDefinition) => {
                const data = sensorData.data as SingleSensor;
                if (!this.selectedSensors.find((s) => s.id === data.id)) {
                    this.selectedSensors = [...this.selectedSensors, sensorData.data as SingleSensor];
                }
            });
            if (this.$route.name !== 'measurementHistory') {
                this.$router.push({
                    name: 'measurementHistory',
                });
            }
        }
    }

    @Watch('params', {deep: true})
    private routeChanged() {
        if (this.params.sensors) {
            this.loadSensors();
        }
    }

    private mounted() {
        this.routeChanged();

        if (this.params.sensors) {
            this.loadSensors();
        }
    }
}
