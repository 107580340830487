
import Component from 'vue-class-component';
import BaseGridForm from './BaseGridForm';

import AuditDataParser from './auditLog/AuditDataParser.vue';
import { SingleAlarmDefinition, SingleAlarmEvent, SingleAuditLogEntry, SingleSensor, SingleUser } from '@/types';
import { AlarmDefinition, AlarmEvent, Entities, ExactumCache } from '@/internal';

import RenderValue from './RenderValue.vue';

import GuidConceal from './GuidConceal.vue';

// Explainers
import GenericAuditChangesExplainer from './auditLog/GenericAuditChangesExplainer.vue';
import UserRolesChangesExplainer from './auditLog/UserRolesChangesExplainer.vue';
import AdminCustomerIdsExplainer from './auditLog/AdminCustomerIdsExplainer.vue';
import SubscriberLevelAuditExplainer from './auditLog/SubscriberLevelAuditExplainer.vue';
import DelaySubscriberLevelAuditExplainer from './auditLog/DelaySubscriberLevelAuditExplainer.vue';
import AlarmEventNotificationsExplainer from './auditLog/AlarmEventNotificationsExplainer.vue';
import UpdatedTimestampAuditExplainer from './auditLog/UpdatedTimestampAuditExplainer.vue';


@Component({
    components: {
        AuditDataParser,
        RenderValue,
        GuidConceal,
        GenericAuditChangesExplainer,
        UserRolesChangesExplainer,
        AdminCustomerIdsExplainer,
        SubscriberLevelAuditExplainer,
        DelaySubscriberLevelAuditExplainer,
        AlarmEventNotificationsExplainer,
        UpdatedTimestampAuditExplainer
    },
})
export default class MasterDetailForm extends BaseGridForm {
    public selectedRow: any = null;

    public supportedLinkEntities = {
        Alarm: 'alarms',
        Customer: 'customers',
        Location: 'locations',
        MeasurementExport: 'measurement_exports',
        MeasurementNote: 'measurement_notes',
        Place: 'places',
        Sensor: 'sensors',
        User: 'users',
        AlarmEvent: 'alarm_events',
    } as { [key: string]: string };

    public get transposedChanges() {
        return (chg: any) => {
            if (chg) {
                const transpose = (a: any) =>
                    a[0].map((_: any, c: number) => a.map((r: any) => r[c]));
                const pre = Object.entries(chg).map((row: any[]) =>
                    Object.entries(row[1]),
                );
                const post = transpose(pre);
                const name = post.map((row: any) => [
                    row[0][0],
                    ...row.map(([valNam, val]: [string, any]) => val),
                ]);
                return [['', ...Object.keys(chg)], ...name];
            }
            return null;
        };
    }

    public downloadCSV() {
        this.export();
    }

    public getFullLink(selectedRow: SingleAuditLogEntry) {
        return '/' + this.supportedLinkEntities[selectedRow.entityType] + '/' + selectedRow.data.entityId;
    }

    public openDetail(row: any) {
        if (this.selectedRow) {
            if (row.data === this.selectedRow.data) {
                this.selectedRow = null;
            } else {
                this.selectedRow = row;
            }
        } else {
            this.selectedRow = row;
        }
    }

    public resolveUser(id: string): Promise<SingleUser> {
        return ExactumCache.ResolveReference('/users/' + id);
    }

    public resolveSensor(selectedRow: SingleAuditLogEntry) {
        return AlarmEvent.providers.fetchSingle((selectedRow as any).entityId).then((aeData: SingleAlarmEvent | null) => {
            if (aeData) {
                console.log("AlarmEventIndo", aeData)
                return AlarmDefinition.providers.fetchSingle(aeData.relAlarm.replace('/alarms/', ''));
            }
        }).then((definitionData: any) => {
            if (definitionData) {
                console.log("AlarmDefInfo", definitionData)
                return ExactumCache.ResolveReference(definitionData.relSensor);
            }
        }).then((s: SingleSensor | null) => {
            if (s) {
                return { sensorId: s.id, sensorName: s.title }
            } else {
                return { sensorId: null, sensorName: '??' }
            }
        }).catch(() => {
            return { sensorId: null, sensorName: '??' };
        });
    }

    public resolveRecord(data: any): Promise<any> {
        const names = this.supportedLinkEntities;

        if (data.entityType in names && data.data.entityId) {
            const link =
                '/' +
                (names as any)[data.entityType] +
                '/' +
                data.data.entityId;
            return ExactumCache.ResolveReference(link);
        }

        return Promise.resolve();
    }

    public getPrimaryField(selectedRow: SingleAuditLogEntry, { value }: any) {

        const ent = Object.values(Entities).find(e => e.providers.serializationData?.entity == selectedRow.entityType);

        console.log(Entities);
        console.log("Found Ent:", ent, "Value: ", value, "Selected Row", selectedRow);

        if (ent && ent.getPrimaryKey) {
            return ent.getPrimaryKey(value);
        }

        return "Odpri zapis";
    }
}
