
import Component from 'vue-class-component';

import RenderValue from '../RenderValue.vue';
import { Prop, Vue } from 'vue-property-decorator';
import { SingleAuditLogEntry, SingleCustomer } from '@/types';
import BlockResolver from '@/components/util/BlockResolver.vue';
import InlineResolver from '@/components/util/InlineResolver.vue';
import { ExactumCache, User } from '@/internal';


@Component({
    components: {

    }
})
export default class SubscriberLevelAuditExplainer extends Vue {

    @Prop() public selectedRow!: SingleAuditLogEntry;
    @Prop() public fieldName!: string;

    public get changes() {
        return (this.selectedRow as any).changes[this.fieldName];
    }

    public userPromise(userId: string) {
        return ExactumCache.ResolveReference("/users/" + userId).then((user) => {
            if (user && User.getPrimaryKey) {
                return User.getPrimaryKey(user);
            } else {
                return userId + " (ni mogoče pridobiti imena prejemnika)"
            }
        });
    }

}
