import { GenerateProviders, GETCSV } from '@/errorHandler';
import {
    APIMetadataResponse,
    EntityDefinition,
    EntityReferenceString,
    FetchMultipleQueryParams,
    SingleAuditLogEntry,
    SingleFlatAuditLogEntry,
} from '@/types';

import { RoleParams } from '@/authorization';

const providers = GenerateProviders<SingleFlatAuditLogEntry>('audits', {
    entity: 'Audit',
    params: {
        attributes: [
            'relCustomer',
            'timestamp',
            'action',
            'entityType',
            'data',
        ],
    },
});

const fetchMultiple = providers.fetchMultiple;
providers.fetchMultiple = (q: FetchMultipleQueryParams) =>
    fetchMultiple(q).then((v) => {
        const data = v.data as SingleAuditLogEntry[];
        v.data = data.map((d) => {
            return {
                action: d.action,
                entityType: d.entityType,
                id: d.id,
                relCustomer: d.relCustomer,
                timestamp: d.timestamp,
                changes: d.data.changes,
                entityId: d.data.entityId,
                userId: d.data.userId,
                userIp: d.data.userIp,
                data: d.data,
            } as SingleFlatAuditLogEntry;
        });

        return v;
    });

providers.export = async (
    q: FetchMultipleQueryParams,
): Promise<APIMetadataResponse<any>> => {
    const queryParams = { ...q };
    await GETCSV('audits/export', queryParams);
    return {
        data: [],
        links: { first: '', last: '', self: '' },
        meta: { currentPage: 1, itemsPerPage: 1, totalItems: 1 },
    };
};

export const AuditLogs: EntityDefinition = {
    primaryKey: 'timestamp',
    endpointName: 'audits',
    isPrimaryResolver: true,
    getPrimaryKey: (row: any): string => {
        return row.timestamp;
    },
    name: 'Audit',
    pluralName: 'Audits',
    routes: {
        single: '',
        grid: 'settingsAuditLogs',
    },
    providers,
    lookupFields: {
        title: '',
        detailLeft: '',
    },
    roleBasedFilters: {
        ROLE_USER: RoleParams.UserCustomer,
        ROLE_OWNER: RoleParams.UserCustomer,
    },
};
