import { GridFormDefinition } from '@/types';

/**
 * processChangeOutput function.
 *
 * - Checks the Grid Def for changeOutput attributes in filter values,
 * - runs the defined changeOutput function against filterValues
 * - returns the changed filterValues
 */
export default (filterValues: any, formDef: GridFormDefinition) => {

    if (formDef.filters) {
        const inputs = formDef.filters.filter((m) => m.changeOutput);
        if (inputs.length > 0) {
            for (const input of inputs) {
                if (input.changeOutput) {
                    // The function can cause side effects.
                    // The function can change any filter value or even add its own keys.
                    input.changeOutput(filterValues);
                }
            }
        }
    }

    return filterValues;
};
