import {
    EntityDefinition,
    APIMetadataResponse,
    ProgressTrackerData,
    PaginationLinks,
    AlarmReceiversDefinition,
    SingleAlarmSubscriberInfo,
    SingleAlarmDefinition,
    SingleSensor,
    SinglePlace,
    SingleSensorAlarmDefinitionComposition,
} from '@/types';

import { ExactumCache, AlarmDefinition, Sensor } from '@/internal';

let store: SingleSensorAlarmDefinitionComposition[] = [];

async function GetSensorAlarmDefinitions() {
    const defs = await AlarmDefinition.providers.fetchAll({
        page: 1,
        itemsPerPage: 500,
    });
    const rels = {} as any;
    for (const def of defs.data) {
        rels[def.relSensor] = def;
    }

    return rels;
}

export async function BuildAlarmFilterView() {
    // Retrieve all sensors
    // Retrieve all alarmDefinitions
    // For each sensor
    //      Retrieve Location

    const allPlaces = Object.fromEntries(
        (await ExactumCache.GetAllEntityRefs('place')).map((a: SinglePlace) => [
            a.id,
            a,
        ]),
    );

    const sensors: SingleSensor[] = await ExactumCache.GetAllEntityRefs(
        'sensor',
    );

    store = [];

    const defs = await GetSensorAlarmDefinitions();
    for (const theSensor of sensors) {
        let place: SinglePlace = {} as SinglePlace;
        let places = '';
        if (theSensor.relPlace) {
            place = allPlaces[theSensor.relPlace];
            if (!place) {
                place = {} as SinglePlace;
            } else {
                // Resolve all places from hierarchy
                places = ConcatHierarchy(allPlaces, place.id, 'parent');
            }
        }

        let alarm = {} as SingleAlarmDefinition;
        if (theSensor.id in defs) {
            alarm = defs[theSensor.id];
        }

        store.push({
            id: theSensor.id,
            title: theSensor.title,
            unit: theSensor.unit,
            Type: theSensor.Type,
            relSensor: theSensor.id,

            relPlace: theSensor.relPlace,
            placeHierarchy: places,
            relLocation: place.relLocation,
            relCustomer: theSensor.relCustomer,

            frequency: theSensor.frequency,

            relAlarm: alarm.id,
            active: alarm.active,
            alarmLow: alarm.alarmLow,
            alarmHigh: alarm.alarmHigh,
            alarmOffline: alarm.alarmOffline,
            delayHighValue: alarm.delayHighValue,
            delayLowValue: alarm.delayLowValue,
            delayOffline: alarm.delayOffline,
            delayBackToNormal: alarm.delayBackToNormal,

            subscribersLevel1: alarm.subscribersLevel1,
            subscribersLevel2: alarm.subscribersLevel2,
            subscribersLevel3: alarm.subscribersLevel3,
        });
    }

    filteredView = store;

    return true;
}

interface RecursiveObj {
    [key: string]: RecursiveObj | null;
}

function ConcatHierarchy(
    objStore: RecursiveObj,
    currentNodeId: string,
    nodeKey: string,
): string {
    // Object doesn't even exist? Return none.
    if (!objStore || !objStore[currentNodeId]) {
        return '';
    }

    const thisElement = objStore[currentNodeId] as any;

    // ROOT NODE? Just return itself.
    if (thisElement && !thisElement[nodeKey]) {
        return currentNodeId;
    }

    return (
        currentNodeId +
        ConcatHierarchy(objStore, thisElement[nodeKey] as string, nodeKey)
    );
}

function splitNameAndOperator(
    filterField: string,
): { operator: string; name: string } {
    const matches = filterField.match(/(\w+)\[(\w+)\]/);
    if (matches && matches.length === 3) {
        return {
            operator: matches[2],
            name: matches[1],
        };
    } else {
        return {
            operator: 'eq',
            name: filterField,
        };
    }
}

function evaluateOperator(record: any, filter: any, operator: string): boolean {
    switch (operator) {
        case 'exactlyThisPlace':
        case 'eq':
            // tslint:disable-next-line
            return (
                (typeof record === 'number' &&
                    typeof filter === 'number' &&
                    record === filter) ||
                (typeof record === 'string' &&
                    typeof filter === 'string' &&
                    record === filter) ||
                (typeof record === 'boolean' &&
                    typeof filter === 'boolean' &&
                    record === filter)
            );

        case 'thisPlaceOrSubplaces':
        case 'contains':
            return (
                typeof record === 'string' &&
                typeof filter === 'string' &&
                (record as string).indexOf(filter) >= 0
            );
        case 'gt':
            return typeof record === 'number' && record > filter;
        case 'ge':
            return typeof record === 'number' && record >= filter;
        case 'lt':
            return typeof record === 'number' && record < filter;
        case 'le':
            return typeof record === 'number' && record <= filter;
        case 'null':
            return record === null;
        case 'notnull':
            return record !== null;
    }

    return false;
}

function populateFilterView(currentFilters: any) {
    filteredView = store.filter(
        (record: SingleSensorAlarmDefinitionComposition) => {
            // Pojdi čez vsako polje
            //  Če je to polje nastavljeno na recordu
            //  Preveri če se ujemata
            //  Če se ujemata, nadaljuj
            //  Če se ne ujemata, prekliči
            for (const filterField in currentFilters) {
                if (currentFilters.hasOwnProperty(filterField)) {
                    if (!currentFilters[filterField]) {
                        continue;
                    }

                    const fO: {
                        operator: string;
                        name: string;
                    } = splitNameAndOperator(filterField);

                    if (fO.name in record) {
                        const recordValue = (record as any)[fO.name];
                        const filterValue = currentFilters[filterField];

                        if (
                            !evaluateOperator(
                                recordValue,
                                filterValue,
                                fO.operator,
                            )
                        ) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }

            return true;
        },
    );
}

function applyFilters(q: any) {
    const currentFilters = { ...q };
    delete currentFilters.itemsPerPage;
    delete currentFilters.page;
    for (const filterField in currentFilters) {
        if (
            currentFilters.hasOwnProperty(filterField) &&
            currentFilters[filterField] === null
        ) {
            delete currentFilters[filterField];
        }
    }

    const filtersUnchanged =
        JSON.stringify(currentFilters) === JSON.stringify(lastFilters);

    if (!filtersUnchanged) {
        populateFilterView(currentFilters);
    }

    lastFilters = { ...currentFilters };
    return filtersUnchanged;
}

let filteredView: SingleSensorAlarmDefinitionComposition[] = [];
let lastFilters = {};

export const AlarmFilterView: EntityDefinition<SingleSensorAlarmDefinitionComposition> = {
    primaryKey: 'relAlarm',
    endpointName: 'alarms',
    isPrimaryResolver: false,
    getPrimaryKey(row: any) {
        if (row) {
            return row.relAlarm;
        }

        return 'unknown';
    },
    name: 'AlarmView',
    pluralName: 'AlarmsView',
    routes: {
        single: 'settingsAlarmDefinitionsAddEdit',
        grid: 'settingsAlarmDefinitions',
    },
    providers: {
        fetchMultiple: (q) => {
            applyFilters(q);
            const perPage = q.itemsPerPage ? q.itemsPerPage : 20;
            const currentPage = q.page ? parseInt(q.page + '', 10) : 1;
            const ret = filteredView.slice(
                (currentPage - 1) * perPage,
                currentPage * perPage,
            );

            const totalItems = filteredView.length;
            const lastPage = Math.ceil(filteredView.length / perPage);

            const links = {
                first: `alarmFiltersView?page=1&itemsPerPage=${perPage}`,
                last: `alarmFiltersView?page=${lastPage}&itemsPerPage=${perPage}`,
                self: `alarmFiltersView?page=${currentPage}&itemsPerPage=${perPage}`,
            } as PaginationLinks;
            if (totalItems > perPage) {
                if (currentPage !== lastPage) {
                    links.next = `alarmFiltersView?page=${currentPage +
                        1}&itemsPerPage=${perPage}`;
                }

                if (currentPage !== 1) {
                    links.prev = `alarmFiltersView?page=${currentPage -
                        1}&itemsPerPage=${perPage}`;
                }
            }

            return Promise.resolve({
                data: ret,
                links,
                meta: {
                    currentPage,
                    itemsPerPage: perPage,
                    totalItems,
                },
            } as APIMetadataResponse<SingleSensorAlarmDefinitionComposition>);
        },
        create: (d: any) => Promise.reject(),
        delete: (d: any) => Promise.reject(),
        fetchAll: (d: any) => Promise.reject(),
        fetchSingle: (d: any) => Promise.reject(),
        update: (d: any) => Promise.reject(),
    },
    lookupFields: {
        title: 'id',
    },
};
