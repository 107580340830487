
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SensorBatteryIndicator extends Vue {
    @Prop({default: null}) private batteryStatus!: string;


    private get batteryIcon() {
        if (this.batteryStatus === 'green') {
            return 'app/battery_green';
        } else if (this.batteryStatus === 'orange') {
            return 'app/battery_orange';
        } else if (this.batteryStatus === 'red') {
            return 'app/battery_red';
        }

        return 'app/battery_unknown';
    }

}
