import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    SingleFormDefinition,
    EntityDefinition,
    FormDefinition,
    FormActionDefinition,
    SingleFormInputDefinition,
    SecurityRole,
    LoginInfo,
    UserInfo,
    LocationBarState,
    LocationBarAction,
    LocationBarActionType,
    SingleCustomer,
    AccessRightScope,
    SingleUser,
    FormActionContext,
} from '@/types';

import BaseForm from '@/components/input/forms/BaseForm';

import TextFormInput from './single/TextFormInput.vue';
import BooleanFormInput from './single/BooleanFormInput.vue';
import PlaceholderTitleTextFormInput from './single/PlaceholderTitleTextFormInput.vue';
import NumberFormInput from './single/NumberFormInput.vue';
import TextareaFormInput from './single/TextareaFormInput.vue';
import SplitterFormInput from './single/SplitterFormInput.vue';
import LookupFormInput from './single/LookupFormInput.vue';
import MultiLookupFormInput from './single/MultiLookupFormInput.vue';
import MapLocationFormInput from './single/MapLocationFormInput.vue';
import FileUploadFormInput from './single/FileUploadFormInput.vue';
import OptionSetFormInput from './single/OptionSetFormInput.vue';
import LocalizedOptionSetFormInput from './single/LocalizedOptionSetFormInput.vue';
import TaggableOptionSetFormInput from './single/TaggableOptionSetFormInput.vue';
import TitleFormInput from './single/TitleFormInput.vue';
import UnitNumberInput from './single/UnitNumberInput.vue';
import UserAccessDefinitionFormInput from './single/UserAccessDefinitionFormInput.vue';
import DateTimeFormInput from './single/DateTimeFormInput.vue';
import FeaturesSelectFormInput from './single/FeaturesSelectFormInput.vue';
import { ExactumCache } from '@/internal';
import { GetAllCustomerFeatures } from '@/components/util/CustomerFeatures';

@Component({
    components: {
        TextFormInput,
        BooleanFormInput,
        NumberFormInput,
        TextareaFormInput,
        SplitterFormInput,
        LookupFormInput,
        MultiLookupFormInput,
        MapLocationFormInput,
        FileUploadFormInput,
        OptionSetFormInput,
        LocalizedOptionSetFormInput,
        TaggableOptionSetFormInput,
        PlaceholderTitleTextFormInput,
        TitleFormInput,
        UnitNumberInput,
        DateTimeFormInput,
        FeaturesSelectFormInput,
        UserAccessDefinitionFormInput,
    },
})
export default class SingleForm extends BaseForm {
    private settings: any = {};

    private dataLoaded = false;

    private inputViolations: any = {};

    private doAction(name: string, def?: FormActionDefinition) {
        const out = this;

        if (def) {
            if (def.action) {
                def.action.apply(this, [
                    {
                        record: out.formData,
                        form: out,
                    },
                ]);
                return;
            }
        }

        if (name === 'save') {
            this.save();
        }
        if (name === 'delete') {
            this.askForDelete();
        }
        if (name === 'reroute' && def && def.route) {
            this.$router.push(def.route);
        }
        if (name === 'openSettings') {
            this.$router.push('/settings' + this.formData.id);
        }
    }

    private runShowIf(def: (ctx: FormActionContext<any>) => boolean): boolean {
        const out = this;
        if (def) {
            return def.apply(this, [
                {
                    record: out.formData,
                    form: out,
                },
            ]);
        }

        return true;
    }

    private saveErrorHandler(error: any) {
        if (error && error.violations) {
            for (const violation of error.violations) {
                this.$set(
                    this.inputViolations,
                    violation.propertyPath,
                    violation.message,
                );
            }
        }
    }

    private actionLabel(colDef: any) {
        if (colDef.label) {
            return colDef.label;
        } else {
            return colDef.name;
        }
    }

    private getDefaultValues() {
        const sfd = this.formDefinition as SingleFormDefinition;
        const loginInfo = this.$store.getters.getLoginInfo as LoginInfo;

        const additionalAttributes = this.$store.getters
            .getAdditionalUserAttributes as SingleUser;
        const res = sfd.inputs
            .map((i) => {
                if (i.defaultIfDisabled) {
                    return [
                        i.name,
                        i.defaultIfDisabled({
                            userData: loginInfo.user,
                            additionalUserInfo: additionalAttributes,
                        }),
                    ];
                }
            })
            .filter((a) => a) as Array<[string, any]>;

        return Object.fromEntries(res);
    }

    private rerouteToGrid() {
        const entity = this.entityDefinition as EntityDefinition;
        if (entity) {
            this.$router.push({ name: entity.routes.grid });
        } else {
            alert('Could not find entity');
        }
    }

    private save(): void {
        // Not necessary anymore since the default values get filled in when the form loads
        // this.resolveRequiredDisabledFields();

        this.inputViolations = {};

        const v = this.formData;
        if (!this.isNewForm) {
            const uid = this.$route.params.uid;
            this.entityDefinition.providers.update!(uid, this.formData)
                .then(() => {
                    this.loadFormData();
                    this.$toasted.success(
                        this.$t('SUCCESSFULLY UPDATED!').toString(),
                        {
                            position: 'bottom-center',
                            fullWidth: true,
                            singleton: true,
                            fitToScreen: true,
                        },
                    );
                })
                .catch(this.saveErrorHandler);
        } else {
            this.entityDefinition.providers.create!(this.formData)
                .then((record) => {
                    this.openSpecificRecord(record.data.Id);
                    this.$toasted.success(
                        this.$t('SUCCESSFULLY SAVED!').toString(),
                        {
                            position: 'bottom-center',
                            fullWidth: true,
                            singleton: true,
                            fitToScreen: true,
                        },
                    );
                })
                .catch(this.saveErrorHandler);
        }
    }

    private askForDelete(): void {
        const out = this;
        this.$toasted.show('Res želite izbrisati zapis?', {
            fullWidth: true,
            singleton: true,
            fitToScreen: true,
            duration: 5000,
            icon: (() => 'question') as any,
            action: [
                {
                    text: this.$t('Da, izbriši').toString(),
                    onClick(e, toastObject) {
                        out.delete();
                        toastObject.goAway(0);
                    },
                },
                {
                    text: this.$t('Ne, prekliči').toString(),
                    onClick(e, toastObject) {
                        toastObject.goAway(0);
                    },
                },
            ],
        });
    }

    private delete(): void {
        if (!this.isNewForm && this.entityDefinition.providers.delete) {
            const uid = this.$route.params.uid;
            this.entityDefinition.providers
                .delete(uid)
                .catch(this.saveErrorHandler)
                .then((a) => {
                    /*this.$toasted.success(
                        this.$t('Uspešno izbrisano!').toString(),
                    );*/
                    this.$router.go(-1);
                });
        } else {
            this.$toasted.info(
                this.$t('Tega zapisa ni mogoče izbrisati.').toString(),
            );
        }
    }

    private async loadFormData() {
        this.dataLoaded = false;
        const defaultValues = this.getDefaultValues();
        const uid: string = this.$route.params.uid;

        if (uid === '-1') {
            this.formData = { ...defaultValues };
        } else {
            this.loading({ isLoading: true });

            const data = await this.entityDefinition.providers.fetchSingle!(
                uid,
            );

            this.formData = { ...defaultValues, ...data };

            await this.loadAvailableFeatures();

            this.loading({ isLoading: false });

            this.$nextTick(() => {
                this.$emit('dataLoaded', this.formData);
            });
        }

        this.dataLoaded = true;
    }

    private async loadAvailableFeatures() {
        if (this.formData.relCustomer) {
            this.availableCustomerFeatures = await GetAllCustomerFeatures(
                this.formData.relCustomer,
            );
        }
    }

    private openSpecificRecord(uid: string) {
        const currentUID = this.$route.params.uid;
        if (currentUID !== uid) {
            const name = this.$route.name;
            this.$router.push({ name, params: { uid } } as any);
            this.loadFormData();
        }
    }

    private openNewForm() {
        this.openSpecificRecord('-1');
    }

    @Watch('isNewForm')
    private setLocationInfo() {
        const state: LocationBarState = {
            entity: this.entityDefinition,
            recordId: this.$route.params.uid,
            text: 'EXACTUM',
        };

        const updateActions: LocationBarAction[] = [
            {
                type: LocationBarActionType.New,
                icon: 'plus',
                func: this.openNewForm,
            },
            {
                type: LocationBarActionType.Delete,
                icon: 'trash',
                func: this.askForDelete,
            },
        ];

        if (this.isNewForm) {
            state.text = 'Nov zapis';
            state.actions = [];
        } else {
            state.text = 'Urejanje zapisa';
            state.actions = updateActions;
        }

        this.$store.dispatch('setLocationData', state);

        this.$emit('afterCoreLocationDataSet');
    }

    @Watch('$route.params.uid')
    private locationChanged() {
        this.setLocationInfo();
        this.loadFormData();
    }

    private mounted() {
        this.setLocationInfo();
        this.loadFormData();
    }
}
