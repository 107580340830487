
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import {GridColumnDefinition, DateTimeColumnDefinition, EntityReference, SingleMediaObject} from '@/types';
import { DatetimeFormat, ParseEntityReference } from '@/util';

import moment from 'moment-timezone';
import { MediaObject } from '@/internal';

@Component({})
export default class MediaDownloadColumn extends Vue {

    @Prop() public columnDefinition!: GridColumnDefinition;
    @Prop() public columnData!: any;

    public get dataRef() {
        return this.columnData[this.columnDefinition.name];
        // return this.data;
    }

    public async downloadMedia(ref: string) {

        const er = ParseEntityReference(ref) as EntityReference;
        const d = await MediaObject.providers.fetchSingle(er.id);

        if (d) {
            const k = d as SingleMediaObject;
            const URL = process.env.VUE_APP_API_BASE_URL + k.contentUrl;
            window.open(URL, '_blank');
        } else {
            alert(this.$t('Ni mogoče prenesti datoteke').toString());
        }

    }

}
