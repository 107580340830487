import {
    ContextMenuOption,
    HierarchyItemDefinition,
    SecurityRole,
    SingleAlarmDefinition,
    SingleLocation,
    SingleSensor,
} from '@/types';
import { HierarchyMenuOptionsContext } from '../../../typeDefs/HierarchyMenuOptionsContext';
import PrepareAndWaitForReport from '../commands/PrepareAndWaitForReport';
import { CustomerFeature } from '@/typeDefs/features';
import { HierarchyItemAction } from '@/typeDefs/hierarchyItemActions';
import { ShowAllSensorsHistory } from '../commands/ShowAllSensorsHistory';
import { DefaultHistoryTimeIntervalQuery } from '@/const';
import { ParseEntityReference } from '@/util';

export default function SensorHierarchyMenuItems(
    out: HierarchyMenuOptionsContext,
): ContextMenuOption[] {
    return [
        {
            label: 'Zgodovina meritev',
            action: () => {
                out.$router.push({
                    name: 'measurementHistory',
                    query: {
                        sensors: out.nodeData.data.id,
                        ...DefaultHistoryTimeIntervalQuery(),
                    },
                });
            },
            icon: 'history',
        },
        {
            label: 'Nastavitve senzorja',
            action: () => {
                const path = out.settingsLink(out.nodeData);
                if (path && out.CanUserAccessLink(path)) {
                    out.$router.push({ path });
                }
            },
            minRole: SecurityRole.RoleOwner,
            icon: 'cog',
        },
        {
            label: 'Izvozi meritev',
            action: () => {
                out.$router.push({
                    name: 'settingsMeasurementExports',
                    query: {
                        relSensors: out.nodeData.data.id,
                    },
                });
            },
            icon: 'cloud-download-alt',
        },
        {
            label: 'measurementHistory.measurementInspection',
            action: () => {
                out.$router.push({
                    name: 'measurementHistory',
                    query: {
                        sensors: out.nodeData.data.id,
                        use: 'lastNoteInterval',
                        enableGapfill: 'true',
                        showAlarmBounds: 'true',
                    },
                });
            },
            icon: 'search',
        },

        {
            label: 'measurementHistory.showRSSIHistory',
            action: () => {
                const ref = ParseEntityReference(out.nodeData.data.id);
                out.$router.push({
                    name: 'rssiHistory',
                    params: { sensorId: ref!.id },
                });
            },
            icon: 'signal',
            minRole: SecurityRole.RoleAdmin,
            showIf(ctx: { nodeData: HierarchyItemDefinition }) {
                if (ctx.nodeData.measurementsData) {
                    const md = ctx.nodeData.measurementsData;
                    return typeof md.rssi === 'number';
                }
                return false;
            },
        },
        {
            label: 'measurementHistory.showBatteryStatusHistory',
            action: () => {
                const ref = ParseEntityReference(out.nodeData.data.id);
                out.$router.push({
                    name: 'batteryStatusHistory',
                    params: { sensorId: ref!.id },
                });
            },
            icon: 'app/battery_green',
            minRole: SecurityRole.RoleAdmin,
            showIf(ctx: { nodeData: HierarchyItemDefinition }) {
                if (ctx.nodeData.measurementsData) {
                    const md = ctx.nodeData.measurementsData;
                    return typeof md.batteryStatus === 'string';
                }
                return false;
            },
        },
        {
            showIf(ctx: { nodeData: HierarchyItemDefinition }) {
                return ctx.nodeData.data.corrections.length > 0;
            },
            label: 'Preglej podatke certifikata',
            action: () => {
                const er = ParseEntityReference(out.nodeData.data.id);
                if (er) {
                    out.$router.push({
                        name: 'viewSensorCorrections',
                        params: {
                            uid: er.id,
                        },
                    });
                }
            },
            icon: 'app/exactum_certificate',
        },
        {
            showIf(ctx: { nodeData: HierarchyItemDefinition }) {
                const sensorData = ctx.nodeData.data as SingleSensor;
                return !!(sensorData && sensorData.alarmDef);
            },
            label: 'Seznam alarmov',
            action: () => {
                const sensorData = out.nodeData.data as SingleSensor;
                const alarmDef = sensorData.alarmDef as SingleAlarmDefinition;
                out.$router.push({
                    name: 'alarmEventsGrid',
                    query: {
                        'relAlarm.relSensor': sensorData.id,
                    },
                });
            },
            icon: 'app/alarm',
        },
        {
            label: 'Omogoči GMP',
            action: () => {
                out.performItemAction(HierarchyItemAction.OpenQAManageModal);
            },
            icon: 'check',
            requiredFeature: CustomerFeature.GMP,
            showIf(context) {
                const locationData = context.nodeData.data as SingleLocation;
                return !locationData.gmpEnabled;
            },
        },
        {
            label: 'ONEMOGOČI GMP',
            action: () => {
                out.performItemAction(HierarchyItemAction.OpenQAManageModal);
            },
            icon: 'times',
            requiredFeature: CustomerFeature.GMP,
            showIf(context) {
                const locationData = context.nodeData.data as SingleLocation;
                return locationData.gmpEnabled;
            },
        },
    ];
}
