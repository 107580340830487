import { GenerateProviders } from '@/errorHandler';
import { EntityDefinition, EntityReferenceString } from '@/types';
import { RoleParams } from '@/authorization';

/**
 * {
 *   "id": "string",
 *   "email": "string",
 *   "firstname": "string",
 *   "lastname": "string",
 *   "phone": "string",
 *   "roles": [
 *     "string"
 *   ],
 *   "relCustomer": "string"
 * }
 */

export const Me: EntityDefinition = {
    primaryKey: 'email',
    getPrimaryKey(row: any) {
        return row.firstname + ' ' + row.lastname;
    },
    name: 'Me',
    pluralName: 'Me',
    endpointName: 'users',
    isPrimaryResolver: false,
    routes: {
        single: 'settingsMe',
        grid: 'dash',
    },
    settingsDisabled: true,
    providers: GenerateProviders('users', {
        entity: 'User',
        params: {
            attributes: [
                'email',
                'firstname',
                'lastname',
                'phone',
                'password',
                'locale',
                'timezone',
            ],
        },
    }),
    lookupFields: {
        title: ['firstname', 'lastname'],
    },
};
