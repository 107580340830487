
import Component from 'vue-class-component';

import RenderValue from '../RenderValue.vue';
import { Prop, Vue } from 'vue-property-decorator';
import { SingleAuditLogEntry } from '@/types';


@Component({
    components: {
        RenderValue
    }
})
export default class UserRolesChangesExplainer extends Vue {

    @Prop() public selectedRow!: SingleAuditLogEntry;

    public get changes() {
        return (this.selectedRow as any).changes.roles;
    }

}
