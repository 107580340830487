
import Vue from 'vue';
import { SecurityRole, AccessRightScope } from '../../types';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

interface MenuItem {
    name: string;
    route: string;
    entity: string;
    icon?: string;
}

@Component({
    computed: {
        ...mapGetters(['CanUserAccess']),
    },
})
export default class AppSettingsMenu extends Vue {

    private allMenuOptions: MenuItem[] = [
        { // 1
            name: 'Upravljanje strank',
            route: 'settingsCustomers',
            entity: 'customers',
            icon: 'user-tie',
        },
        { // 2
            name: 'Upravljanje lokacij',
            route: 'settingsLocations',
            entity: 'locations',
            icon: 'map-marker-alt',
        },
        { // 3
            name: 'Upravljanje prostorov',
            route: 'settingsPlaces',
            entity: 'places',
            icon: 'map-marked-alt',
        },
        { // 4
            name: 'Upravljanje senzorjev',
            route: 'settingsSensors',
            entity: 'sensors',
            icon: 'broadcast-tower',
        },
        { // 5
            name: 'Upravljanje uporabnikov',
            route: 'settingsUsers',
            entity: 'users',
            icon: 'users',
        },
        { // 7.5
            name: 'Upravljanje skupin uporabnikov',
            route: 'settingsUserGroups',
            entity: 'userGroups',
            icon: 'users',
        },
        { // 6
            name: 'Upravljanje nastavitev alarmiranja',
            route: 'settingsAlarmingView',
            entity: 'alarming',
            icon: 'bell',
        },
        { // 7
            name: 'Pregled revizijske sledi',
            route: 'settingsAudits',
            entity: 'audits',
            icon: 'history',
        },
        { // 7
            name: 'Upravljanje izvozov',
            route: 'settingsMeasurementExports',
            entity: 'measurement_exports',
            icon: 'cloud-download-alt',
        },
        { // 7
            name: 'Seznam komentarjev pregledov meritev',
            route: 'settingsMeasurementNotes',
            entity: 'measurement_notes',
            icon: 'sticky-note',
        },
        { // 7.5
            name: 'Upravljanje certifikatov',
            route: 'sensorCorrectionsGrid',
            entity: 'certificates',
            icon: 'clone',
        },
        { // 8
            name: 'Nastavitve mojega profila',
            route: 'settingsMe',
            entity: 'me',
            icon: 'user-edit',
        },
    ];

    // private get CanUserAccess(scope: AccessRightScope, entity: string, id?: string): boolean

    private get menuOptions() {
        const out = this;
        return this.allMenuOptions.filter((opt) => {
            return (out as any).CanUserAccess(AccessRightScope.Settings, opt.entity);
        });
    }

}
