import { render, staticRenderFns } from "./GridForm.vue?vue&type=template&id=435d7504&scoped=true&"
import script from "./GridForm.vue?vue&type=script&lang=ts&"
export * from "./GridForm.vue?vue&type=script&lang=ts&"
import style0 from "./GridForm.vue?vue&type=style&index=0&id=435d7504&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435d7504",
  null
  
)

export default component.exports