
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';

import DTMaskedInput from './forms/dt/DTMaskedInput.vue';
import Popper from 'vue-popperjs';
import { DateTime } from 'luxon';

@Component({
    components: {
        Datetime, DTMaskedInput, Popper,
    },
})
export default class DatetimeInput extends Vue {

    @Prop({default: false}) private disabled!: boolean;
    @Prop() private value!: string | null;
    @Prop({default: false}) private hideTimezone!: boolean;

    private get localTime() {
        if (this.value) {
            return DateTime.fromISO(this.value).toLocaleString(DateTime.DATETIME_MED);
        }

        return '';
    }


    private get tz() {
        return this.$store.getters.getAdditionalUserAttributes.timezone;
    }

    // On Load
    // Load value from prop

    // Še vedno ne dela:
    //  ✅ - Nastavljanje vrednosti koledarja
    //  ✅ - Invalid Date
    //  ✅ - Cleave.js je šit - nič več.
    // - Design

    private get displayValue(): string | null {
        return this.value;
    }

    private set displayValue(value: string | null) {
        // console.log("DISPLAY VALUE CHANGED!!!");
        this.$emit('input', value);
    }

    private get calValue(): string | null {
        return this.value;
    }

    private set calValue(value: string | null) {
        // console.log("CALENDAR VALUE CHANGED!!!");
        this.$emit('input', value);
    }

    private mounted() {
        const v = this.value;
        this.$emit('input', v);
    }

    // On user value change
    // send input event
        // On Prop change received
        // Check if display value changed
        // Change value, otherwise leave as is...

}
