import { GenerateProviders } from '@/errorHandler';
import { EntityDefinition, SingleSensor } from '@/types';
import { RoleParams } from '@/authorization';

/**
 *   "id": "string",
 *   "title": "string",
 *   "type": "string",
 *   "unit": "string",
 *   "position": "string",
 *   "alarmLow": 0,
 *   "alarmHigh": 0,
 *   "deviceIds": [
 *     "string"
 *   ],
 *   "noAccessUsers": [
 *     "string"
 *   ],
 *   "alarmSubscribers": [
 *     "string"
 *   ],
 *   "relPlace": "string",
 *   "relCustomer": "string"
 */

const providers = GenerateProviders<SingleSensor>('sensors', {
    entity: 'Sensor',
    params: {
        attributes: [
            'title',
            'Type',
            'unit',
            'position',
            'alarmLow',
            'alarmHigh',
            'deviceIds',
            'noAccessUsers',
            'alarmSubscribers',
            'relPlace',
            'relCustomer',
            'frequency',
            'titleInternal',
            'presentationUnit',
            'noAccessUsers',
        ],
        transform(row: any) {
            const k = { ...row };
            if (k.frequency) {
                k.frequency = parseInt(k.frequency, 10);
            }

            return k;
        },
    },
});

// const origFetchSingle = providers.fetchSingle;
// const PickRandomArray = (arr: string[]) => {
//     return arr[parseInt((Math.random() * arr.length) + '', 10)];
// };
// const RandomStringId = () => {
//     const abeceda = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
//     const sifra = ['0', '1' , '2', '3', '4', '5', '6'];
//     return 'FAKE-' + PickRandomArray(abeceda)
//         + PickRandomArray(sifra)
//         + PickRandomArray(abeceda)
//         + PickRandomArray(sifra)
//         + '-'
//         + PickRandomArray(sifra)
//         + PickRandomArray(abeceda)
//         + PickRandomArray(sifra)
//         + PickRandomArray(abeceda)
//         + PickRandomArray(sifra)
//         + PickRandomArray(abeceda);
// };
// const CopyCorrection = (corr: SingleCorrectionsMeta) => {
//     const newCorr = {
//         ...corr,
//         certificateId: RandomStringId(),
//         inspectionDeadline: DateTime.fromISO(corr.inspectionDeadline)
//                                     .plus({days: parseInt((Math.random() * 120) + '', 10)})
//                                     // .plus({days: 30})
//                                     .toISO(),
//     } as SingleCorrectionsMeta;
//
//     return newCorr;
// };
//
// const fakeSingle = (res: SingleSensor) => {
//     if (res.corrections && res.corrections.length > 0) {
//         res.corrections.push(CopyCorrection(res.corrections[0]));
//         res.corrections.push(CopyCorrection(res.corrections[0]));
//         res.corrections.push(CopyCorrection(res.corrections[0]));
//     }
// };
//
// // providers.fetchSingle = async (id: string) => {
// //
// //     const res = await origFetchSingle(id);
// //
// //     fakeSingle(res);
// //
// //
// //     res.corrections.sort((e2, e1) => e1.inspectionDeadline.localeCompare(e2.inspectionDeadline));
// //     return res;
// //
// // };
//
// const origFetchMultiple = providers.fetchMultiple;
// providers.overrideFetchMultiple(async (q: any) => {
//     const res = await origFetchMultiple(q, (tr: APIMetadataResponse<SingleSensor>) => {
//         for (const item of tr.data) {
//             fakeSingle(item);
//         }
//         return tr;
//     });
//
//     return res;
// });

export const Sensor: EntityDefinition<SingleSensor> = {
    primaryKey: 'title',
    name: 'Sensor',
    pluralName: 'Sensors',
    routes: {
        single: 'settingsSensorsAddEdit',
        grid: 'settingsSensors',
    },
    endpointName: 'sensors',
    isPrimaryResolver: true,
    providers,
    lookupFields: {
        title: 'title',
        detailLeft: ['type', 'unit'],
    },
    getPrimaryKey: (row: any): string => {
        return row.title;
    },
    roleBasedFilters: {
        ROLE_USER: RoleParams.UserCustomer,
        ROLE_OWNER: RoleParams.UserCustomer,
    },
};
