import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';

import E404 from './views/E404.vue';
import E401 from './views/E401.vue';

import HierarchyView from './components/hierarchy/HierarchyView.vue';
import AppSettingsMenu from './views/menus/AppSettingsMenu.vue';

import { Entities, Guards } from '@/internal';
import { EntityDefinition } from './types';
import { GetEntityNames } from './util';

const EntitiesSettings: () => RouteConfig[] = () => {
    const allEntities: { [key: string]: EntityDefinition } = Entities as any;

    const settingsRoutes = [];
    for (const entityName in allEntities) {
        if (allEntities[entityName]) {
            const entity = allEntities[entityName];
            if (!entity.settingsDisabled) {
                const names = GetEntityNames(entity);

                settingsRoutes.push(
                    ...[
                        {
                            path: `settings/${names.routeBase}`,
                            name: `settings${names.pluralUppercase}`,
                            beforeEnter: Guards.SettingsGuard,
                            props: true,
                            components: {
                                detail: () =>
                                    import(
                                        `./views/settings/${names.pluralUppercase}Settings.vue`
                                    ),
                                master: AppSettingsMenu,
                            },
                        },
                        {
                            path: `settings/${names.routeBase}/:uid`,
                            name: `settings${names.pluralUppercase}AddEdit`,
                            beforeEnter: Guards.SettingsGuard,
                            props: true,
                            components: {
                                detail: () =>
                                    import(
                                        `./views/settings/${names.pluralUppercase}SettingsSingle.vue`
                                    ),
                                master: AppSettingsMenu,
                            },
                        },
                    ],
                );
            }
        }
    }

    settingsRoutes.push({
        path: `settings/me`,
        name: `settingsMe`,
        props: true,
        beforeEnter: Guards.LoggedInGuard,
        components: {
            detail: () => import(`./views/settings/MeSettings.vue`),
            master: AppSettingsMenu,
        },
    });

    // debugger; // Uncomment this to inspect the list of settings routes added to the file
    return settingsRoutes;
};

export const PublicRoutes = ['login', 'passwordreset'];

export default function GetRouter() {
    const routes = [
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Login.vue'),
        },
        {
            path: '/passwordreset',
            name: 'passwordreset',
            component: () => import('./views/PasswordReset.vue'),
        },
        {
            path: '/download/media_objects/:uid',
            name: 'downloadMediaObject',
            component: () => import(`./views/DownloadMediaObject.vue`),
        },
        {
            path: '/error/401',
            name: 'notAllowed',
            component: E401,
        },
        {
            path: '/',
            name: 'homeNoMenus',
            component: Home,
            children: [
                {
                    path: 'measurements/history',
                    name: 'measurementHistory',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () =>
                            import(`./views/MeasurementHistoryView.vue`),
                        master: HierarchyView,
                    },
                },
                {
                    path: 'exportWaiter',
                    name: 'exportWaiter',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () => import(`./views/ExportWaiter.vue`),
                        master: HierarchyView,
                    },
                },
                {
                    // Live measurements chart
                    path: 'chart/live/:sensorId',
                    name: 'liveChart',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () => import('./views/home/LiveChartView.vue'),
                        master: HierarchyView,
                    },
                },
                {
                    // RSSI History view
                    path: 'chart/rssi/:sensorId',
                    name: 'rssiHistory',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () =>
                            import('./views/settings/RSSIHistoryView.vue'),
                        master: HierarchyView,
                    },
                },
                {
                    // RSSI History view
                    path: 'chart/battery/:sensorId',
                    name: 'batteryStatusHistory',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () =>
                            import('./views/settings/BatteryHistoryView.vue'),
                        master: HierarchyView,
                    },
                },
                {
                    path: 'hierarchyImage/:objectType/:objectId',
                    name: 'hierarchyImage',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () =>
                            import('./views/home/HierarchyObjectImageView.vue'),
                        master: HierarchyView,
                    },
                },
                {
                    // Live measurements chart
                    path: 'alarmEvent/:alarmEventId',
                    name: 'alarmEventView',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () => import('./views/home/AlarmEventView.vue'),
                        master: HierarchyView,
                    },
                },
                {
                    // Nastavitve, ki jih vidi navaden uporabnik na hierarhiji
                    // Ko pritisne na hamburger pri senzorju
                    path: 'hierarchySettings/sensors/:uid',
                    name: 'sensorHierarchySettings',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () =>
                            import('./views/home/SensorAlarmsView.vue'),
                        master: HierarchyView,
                    },
                },
                {
                    path: 'settings/dashboard/:uid',
                    name: 'dashboardEditor',
                    props: true,
                    // beforeEnter: Guards.SettingsGuard,
                    components: {
                        detail: () =>
                            import('./views/settings/DashboardEditor.vue'),
                        master: HierarchyView,
                    },
                },
                {
                    // Glavni pogled
                    path: 'dash',
                    name: 'dash',
                    props: true,
                    beforeEnter: Guards.LoggedInGuard,
                    components: {
                        detail: () => import('./views/home/Dashboard.vue'),
                        master: HierarchyView,
                    },
                },

                // All settings routes
                ...EntitiesSettings(),

                {
                    path: 'settings/corrections/:uid',
                    name: 'viewSensorCorrections',
                    props: true,
                    beforeEnter: Guards.SettingsGuard,
                    components: {
                        detail: () =>
                            import('./views/settings/SensorCorrections.vue'),
                        master: HierarchyView,
                    },
                },

                {
                    path: 'settings/corrections',
                    name: 'sensorCorrectionsGrid',
                    props: true,
                    beforeEnter: Guards.SettingsGuard,
                    components: {
                        detail: () =>
                            import(
                                './views/settings/SensorCorrectionsGrid.vue'
                            ),
                        master: AppSettingsMenu,
                    },
                },

                {
                    path: 'alarmEvents',
                    name: 'alarmEventsGrid',
                    beforeEnter: Guards.LoggedInGuard,
                    props: true,
                    components: {
                        detail: () =>
                            import('./views/alarming/AlarmingEventGrid.vue'),
                        master: HierarchyView,
                    },
                },

                {
                    path: `settings/alarming/view`,
                    name: `settingsAlarmingView`,
                    beforeEnter: Guards.SettingsGuard,
                    props: true,
                    components: {
                        detail: () =>
                            import(`./views/alarming/AlarmingViewSettings.vue`),
                        master: AppSettingsMenu,
                    },
                },

                {
                    path: `settings/alarming/edit/:relSensor`,
                    name: `settingsAlarmingEdit`,
                    beforeEnter: Guards.SettingsGuard,
                    props: true,
                    components: {
                        detail: () =>
                            import(
                                `./views/settings/AlarmingSettingsSingle.vue`
                            ),
                        master: AppSettingsMenu,
                    },
                },

                {
                    path: '*',
                    name: 'home',
                    props: true,
                    redirect: { name: 'dash' },
                    components: {
                        master: HierarchyView,
                    },
                },
            ],
        },
        {
            path: '*',
            name: 'notFound',
            component: E404,
        },
    ] as any;

    return new Router({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
    });
}
