import axios, { AxiosError, AxiosResponse } from 'axios';
import { Serializer, Deserializer } from 'jsonapi-serializer';
import {
    DataProviders,
    FetchMultipleQueryParams,
    APIMetadataResponse,
    EntityDefinition,
    SecurityRole,
    LoginInfo,
    ProgressTrackerData,
    ErrorHandlerFunction,
    TokenInfo,
    EntityPluginStep,
} from './types';
import { ParseEntityReference, ProgressTracker } from '@/util';

import { store, Entities, ExactumCache, Endpoints } from '@/internal';

import jwt_decode from 'jwt-decode';

import Vue from 'vue';

import i18n from '@/i18n';
import RunPlugins, { CorePluginAvailable } from './pluginExecutor';

function jwtExists() {
    const jwt = axios.defaults.headers.common.Authorization as string | null;
    return jwt && jwt.toLowerCase().startsWith('bearer');
}

function isJWTExpired() {
    const token = axios.defaults.headers.common.Authorization;

    let duration = 0;
    if (token && typeof token === 'string') {
        const decoded: TokenInfo = jwt_decode(token.split(' ')[1]);
        const now = Math.round(new Date().getTime() / 1000);
        duration = (decoded.exp - now - 1 * 60) * 1000;
    }
    return duration <= 0;
}

class V2Deserializer {
    public Deserialize<T = any>(resp: any, options?: any): T {
        if (!resp.data) {
            // tslint:disable-next-line: no-console
            console.error(resp);
            throw new Error('Invalid response. Cannot deserialize.');
        }

        const data = resp.data;

        if (this.isArray(data)) {
            return data.map((el: any) => this.DeserializeSingle(el, options));
        } else {
            return this.DeserializeSingle(data);
        }
    }

    private isArray(a: any): boolean {
        if (!a) {
            return false;
        }

        return Array.isArray(a);
    }

    private DeserializeSingle<T>(data: any, options?: any): T {
        const relationships: any = {};
        if (data.relationships) {
            for (const key in data.relationships) {
                if (data.relationships.hasOwnProperty(key)) {
                    relationships[key] = data.relationships[key].data.id;
                }
            }
        }

        const resultObj = {
            ...data.attributes,
            id: data.id,
            ...relationships,
        };

        return resultObj as T;
    }
}

export function DefaultProviders<R>(
    baseURL: string,
    serializationData: any,
    errorHandlerParams?: any,
    plugins?: EntityPluginStep[],
): DataProviders<R> {
    return {
        update: async (_id: string, _data: any) => {
            let id = _id;
            let data = _data;
            if (plugins) {
                const returns = await RunPlugins(plugins, 'Update', 'Pre', {
                    id,
                    data,
                });
                id = returns.id;
                data = returns.data;
            }

            let req: any = null;

            if (plugins && CorePluginAvailable(plugins, 'Update')) {
                req = await RunPlugins(plugins, 'Update', 'Core', {
                    id,
                    data,
                });
            } else {
                req = await PATCH(
                    baseURL + '/' + id,
                    data,
                    serializationData,
                    errorHandlerParams,
                );
            }

            if (plugins) {
                return await RunPlugins(plugins, 'Update', 'Post', req);
            }

            return req;
        },
        delete: async (id: string) => {
            if (plugins) {
                const returns = await RunPlugins(plugins, 'Delete', 'Pre', {
                    id,
                });
            }

            let result: any = null;
            if (plugins && CorePluginAvailable(plugins, 'Delete')) {
                result = RunPlugins(plugins, 'Delete', 'Core', { id });
            } else {
                result = DELETE(
                    baseURL + '/' + id,
                    errorHandlerParams,
                    serializationData,
                );
            }

            if (plugins) {
                result = await RunPlugins(plugins, 'Delete', 'Post', {
                    id,
                    result,
                });
            }

            return result;
        },

        create: async (rawData: any) => {
            let data = rawData;
            if (plugins) {
                data = await RunPlugins(plugins, 'Create', 'Pre', data);
            }

            let req: any = null;
            if (plugins && CorePluginAvailable(plugins, 'Create')) {
                req = await RunPlugins(plugins, 'Create', 'Core', data);
            } else {
                req = await POST(
                    baseURL,
                    data,
                    serializationData,
                    errorHandlerParams,
                );
            }

            if (plugins) {
                req = await RunPlugins(plugins, 'Create', 'Post', req);
            }

            return req;
        },
        fetchSingle: async (id: string): Promise<R> => {
            const opts = {
                noMeta: true,
                ...serializationData,
            };

            let data: any = null;
            if (plugins) {
                data = await RunPlugins(plugins, 'LoadSingle', 'Pre', data);
            }

            if (plugins && CorePluginAvailable(plugins, 'LoadSingle')) {
                data = await RunPlugins(plugins, 'LoadSingle', 'Core', { id });
            } else {
                data = await GET(
                    baseURL + '/' + id,
                    errorHandlerParams,
                    opts,
                    false,
                );
            }

            saveToCache([data], baseURL.substring(1));

            if (plugins) {
                return await RunPlugins(plugins, 'LoadSingle', 'Post', data);
            }

            return data;
        },
        fetchAll: async (
            q: FetchMultipleQueryParams,
            p?: ProgressTrackerData,
        ): Promise<APIMetadataResponse<R>> => Promise.reject(null as any),
        fetchMultiple: async (
            q: FetchMultipleQueryParams,
            p?: ProgressTrackerData,
        ): Promise<APIMetadataResponse<R>> => Promise.reject(null as any),
    };
}

export interface ErrorCodeHandler {
    code: number;
    handler: string | ErrorHandlerFunction;
}

export interface ErrorHandlerParams {
    codes?: ErrorCodeHandler[];
}

function isFunction(functionToCheck: any) {
    return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === '[object Function]'
    );
}

export function ErrorHandler(
    _error: AxiosError,
    params?: ErrorHandlerParams,
): any {
    if (!jwtExists() || isJWTExpired()) {
        // console.log('JWT Expired; Errors are all due to JWT Expiration');
        return;
    }

    const error = _error as any;

    if (error && error.response && error.response.status) {
        // Check for standard errors
        if (error.response.status === 400 && error.response.data.violations) {
            throw { violations: error.response.data.violations };
        }
    } else {
        const message = i18n
            .t('Unknown error occured during API communication.')
            .toString();

        Vue.toasted.error(message);
        throw error;
    }

    if (params && params.codes) {
        for (const { code, handler } of params.codes) {
            if (error.response.status === code) {
                if (isFunction(handler)) {
                    return (handler as ErrorHandlerFunction)(error);
                } else {
                    Vue.toasted.error(
                        i18n
                            .t(
                                'Error occured during API communication and no handler is available.',
                            )
                            .toString(),
                    );
                    throw new Error(handler as string);
                }
            }
        }
    }

    if (error.message) {
        Vue.toasted.error('Unknown error: ' + error.message);
        throw new Error('EUNKNOWN: ' + error.message);
    }

    Vue.toasted.error(
        i18n.t('Unknown error occured during API communication.').toString(),
    );
    throw new Error('EUNKNOWN');
}

function getEntitiesRelationshipsParsers(): any {
    const rels: any = {};
    for (const name of Object.keys(Entities)) {
        rels[name] = {
            valueForRelationship(relationship: any) {
                return relationship.id;
            },
        };
    }

    return rels;
}

export function SuccessHandler(
    response: AxiosResponse,
    serializationData?: any,
): Promise<any> | null {
    if (response.data) {
        if (serializationData && serializationData.dontDeserialize) {
            return Promise.resolve(response.data);
        } else if (serializationData && serializationData.useV2Deserializer) {
            const d = new V2Deserializer();
            const data = d.Deserialize(response.data, serializationData);

            if (serializationData.noMeta) {
                return data;
            } else {
                let links = {};
                if (response.data.links) {
                    links = response.data.links;
                }

                let meta = {};
                if (response.data.meta) {
                    meta = response.data.meta;
                }

                return Promise.resolve({ links, meta, data });
            }
        } else {
            return new Deserializer({
                keyForAttribute: 'camelCase',
                ...getEntitiesRelationshipsParsers(),
            })
                .deserialize(response.data)
                .then((deserialized) => {
                    if (serializationData.noMeta) {
                        return deserialized;
                    } else {
                        return {
                            links: response.data.links,
                            meta: response.data.meta,
                            data: deserialized,
                        };
                    }
                });
        }
    }

    return null;
}

export function saveToCache(data: any[], entity: string) {
    const er = ParseEntityReference('/' + entity + '/1');
    if (er) {
        ExactumCache.SaveRefs(data, er.singular);
    }
}

export async function _getEntityRoleBasedParams(
    entity: EntityDefinition,
): Promise<{ [key: string]: string | number }> {
    const e = entity;
    const s = store.getters.isLoggedIn;
    if (entity) {
        if (entity.roleBasedFilters && store.getters.isLoggedIn) {
            const userRole: SecurityRole = store.getters.MaxUserRole;
            const prov = entity.roleBasedFilters[userRole];
            if (prov) {
                return prov();
            }
        }
    }

    return {};
}

async function _checkFetchMultipleOverride(
    entity: EntityDefinition,
): Promise<string | null> {
    if (entity) {
        if (entity.fetchMultipleOverrides) {
            const userRole: SecurityRole = store.getters.MaxUserRole;
            if (entity.fetchMultipleOverrides.indexOf(userRole) >= 0) {
                const loginInfo: LoginInfo = store.getters.getLoginInfo;
                return loginInfo.user.relCustomer!;
            }
        }
    }

    return null;
}

function wrapSingleResult(data: any) {
    return {
        data: [data],
        links: {
            first: '',
            last: '',
            self: '',
        },
        meta: {
            currentPage: 1,
            itemsPerPage: 1,
            totalItems: 1,
        },
    };
}

export function GenerateProviders<R>(
    entity: string,
    serializationData: any,
    errorHandlerParams?: ErrorHandlerParams,
    plugins?: EntityPluginStep[],
): DataProviders<R> {
    const noVND = serializationData.noVND;

    const baseURL = '/' + entity;

    const dp = DefaultProviders<R>(
        baseURL,
        serializationData,
        errorHandlerParams,
        plugins,
    );

    let fetchMultiple = async (
        q: FetchMultipleQueryParams,
    ): Promise<APIMetadataResponse<R>> => {
        const entityDef = Endpoints[entity];

        let response: APIMetadataResponse<R> = emptyMultipleResponse(baseURL);

        let deserializetionOptions = { deserialize: true };
        if (serializationData) {
            deserializetionOptions = {
                deserialize: true,
                ...serializationData,
            };
        }

        const roleBased = await _getEntityRoleBasedParams(entityDef);

        const queryParams = {
            // Client code can override role params
            ...roleBased,
            ...q,
        };

        if (plugins) {
            await RunPlugins(plugins, 'LoadMultiple', 'Pre', {
                queryParams,
                entity,
            });
        }

        if (plugins && CorePluginAvailable(plugins, 'LoadMultiple')) {
            response = await RunPlugins(plugins, 'LoadMultiple', 'Core', {
                baseURL,
                queryParams,
                deserializetionOptions,
                errorHandlerParams,
                noVND,
                entity,
            });
        } else {
            const fetchMultipleOverride = await _checkFetchMultipleOverride(
                entityDef,
            );
            if (fetchMultipleOverride) {
                const d = await dp.fetchSingle(fetchMultipleOverride);
                return wrapSingleResult(d);
            }

            try {
                response = (await GETWithParams(
                    baseURL,
                    queryParams,
                    errorHandlerParams,
                    deserializetionOptions,
                    noVND,
                )) as APIMetadataResponse<R>;
            } catch (e) {
                console.error(e);
            }
        }

        if (plugins) {
            response = await RunPlugins(
                plugins,
                'LoadMultiple',
                'Post',
                response,
            );
        }

        saveToCache(response.data, entity);
        return response;
    };

    const fetchAll = async (
        q: FetchMultipleQueryParams,
        p?: ProgressTrackerData,
    ): Promise<APIMetadataResponse<R>> => {
        let allData: any[] = [];

        const progress = new ProgressTracker(p);

        progress.setBounds(0, 1, 0.01);
        let batch = null;
        do {
            batch = await fetchMultiple(q);
            if (!batch) {
                throw new Error('Cannot retrieve measurements.');
            }

            progress.setBounds(0, batch.meta.totalItems, allData.length);

            allData = allData.concat(batch.data);
            q.page += 1;
        } while (batch.links.next);

        progress.setBounds(0, 1, 1);

        return {
            data: allData,
            links: { ...batch.links },
            meta: {
                currentPage: batch.meta.currentPage,
                itemsPerPage: batch.meta.currentPage,
                totalItems: allData.length,
            },
        };
    };

    return {
        fetchSingle: dp.fetchSingle,
        fetchMultiple,
        fetchAll,
        update: dp.update,
        delete: dp.delete,
        create: dp.create,
        overrideFetchMultiple: (ov: any) => {
            fetchMultiple = ov;
        },
        serializationData,
    };
}

export function emptyMultipleResponse(
    requestUrl: string,
): APIMetadataResponse<any> {
    return {
        data: [],
        links: {
            first: requestUrl,
            last: requestUrl,
            self: requestUrl,
        },
        meta: {
            currentPage: 1,
            itemsPerPage: 0,
            totalItems: 0,
        },
    };
}

function SerializeSingleRelColumn(relString: string) {
    if (!relString) {
        return null;
    }

    const typeParts = relString.split('/');
    if (typeParts.length >= 3) {
        const refObj = {
            type: typeParts[1],
            id: relString,
        };
        return refObj;
    }

    return relString;
}

export function SerializeRelFields(row: any) {
    if (!row) {
        return null;
    }

    // Let's not mess around with form values
    const newRow = { ...row };

    for (const colName in row) {
        if (
            colName.startsWith('rel') ||
            ['parent', 'noAccessUsers', 'alarmSubscribers'].indexOf(colName) >=
                0
        ) {
            let newColumn = newRow[colName];

            if (Array.isArray(row[colName])) {
                newColumn = newRow[colName].map((rel: string) => {
                    return SerializeSingleRelColumn(rel);
                });
            } else {
                newColumn = SerializeSingleRelColumn(row[colName]);
            }

            newRow[colName] = newColumn;
        }
    }

    return newRow;
}

export function ExtractRelationships(serializationData: any) {
    if (!serializationData || !serializationData.params) {
        return {};
    }

    const k: any = serializationData.params.attributes
        .filter((c: string) => {
            return c.startsWith('rel');
        })
        .map((c: string) => {
            return {
                name: c,
                valueForRelationship(rel: any) {
                    // const type = rel.type;
                    return {
                        id: rel.id,
                        // Add primary key later...
                    };
                },
            };
        });

    const v: any = {};
    for (const rel of k) {
        if (rel.name) {
            v[rel.name] = rel;
        }
    }

    return v;
}

const CommonSerializer = (serializationData: any) => {
    const serOptions = {
        keyForAttribute: 'camelCase',
        ...serializationData.params,
    };

    if (!serOptions.transform) {
        serOptions.transform = SerializeRelFields;
    } else {
        const origTransform = serOptions.transform;
        serOptions.transform = (row: any) => {
            const transformed = origTransform(row);
            return SerializeRelFields(transformed);
        };
    }

    return new Serializer(serializationData.entity, serOptions);
};

const CommonHeaders: any = {
    'Accept': 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
};

function handlePromise(
    promise: Promise<any>,
    serializationData?: any,
    errorParams?: ErrorHandlerParams,
): Promise<any> {
    return promise
        .then((data: AxiosResponse) => SuccessHandler(data, serializationData))
        .catch((error: AxiosError) => ErrorHandler(error, errorParams));
}

export function uploadMediaObject(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return handlePromise(
        axios.post('/media_objects', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/vnd.api+json',
            },
        }),
        { noMeta: true },
        {},
    );
}

function getRequestTimeout(): number {
    return parseInt(process.env.VUE_APP_REQUEST_TIMEOUT || '30', 10) * 1000;
}

export function POST(
    url: string,
    data: any,
    serializationData: any,
    errorParams?: ErrorHandlerParams,
): Promise<any> {
    const serializedData = CommonSerializer(serializationData).serialize(data);

    return handlePromise(
        axios.post(url, serializedData, {
            headers: CommonHeaders,
            timeout: getRequestTimeout(),
        }),
        serializationData,
        errorParams,
    );
}

export function DELETE(
    url: string,
    errorParams?: ErrorHandlerParams,
    serializationData?: any,
): Promise<any> {
    return handlePromise(
        axios.delete(url, {
            headers: {
                ...CommonHeaders,
            },
        }),
        serializationData,
        errorParams,
    );
}

export function PATCH(
    url: string,
    data: any,
    serializationData: any,
    errorParams?: ErrorHandlerParams,
): Promise<any> {
    const serializedData = CommonSerializer(serializationData).serialize(data);

    return handlePromise(
        axios.patch(url, serializedData, {
            headers: CommonHeaders,
            timeout: getRequestTimeout(),
        }),
        serializationData,
        errorParams,
    );
}

export function RawPATCH(
    endpoint: string,
    data: any,
    additionalHeaders?: any,
): Promise<any> {
    const baseUrl = process.env.VUE_APP_API_BASE_URL as string;

    const url = baseUrl.substring(0, baseUrl.length - 1) + endpoint;

    return fetch(url, {
        method: 'PATCH',
        headers: {
            ...CommonHeaders,
            Authorization: axios.defaults.headers.common.Authorization,
            ...(additionalHeaders || {}),
        },
        body: JSON.stringify(data),
    });
}

export function GET(
    url: string,
    errorParams?: ErrorHandlerParams,
    serializationData?: any,
    noVND?: boolean,
): Promise<any> {
    let headers = { ...CommonHeaders };
    if (noVND) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    return handlePromise(
        axios.get(url, {
            timeout: getRequestTimeout(),
            headers,
        }),
        serializationData,
        errorParams,
    );
}

export function GETCSV(
    url: string,
    params: any,
    errorParams?: ErrorHandlerParams,
    serializationData?: any,
) {
    return axios
        .get(url, {
            params,
            timeout: getRequestTimeout(),
            headers: { Accept: 'text/csv' },
        })
        .catch(ErrorHandler)
        .then(({ data }) => {
            const dataURL = 'data:text/csv;base64,' + btoa(data);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = dataURL;
            // the filename you want
            a.download = 'export.csv';
            document.body.appendChild(a);
            a.click();
        });
}

export function GETWithParams(
    url: string,
    params: any,
    errorParams?: ErrorHandlerParams,
    serializationData?: any,
    noVND?: boolean,
): Promise<any> {
    let headers = { ...CommonHeaders };
    if (noVND) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    return handlePromise(
        axios.get(url, {
            params,
            timeout: getRequestTimeout(),
            headers,
        }),
        serializationData,
        errorParams,
    );
}
