import { HierarchyItemDefinition } from '@/types';

export async function walkTree(
    node: HierarchyItemDefinition,
    callback: (item: HierarchyItemDefinition) => Promise<any>,
) {
    if (node) {
        await callback(node);
    }

    for (const child of node.children) {
        await walkTree(child, callback);
    }
}

export async function walkTreeUpstream(
    node: HierarchyItemDefinition,
    callback: (item: HierarchyItemDefinition) => Promise<any>,
    skipFirst?: boolean,
) {
    if (node && !skipFirst) {
        await callback(node);
    }

    if (node.parent) {
        await walkTreeUpstream(node.parent, callback);
    }
}
