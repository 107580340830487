
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { SingleFormInputDefinition } from '@/types';
import BaseFormInput from './BaseFormInput';

import { uploadMediaObject } from '@/errorHandler';

import FileUpload from 'vue-upload-component';

import { MediaObject } from '@/internal';
import { ParseEntityReference } from '../../../../util';
import axios from 'axios';

Vue.filter('formatSize', (size: number) => {
    if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
    } else if  (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
    } else if  (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB';
    } else if  (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB';
    }
    return size.toString() + ' B';
});

/*
    {contentUrl: "/media/cat.jpg", id: "/media_objects/9b2d9309-f4c4-11e9-93ab-005056a5198f"}
*/
interface MediaObjectDescription {
    contentUrl: string;
    id: string;
}

@Component({
    components: {
        FileUpload,
    },
})
export default class FileUploadFormInput extends BaseFormInput {

    private file: File | null = null;

    private serverFileInfo: MediaObjectDescription | null = null;

    private tempString?: string = '';

    private get serverFileId() {
        return this.formData[this.columnDefinition.name];
    }

    private get serverFileURL() {
        if (this.serverFileInfo && this.serverFileInfo.contentUrl) {
            const baseURL = axios.defaults.baseURL;
            return baseURL + this.serverFileInfo.contentUrl.substr(1);
        }

        return null;
    }

    private allowedImageFormats = [
        'jpg', 'jpeg', 'png', 'gif', 'svg', 'pdf', 'webp',
    ];

    private get isImage() {
        if (!this.serverFileInfo) {
            return false;
        }

        for (const ext of this.allowedImageFormats) {
            if (this.serverFileInfo.contentUrl.endsWith(ext)) {
                return true;
            }
        }

        return false;
    }

    @Watch('serverFileId')
    private formDataFileChanged() {
        this.loadImage();
    }

    private replaceFile() {
        this.tempString = this.formData[this.columnDefinition.name];
        this.formData[this.columnDefinition.name] = null;
    }

    private cancelReplace() {
        this.formData[this.columnDefinition.name] = this.tempString;
        this.tempString = '';
    }

    private handleFileUpload() {
        if (this.$refs.file) {
            this.file = (this.$refs.file as any).files[0];
        }
    }

    private submitFile() {
        if (this.file) {
            const that = this;
            uploadMediaObject(this.file).then((response: any) => {
                if (response.contentUrl && response.id) {
                    const uploadedFile = response as MediaObjectDescription;
                    this.serverFileInfo = uploadedFile;
                    that.formData[that.columnDefinition.name] = uploadedFile.id;
                    that.file = null;
                    that.tempString = '';
                } else {
                    alert('Server sent an unrecognized response. '
                    + 'Upload may still have succeeded, but the application could not collect the object reference.');
                }
            });
        }
    }

    private loadImage() {
        const fileId = this.formData[this.columnDefinition.name];
        const out = this;
        if (fileId) {
            const fileRef = ParseEntityReference(fileId);
            if (fileRef) {
                MediaObject.providers.fetchSingle(fileRef.id).then((fileInfo: MediaObjectDescription) => {
                    out.serverFileInfo = fileInfo;
                });
            }
        }
    }

    private mounted() {
        if (this.serverFileId && !this.serverFileInfo) {
            this.loadImage();
        }
    }

}
