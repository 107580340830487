
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HierarchyItemDefinition } from '../../types';

@Component({
    name: 'CustomerHierarchyItem',
})
export default class CustomerHierarchyItem extends Vue {
    @Prop() private nodeData!: HierarchyItemDefinition;
    @Prop() private numChildren!: number;

    private get offlineRatio() {
        const m = this.nodeData.measurementsData;
        if (m) {
            return `(${m.numSensors - m.numOffline} / ${m.numSensors})`;
        } else {
            return '??';
        }
    }
}

