
import Component, { } from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { HierarchyViewState, RootModules } from '@/types';

import HierarchyItem from '@/components/hierarchy/HierarchyItem.vue';
import HierarchyContextMenu from '@/components/hierarchy/HierarchyContextMenu.vue';

@Component({
    components: {
        HierarchyItem,
        HierarchyContextMenu,
    },
    computed: {
        ...mapState({
            treeData: (state: RootModules) => state.HierarchyStore.tree,
        } as any)
    },
    ...mapGetters(['getHierarchyStateRestoredNotification', 'getFocusedElement', 'getHierarchyTree', 'getHierarchyViewState']),
})
export default class HierarchyTreeView extends Vue {
    @Prop() private filterBoxValue!: string;

    @Prop() private executeCommand!: any;

    private getHierarchyViewState!: HierarchyViewState;
    private getFocusedElement!: string;

    @Watch('getHierarchyTree')
    public hierarchyChanged() {
        if (this.$refs.hItem) {
            if ((this.$refs.hItem as any[]).length > 0) {
                ((this.$refs.hItem as any[])[0] as any).doShowChildren();
            } else {
                (this.$refs.hItem as any).doShowChildren();
            }
        }
    }

    @Watch('focusedElement')
    public async focusedElementChanged() {
        if (this.focusedElement) {
            const feId = this.focusedElement;

            this.$store.commit('showAppMenu');
            const el = await this._unwrapFocusedElement(this, feId, 0);
            if (el) {
                const k = el as Vue;
                k.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    @Watch('getHierarchyStateRestoredNotification')
    public async restoreState() {

        this.$store.commit('setIsAppLoading', +1);
        // Unwrap all children

        const state = this.getHierarchyViewState;

        for (const pathCompound in state.openedTabs) {
            if (pathCompound) {
                const [path, id] = pathCompound.split(':');

                await this._unwrapFocusedElement(this, path, 0);
            }
        }

        this.rescrollToViewPosition();

        this.$store.commit('setIsAppLoading', -1);
    }

    public get focusedElement() {
        return this.$store.getters.getFocusedElement;
    }

    // On state restore
    public rescrollToViewPosition() {
        const value = this.getHierarchyViewState.scrollPos;
        const l = value.length;
        const [x, y, height] = value.substr(1, l - 1).split(',');

        const view = this.$refs.mainHierarchyView as {
            scrollLeft: number,
            scrollTop: number,
            clientHeight: number,
        };

        // Scroll only if view height didn't change
        if (parseInt(height, 10) === view.clientHeight) {
            (this.$refs.mainHierarchyView as any).scrollTo(x, y);
        }
    }

    public async _unwrapFocusedElement(node: any, feId: string, depth: number) {
        // parse focused element
        const path = feId.substr(1).split('/');
        const nodes = node.$refs.hItem;

        const idx = path[depth];

        if (!nodes) {
            return Promise.resolve();
        }

        let el: any = null;
        if (nodes && !nodes.length) {
            // one node
            el = nodes;
            nodes.doShowChildren();
        } else if (nodes[idx]) {
            el = nodes[idx];
            nodes[idx].doShowChildren();
        }

        if (depth + 1 === path.length) {
            return Promise.resolve(el);
        } else {
            return new Promise((resolve, reject) => {
                node.$nextTick(() => {
                    this._unwrapFocusedElement(nodes[idx], feId, depth + 1).then((a) => {
                        resolve(a);
                    });
                });
            });
        }
    }
}



