import Vue from 'vue';
import Vuex from 'vuex';

import {LoginStore, AppMenusStore, HierarchyStore, LoadingStore, MeasurementsStore,
    AccessRightsStore, DashboardStore, MeasurementHistoryStore, RecentAlarmsStore} from '@/internal';

Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        LoginStore,
        AppMenusStore,
        HierarchyStore,
        LoadingStore,
        MeasurementsStore,
        AccessRightsStore,
        DashboardStore,
        MeasurementHistoryStore,
        RecentAlarmsStore,
    },

});
