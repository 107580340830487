
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AuditDataParser extends Vue {

    @Prop() public row: any;

}
