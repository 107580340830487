
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class NiceInput extends Vue {
    @Prop({default: () => ({})}) private blockStyles!: any;
    @Prop({default: false}) private hideDetail!: boolean;

    private get themeColor() {
        if (this.blockStyles.themeColor) {
            return this.blockStyles.themeColor;
        }

        return null;
    }

    private get niceBlockStyles() {
        if (this.themeColor) {
            return {
                borderColor: this.themeColor,
            };
        }
        return {};
    }

    private get niceMainStyles() {
        if (this.blockStyles.mainBackground) {
            return {
                backgroundColor: this.blockStyles.mainBackground,
            };
        }

        return {};
    }

    private get niceDetailStyles() {
        if (this.themeColor) {
            return {
                backgroundColor: this.themeColor,
            };
        }
        return {};
    }
}
