
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component({ })
export default class ICheckbox extends Vue {

    @Prop() private value!: boolean;

    private checkboxClicked(): void {
        this.$emit('input', !this.value);
    }

}
