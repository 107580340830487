import { ErrorHandler, GenerateProviders } from '@/errorHandler';
import {
    EntityDefinition,
    SingleUserGroup,
    APIMetadataResponse,
    APIUserGroup,
} from '@/types';
import { ParseEntityReference } from '@/util';
import { AxiosError } from 'axios';
import Vue from 'vue';

const entityDetails = {
    name: 'user_group',
    pluralName: 'UserGroups', // Watch the capitalisation - make sure the first letter is uppercase
    endpointName: 'userGroups',
};

const mapGUIToAPI = (data: SingleUserGroup): APIUserGroup => {
    const uar = data.userAccessRestrictions;

    const extractIds = (entityTypeToLookFor: string) =>
        uar
            .filter((r) => r.startsWith(`/${entityTypeToLookFor}/`))
            .map((r) => ParseEntityReference(r)?.id || '');

    return {
        title: data.title,
        relCustomer: data.relCustomer,
        relUsers: [],
        entities: [
            {
                locations: extractIds('locations'),
                sensors: extractIds('sensors'),
                places: extractIds('places'),
            },
        ],
    };
};

export const UserGroup: EntityDefinition = {
    primaryKey: 'title',
    isPrimaryResolver: true,
    getPrimaryKey(row: any) {
        if (row) {
            return row.title;
        }

        return 'unknown';
    },
    ...entityDetails,
    routes: {
        single: 'settingsUserGroupsAddEdit',
        grid: 'settingsUserGroups',
    },
    routeBase: 'user_groups',
    providers: GenerateProviders<SingleUserGroup>(
        'user_groups',
        {
            entity: 'userGroup',
            params: {
                attributes: ['title', 'relCustomer', 'entities'],
            },
        },
        {
            // Error handler
            codes: [
                {
                    code: 409,
                    handler: async (error: AxiosError): Promise<any> => {
                        Vue.toasted.error(
                            'Na skupino uporabnikov je vezan vsaj en uporabnik. Pred izbrisom iz skupine odstranite vse uporabnike.',
                        );
                    },
                },
            ],
        },
        [
            {
                stage: 'Pre',
                message: 'Create',
                priority: 1,
                async action(data: SingleUserGroup): Promise<APIUserGroup> {
                    return mapGUIToAPI(data);
                },
            },
            {
                stage: 'Post',
                message: 'LoadSingle',
                priority: 1,
                async action(data: APIUserGroup): Promise<SingleUserGroup> {
                    const rst = data.entities[0];

                    return {
                        id: data.id || '',
                        relCustomer: data.relCustomer,
                        title: data.title,
                        userAccessRestrictions: [
                            ...rst.locations.map((id) => '/locations/' + id),
                            ...rst.sensors.map((id) => '/sensors/' + id),
                            ...rst.places.map((id) => '/places/' + id),
                        ],
                    };
                },
            },
            {
                stage: 'Pre',
                message: 'Update',
                priority: 1,
                async action({
                    data,
                    id,
                }: {
                    data: SingleUserGroup;
                    id: string;
                }): Promise<{ id: string; data: APIUserGroup }> {
                    return {
                        id,
                        data: mapGUIToAPI(data),
                    };
                },
            },
        ],
    ),
    lookupFields: {
        title: 'title',
        detailLeft: 'relCustomer',
    },
};
