
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import {GridColumnDefinition} from '@/types';

@Component({})
export default class TextColumn extends Vue {

    @Prop() public columnDefinition!: GridColumnDefinition;
    @Prop() public columnData!: any;

}
