import { GenerateProviders } from '@/errorHandler';
import {
    DashboardBlockType,
    EntityDefinition,
    EntityPluginStep,
    SecurityRole,
    SensorDashBlockDef,
    SingleUser,
} from '@/types';
import { RoleParams } from '@/authorization';
import { store } from '@/internal';

export const User: EntityDefinition = {
    primaryKey: 'email',
    getPrimaryKey(row: any) {
        return row.firstname + ' ' + row.lastname;
    },
    name: 'User',
    pluralName: 'Users',
    endpointName: 'users',
    isPrimaryResolver: true,
    routes: {
        single: 'settingsUsersAddEdit',
        grid: 'settingsUsers',
    },
    lookupFields: {
        title: ['firstname', 'lastname'],
        detailLeft: 'relCustomer',
    },
    roleBasedFilters: {
        ROLE_USER: RoleParams.UserCustomer,
        ROLE_OWNER: RoleParams.UserCustomer,
    },
    providers: GenerateProviders(
        'users',
        {
            entity: 'User',
            params: {
                attributes: [
                    'email',
                    'firstname',
                    'lastname',
                    'phone',
                    'roles',
                    'relCustomer',
                    'password',
                    'locale',
                    'timezone',
                    'dashboard',
                    'adminCustomerIds',
                    'relUserGroup',
                ],
            },
        },
        undefined,
        [
            {
                /**
                If the user is an ROLE_OWNER_PLUS ans trying to create an admin with ROLE_OWNER_PLUS, 
                then this new user should have the adminCustomerId set to the current customer. 
            */
                message: 'Create',
                stage: 'Pre',
                priority: 1,
                action: async (user) => {
                    const isOwnerPlus =
                        store.getters.MaxUserRole == SecurityRole.RoleOwnerPlus;
                    if (
                        isOwnerPlus &&
                        user.relCustomer &&
                        user.roles.includes(SecurityRole.RoleAdmin)
                    ) {
                        user.adminCustomerIds = [
                            user.relCustomer.replace('/customers/', ''),
                        ];
                    }

                    return user;
                },
            },
            {
                /**
                If the user is an ROLE_OWNER_PLUS ans trying to UPDATE an admin with ROLE_OWNER_PLUS, 
                then this new user should have the adminCustomerId set to the current customer. 
            */
                message: 'Update',
                stage: 'Pre',
                priority: 1,
                action: async (d) => {
                    const isOwnerPlus =
                        store.getters.MaxUserRole == SecurityRole.RoleOwnerPlus;
                    if (
                        isOwnerPlus &&
                        d.data.relCustomer &&
                        d.data.roles.includes(SecurityRole.RoleAdmin)
                    ) {
                        d.data.adminCustomerIds = [d.data.relCustomer];
                    }

                    return d;
                },
            },
            {
                message: 'LoadSingle',
                stage: 'Post',
                priority: 1,
                action: async (user) => {
                    if (
                        user.roles.filter((r) => r === SecurityRole.RoleViewer)
                            .length > 0
                    ) {
                        user.roles = [SecurityRole.RoleViewer];
                    }

                    return user;
                },
            } as EntityPluginStep<SingleUser>,
            {
                message: 'LoadSingle',
                stage: 'Post',
                priority: 1,
                action: async (u) => {
                    if (u.adminCustomerIds) {
                        u.adminCustomerIds = u.adminCustomerIds.map(
                            (c) => '/customers/' + c,
                        );
                    }

                    return u;
                },
            } as EntityPluginStep<SingleUser>,
            {
                message: 'LoadSingle',
                stage: 'Post',
                priority: 1,
                action: async (u) => {
                    if (u.adminCustomerIds) {
                        u.adminCustomerIds = u.adminCustomerIds.map(
                            (c) => '/customers/' + c,
                        );
                    }

                    return u;
                },
            } as EntityPluginStep<SingleUser>,
            {
                /**
                2024-02-16
                API STARTED RETURNING ROLES AS OBJECT INSTEAD OF AN ARRAY, 
                WHICH IS NOT AS SPECIFIED
            */
                message: 'LoadSingle',
                stage: 'Post',
                priority: -1,
                action: async (u) => {
                    if (u.roles && !Array.isArray(u.roles)) {
                        u.roles = Object.values(u.roles);
                    }

                    return u;
                },
            } as EntityPluginStep<SingleUser>,
            {
                message: 'Update',
                stage: 'Pre',
                priority: 2,
                action: async (u) => {
                    if (u.data.adminCustomerIds) {
                        u.data.adminCustomerIds = [
                            ...u.data.adminCustomerIds.map((c) =>
                                c.replace(/\/customers\//g, ''),
                            ),
                        ];
                    }

                    return u;
                },
            } as EntityPluginStep<{ data: SingleUser; id: string }>,
        ],
    ),
};
