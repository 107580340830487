import { HierarchyItemDefinition } from '@/types';

export function SettingsLink(nodeData: HierarchyItemDefinition) {
    if (nodeData.data.id) {
        const id = nodeData.data.id;
        const p = '/settings';

        return p + id;
    }

    return null;
}
