
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { SingleFormInputDefinition, LookupFormInputDefinition,
         SingleFormInputType, LookupFilterContext, AlarmReceiversDefinition,
         EntityReferenceString, SingleUser } from '@/types';

import BaseFormInput from './BaseFormInput';
import LookupFormInput from './LookupFormInput.vue';

import { ExactumCache, User } from '@/internal';
import { ParseEntityReference } from '@/util';

@Component({
    components: {
        LookupFormInput,
    },
})
export default class AlarmReceiverFormInput extends BaseFormInput {

    private resolvedUsers: {[relUser: string]: SingleUser} = {};

    private selectedUserData = {
        user: null,
        relCustomer: null,
    };

    @Prop({default: false}) private bulkEditing!: boolean;

    private inputDef: LookupFormInputDefinition<SingleUser> = {
        type: SingleFormInputType.Lookup,
        name: 'user',
        entity: User,
        filter: {
            conditions: [
                (context: LookupFilterContext<SingleUser>) => {
                    return !context.formData.relCustomer
                        || context.thisRecord.relCustomer === context.formData.relCustomer;
                },
            ],
        },
    };

    private get userName() {
        return (userId: string) => {
            return this.resolvedUsers[userId].firstname + ' ' + this.resolvedUsers[userId].lastname;
        };
    }

    private addEditAllEntry() {
        const idx = this.userIdx('*');
        // If exists
        // Do nothing
        if (idx < 0) {
            // Else, splice the entry
            this.formValue.push({
                userId: '*',
                notifications: [],
            } as AlarmReceiversDefinition);
        }
    }

    private get availableChannels() {
        return (channels: string[]) => {
            if (this.isChannelSelected(channels, '$delete')) {
                return ['$delete'];
            } else {
                if (this.bulkEditing) {
                    return ['sms', 'email', '$delete'];
                } else {
                    return ['sms', 'email'];
                }
            }
        };
    }

    private channelSelected(value: boolean, userId: string, channel: string) {

        // Remove channel from selected channels
        const valueArrays = this.formValue.filter((c: AlarmReceiversDefinition) => c.userId === userId);
        const valueArray = valueArrays[0].notifications;
        const channelPos = valueArray.indexOf(channel);
        if (channelPos >= 0) {
            (valueArray as string[]).splice(channelPos, 1);
        }

        if (value) {
            valueArray.push(channel);
        }
    }

    private get isChannelSelected() {
        return (channels: string[], channel: string) => {
            return channels.indexOf(channel) >= 0;
        };
    }

    private async loadUserMeta(relUser: string) {
        const meta: SingleUser = await ExactumCache.ResolveReference(relUser);

        this.$set(this.resolvedUsers, relUser, meta);
    }

    private async resolveUser(userId: string) {
        if (!this.resolvedUsers[userId]) {

            const d = await ExactumCache.ResolveReference('/users/' + userId);
            this.$set(this.resolvedUsers, userId, d);
        }
    }

    private userIdx(userId: string) {
        if (!this.formValue) {
            return -1;
        }
        return this.formValue.map((c: AlarmReceiversDefinition) => c.userId).indexOf(userId);
    }

    private createDataKey() {
        this.$set(this.formData, this.columnDefinition.name, []);
    }

    @Watch('selectedUserData.user')
    private userSelected() {
        const u: EntityReferenceString | null = this.selectedUserData.user;
        const er = ParseEntityReference(u);
        if (er) {
            if (this.userIdx(er.id) < 0) {

                if (!this.formValue) {
                    this.createDataKey();
                }

                this.formValue.push({
                    userId: er.id,
                    notifications: [],
                } as AlarmReceiversDefinition);
            }
        }
    }

    private removeSubscriber(userId: string) {
        const idx = this.userIdx(userId);
        if (idx >= 0) {
            this.formValue.splice(idx, 1);
        }
    }

    private get formValue() {
        return this.formData[this.columnDefinition.name];
    }

    @Watch('formValue')
    private async formValueChanged() {
        this.selectedUserData.relCustomer = this.formData.relCustomer;
        if (this.formValue) {
            const fv: AlarmReceiversDefinition[] = this.formValue;
            for (const user of fv) {
                this.resolveUser(user.userId);
            }
        }
    }

    private mounted() {
        this.formValueChanged();
    }
}
