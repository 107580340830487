
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import DTMaskedInputSingle from './DTMaskedInputSingle.vue';

import { DateTime } from 'luxon';

@Component({
    components: {
        DTMaskedInputSingle,
    },
})
export default class DTMaskedInput extends Vue {

    @Prop() private value!: string | null;

    private dateCleaveOptions = {
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y'],
        placeholder: 'dd. mm. yyyy',
    };

    private timeCleaveOptions = {
        time: true,
        timePattern: ['h', 'm'],
        placeholder: 'hh:mm',
    };

    private tempDateValue: any = null;
    private tempTimeValue: any = null;

    private get dateValue() {
        if (this.value) {
            return DateTime.fromISO(this.value).toFormat('dd.MM.yyyy');
        }

        return null;
    }

    private set dateValue(val: any) {
        // console.log("Setting dateValue to " + val);

        this.tempDateValue = val;

        const vueInput = this.$refs.dateInput as Vue;
        const input = vueInput.$el as HTMLInputElement;
        const selectionPos = input.selectionStart;

        // console.log(selectionPos);

        this.checkDateTimeCompleteness();

        if (this.tempDateValue.length === 10 && selectionPos === 10) {
            const timeInput = (this.$refs.timeInput as Vue).$el as HTMLInputElement;
            timeInput.select();
            timeInput.focus();
        }
    }

    private get dateValueISO() {
        if (this.value) {
            return DateTime.fromISO(this.value).toISODate();
        }

        return null;
    }

    private get timeValue() {
        // console.log("Getting timeValue");
        if (this.value) {
            return DateTime.fromISO(this.value).toFormat('HH:mm');
        }

        return null;
    }

    private set timeValue(val: any) {
        // console.log("Setting timeValue to " + val);

        this.tempTimeValue = val;

        this.checkDateTimeCompleteness();
    }


    private checkDateTimeCompleteness() {

        // console.log("Checking for completeness... " + this.tempDateValue + "   ::  " + this.tempTimeValue);
        if (this.tempDateValue && this.tempTimeValue
            && this.tempDateValue.length === 10 && this.tempTimeValue.length === 5) {
            const d = this.tempDateValue.split('-');
            const t = this.tempTimeValue.split(':');
            const pl = DateTime.fromObject({
                year: d[0], month: d[1], day: d[2],
                hour: t[0], minute: t[1],
            },
            {
                zone: this.$store.getters.getAdditionalUserAttributes.timezone,
            }).toISO();

            // console.log("INPUT CHANGED! " + pl);
            this.$emit('input', pl);
            // console.log("completeness EMITTING...")
        } else {
            // console.log("Completeness failes...")
        }
    }

    @Watch('value')
    private updateCache() {
        this.tempTimeValue = this.timeValue;
        this.tempDateValue = this.dateValueISO;
    }

    private mounted() {
        this.updateCache();
    }

}
