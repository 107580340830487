
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SingleFormInputType, SecurityRole, ConstUnitInput, AlarmingSettingsFormDefinition,
        EntityReference, LookupFormInputDefinition, FormActionContext, RootModules, FormDefinition,
        SubmitViolations, SingleFormInputDefinition, LocationBarState} from '../../../types';

import DurationFormInput from './single/DurationFormInput.vue';
import BooleanFormInput from './single/BooleanFormInput.vue';
import ConstUnitNumberFormInput from './single/ConstUnitNumberFormInput.vue';
import NumberFormInput from './single/NumberFormInput.vue';
import OptionSetFormInput from './single/OptionSetFormInput.vue';
import LookupFormInput from './single/LookupFormInput.vue';

import { ExactumCache, Sensor, AlarmDefinition, Customer } from '@/internal';
import AlarmReceiverFormInput from './single/AlarmReceiverFormInput.vue';

import BaseForm from '@/components/input/forms/BaseForm';
import { ParseEntityReference } from '@/util';
import { YesNoOptionSet } from '@/typeDefs/optionSets';
import { SingleAlarmEvent, SingleAlarmDefinition, SingleSensor } from '@/types';
import { mapState } from 'vuex';

@Component({
    components: {
        NumberFormInput, ConstUnitNumberFormInput, DurationFormInput, AlarmReceiverFormInput,
        OptionSetFormInput, BooleanFormInput, LookupFormInput,
    },
    computed: {
        ...mapState({
            loadingInfo: (state: RootModules) => state.LoadingStore.form,
        } as any),
    },
})
export default class AlarmingSettingsForm extends BaseForm {
    @Prop({default: 'Pregled definicije'}) private formTitle!: string;
    @Prop({default: 'Definicija alarmov'}) private formSubtitle!: string;

    @Prop({default: null}) private relSensor!: string | null;
    private sensorMeta: SingleSensor | null = null;

    @Prop({default: false}) private bulkEditing!: boolean;

    private get alarmingFormDefinition(): AlarmingSettingsFormDefinition {
        return this.formDefinition as AlarmingSettingsFormDefinition;
    }

    private delaysInfo = {
        subscribersLevel2: {},
        subscribersLevel3: {},
    };

    private setLocationInfo() {
        const state: LocationBarState = {
            text: this.$t('Nastavljanje alarmne definicije').toString(),
        };

        this.$store.dispatch('setLocationData', state);
    }

    private isDataReady: boolean = false;

    private formDef: any = null;

    private get atLeastOneSubscriber() {

        if (this.formData.subscribersLevel1 && this.formData.subscribersLevel2 && this.formData.subscribersLevel3) {
            return (this.formData.subscribersLevel1.length
                  + this.formData.subscribersLevel2.length
                  + this.formData.subscribersLevel3.length) >= 1;
        } else {
            return false;
        }

    }

    private get areThereAnySubscribers() {
        return (res: any) => {
            return !res || res.length === 0;
        };
    }

    public unsetValue(value: boolean, inputName: string) {
        if (value) {
            this.$delete(this.formData, inputName);
        }
    }

    public removeAllSubscribers(value: boolean, inputName: string) {
        if (value) {
            this.$set(this.formData, inputName, []);
        }
    }

    public async getSensorAlarmDefinition(relSensor: string) {
        const defs = await AlarmDefinition.providers.fetchAll({
            page: 1,
            itemsPerPage: 60,
            relSensor,
        });

        for (const resp of defs.data) {
            if (resp.relSensor === relSensor) {
                return resp;
            }
        }

        return null;
    }

    private setFormDef() {
        this.formDef = {
            activeTick: {
                type: SingleFormInputType.Boolean,
                name: 'active',
                label: 'Alarmiranje omogočeno',
                required: true,
                options: YesNoOptionSet,
                security: {
                    minReadRole: SecurityRole.RoleAdmin,
                    minWriteRole: SecurityRole.RoleAdmin,
                },
            },
            inputBlocks: [
                {
                    title: 'Zgornja meja',
                    inputs: [
                        ({
                            name: 'alarmHigh',
                            label: 'Sproži alarm, ko vrednost senzorja naraste nad',
                            type: SingleFormInputType.ConstUnitNumber, // SingleFormInputType.Number,
                            required: true,
                            sensorMeta: this.sensorMeta,
                        } as ConstUnitInput),
                        {
                            name: 'delayHighValue',
                            label: 'Zamik alarma pri pošiljanju opozorila',
                            type: SingleFormInputType.Duration,
                            required: true,
                        },
                    ],
                },
                {
                    title: 'Senzor brez povezave',
                    inputs: [
                        {
                            name: 'alarmOffline',
                            label: 'Senzor se obravnava kot brez povezave, če ne pošlje meritve več kot X minut',
                            type: SingleFormInputType.Duration, // SingleFormInputType.Number,
                            required: true,
                        },
                        {
                            name: 'delayOffline',
                            label: 'Zamik alarma pri pošiljanju opozorila',
                            type: SingleFormInputType.Duration, // SingleFormInputType.Number,
                            required: true,
                        },
                    ],
                },
                {
                    title: 'Spodnja meja',
                    inputs: [
                        ({
                            name: 'alarmLow',
                            label: 'Sproži alarm, ko vrednost senzorja pade pod',
                            type: SingleFormInputType.ConstUnitNumber, // SingleFormInputType.Number,
                            required: true,
                            sensorMeta: this.sensorMeta,
                        } as ConstUnitInput),
                        {
                            name: 'delayLowValue',
                            label: 'Zamik alarma pri pošiljanju opozorila',
                            type: SingleFormInputType.Duration,
                            required: true,
                        },
                    ],
                },
                {
                    title: 'Delta alarm',
                    inputs: [
                        ({
                            name: 'alarmDelta',
                            label: 'Vrednost odstopanja od trenutne meritve (če je 0, je alarm izključen)',
                            type: SingleFormInputType.ConstUnitNumber, // SingleFormInputType.Number,
                            required: true,
                            sensorMeta: this.sensorMeta,
                        } as ConstUnitInput),
                        {
                            name: 'deltaInterval',
                            label: 'Časovni interval',
                            type: SingleFormInputType.Duration,
                            required: true,
                        },
                    ],
                },
                {
                    title: 'alarmDefinition.editor.secondAndThirdLevelOffsets',
                    inputs: [
                        ({
                            name: 'delayLevel2',
                            label: 'alarmDefinition.editor.secondAndThirdLevelOffsets.secondLevel',
                            type: SingleFormInputType.Duration, // SingleFormInputType.Number,
                            required: false,
                        } as SingleFormInputDefinition),
                        ({
                            name: 'delayLevel3',
                            label: 'alarmDefinition.editor.secondAndThirdLevelOffsets.thirdLevel',
                            type: SingleFormInputType.Duration, // SingleFormInputType.Number,
                            required: false,
                        } as SingleFormInputDefinition),
                    ],
                },
            ],
            receivers: [
                {
                    name: 'subscribersLevel1',
                    label: 'Prvi krog prejemnikov',
                    type: SingleFormInputType.AlarmReceivers,
                    required: true,
                },
                {
                    name: 'subscribersLevel2',
                    label: 'Drugi krog prejemnikov',
                    type: SingleFormInputType.AlarmReceivers,
                    required: true,
                },
                {
                    name: 'subscribersLevel3',
                    label: 'Tretji krog prejemnikov',
                    type: SingleFormInputType.AlarmReceivers,
                    required: true,
                },
            ],
        };
    }

    private retrieveRelSensor() {
        const relSensor = this.alarmingFormDefinition.relSensor;

        if (typeof relSensor === 'string') {
            return relSensor;
        }

        if (typeof relSensor === 'function') {
            return relSensor();
        }

        return null;
    }

    private async getSensorMeta(relSensor: string) {

        const er: EntityReference | null = ParseEntityReference(relSensor);
        if (er === null) {
            return null;
        }

        return Sensor.providers.fetchSingle(er.id);
    }

    /**
     * Tale metoda vrne APIMetadataResponse in ne SingleSensor.
     * Ta response bi bilo treba deserializirat, zato je bolj primerno, da
     * po Create-u znova querijaš
     */
    private async createDefaultAlarmDefinition(meta: SingleSensor) {

        const refCustomer = ParseEntityReference(meta.relCustomer);
        const refSensor = ParseEntityReference(meta.id);

        return AlarmDefinition.providers.create({
            active: false,
            alarmLow: 0,
            alarmHigh: 100,
            alarmOffline: 60,
            delayHighValue: 60,
            delayLowValue: 60,
            delayOffline: 60,
            delayBackToNormal: 60,
            deltaInterval: 60,
            alarmDelta: 0,
            relSensor: meta.id,
            relCustomer: meta.relCustomer,
        } as SingleAlarmDefinition) ;

    }

    private async mounted() {

        if (this.bulkEditing) {
            this.isDataReady = true;
            this.setFormDef();
            return;
        }

        this.setLocationInfo();

        this.loading({
            isLoading: true,
            progress: 0,
            loadingText: 'Nalaganje...',
        });

        // Preveri, če obstaja relSensor
        const relSensor = this.retrieveRelSensor();
        if (!relSensor) {
            this.loading({
                isLoading: true,
                progress: 0,
                loadingText: 'Napaka',
                loadingFailed: true,
                loadingFailureText: 'Povezava do senzorja ni podana, program ne more odpreti strani.',
            });

            return;
        }

        // Preveri, če senzor obstaja
        const sensorMeta: SingleSensor | null = await this.getSensorMeta(relSensor);
        if (!sensorMeta) {
            this.loading({
                isLoading: true,
                progress: 0,
                loadingText: 'Napaka',
                loadingFailed: true,
                loadingFailureText: 'Senzor s to številko ni najden. Poskusite ponovno odpreti nastavitve',
            });

            return;
        }
        this.$set(this, 'sensorMeta', sensorMeta);

        // Preveri, če za ta senzor obstaja definicija alarmiranja.
        let alarmDefinition: SingleAlarmDefinition | null = await this.getSensorAlarmDefinition(relSensor);
        if (!alarmDefinition) {

            // Če ne obstaja,
            try {
                const ad = await this.createDefaultAlarmDefinition(sensorMeta);
                alarmDefinition = await this.getSensorAlarmDefinition(relSensor);
            } catch (e) {
                this.$toasted.error(JSON.stringify(e));
                this.loading({
                    isLoading: false,
                    loadingFailed: true,
                    loadingFailureText: this.$t('Napaka pri ustvarjanju alarmne definicije!').toString()
                                        + JSON.stringify(e),
                });
            }
        }

        this.setFormDef();
        this.formData = alarmDefinition;
        this.isDataReady = true;

        this.loading({
            isLoading: false,
        });
    }

    private async reloadSensorAlarmDef() {
        this.isDataReady = false;
        this.loading({
            isLoading: true,
            progress: 0,
            loadingText: 'Nalaganje...',
        });

        const relSensor = this.retrieveRelSensor();
        if (!relSensor) {
            this.loading({
                isLoading: true,
                progress: 0,
                loadingText: 'Napaka',
                loadingFailed: true,
                loadingFailureText: 'Povezava do senzorja ni podana, program ne more odpreti strani.',
            });

            return;
        }

        const alarmDefinition: SingleAlarmDefinition | null = await this.getSensorAlarmDefinition(relSensor);
        this.formData = alarmDefinition;
        this.isDataReady = true;

        this.loading({
            isLoading: false,
        });
    }

    private async save() {

        const startTime = (new Date()).getTime();

        const fd = this.formData;

        if (fd.deltaInterval === 0) {
            this.formData.deltaInterval = 60;
        }

        this.loading({
            isLoading: true,
        });

        const er = ParseEntityReference(this.formData.id);

        try {
            await AlarmDefinition.providers.update(er!.id, this.formData);

            this.$toasted.success(this.$t('Definicija alarmiranja uspešno posodobljena.').toString());
        } catch (error) {
            const e: SubmitViolations = error;
            if (e.violations) {
                for (const v of e.violations) {
                    this.$toasted.error(
                        this.$t('Napaka pri izpolnjevanju polja:')
                        + ' '
                        + this.$t(v.propertyPath)
                        + ' - '
                        + this.$t(v.message));
                }
            }
        }

        await this.reloadSensorAlarmDef();

        this.loading({
            isLoading: false,
        });

    }
}
