
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SingleFormDefinition, EntityDefinition, FormDefinition } from '@/types';

import BaseSingleForm from '@/components/input/forms/BaseSingleForm';

@Component({})
export default class SingleForm extends BaseSingleForm {

}
