import { EntityDefinition } from '@/types';

//
//
// This module is used to prevent dependancy cycles
// At some point the application became so hard to maintain
// due to so many cycles, that a more permanent fix was required.
//
// A rule of thumb: First import the new module into internal.ts and sort out
// the cycles, then use the module elsewhere.
//
// As inspired by:
// tslint:disable-next-line:max-line-length
// https://medium.com/visual-development/how-to-fix-nasty-circular-dependency-issues-once-and-for-all-in-javascript-typescript-a04c987cf0de
//

// Entities
import { Customer } from '@/entities/customer';
import { Location } from '@/entities/location';
import { MediaObject, GetImageUrl } from '@/entities/mediaObject';
import { Place } from '@/entities/place';
import { User } from '@/entities/user';
import { Me } from '@/entities/me';
import { Sensor } from '@/entities/sensor';
import { Measurement } from '@/entities/measurement';
import { MeasurementExport } from '@/entities/measurementExport';
import { AlarmDefinition } from '@/entities/alarmDefinition';
import { AlarmEvent } from '@/entities/alarmEvent';
import { MeasurementLatest } from '@/entities/measurementLatest';
import { MeasurementAverage } from '@/entities/measurementAverage';
import {
    AlarmFilterView,
    BuildAlarmFilterView,
} from '@/entities/alarmFilterView';
import { DashAlarms } from '@/entities/dashAlarms';
import { AuditLogs } from '@/entities/audits';
import { AlarmDefSensorComposite } from '@/entities/alarmDefSensorComposite';
import { MeasurementNote } from '@/entities/measurementNotes';
import { UserGroup } from './entities/userGroup';

export const Entities = {
    Customer,
    Location,
    MediaObject,
    Place,
    User,
    Me,
    Sensor,
    Measurement,
    MeasurementExport,
    AlarmDefinition,
    AlarmEvent,
    AuditLogs,
    MeasurementNote,
    UserGroup,
} as { [key: string]: EntityDefinition };

export const Endpoints: { [s: string]: EntityDefinition } = Object.fromEntries(
    Object.keys(Entities).map((realEntityName: string) => {
        const es = Entities;
        const e = (es as any)[realEntityName];
        return [e.endpointName, e];
    }),
);

export const GetEntityByRef = (ref: string): EntityDefinition | null => {
    const er = ParseEntityReference(ref);
    if (er) {
        const refEndpoint = er.type;
        const result = Object.entries(Entities)
            .filter(
                ([entityName, entity]) =>
                    entity.isPrimaryResolver &&
                    entity.endpointName === refEndpoint,
            )
            .map(([entityName, currentEntity]) => {
                return currentEntity;
            });
        if (result.length === 1) {
            return result[0];
        } else {
            // tslint:disable-next-line:no-console
            console.error(
                'Cannot find entity resolver - more than one resolver exists for a given endpoint',
            );
        }
    }

    return null;
};

// Stores
import LoginStore from '@/store/login';
import AppMenusStore from '@/store/appMenus';
import HierarchyStore from '@/store/hierarchy';
import LoadingStore from '@/store/loading';
import MeasurementsStore from '@/store/measurements';
import DashboardStore from '@/store/dashboard';
import MeasurementHistoryStore from '@/store/measurementHistory';
import RecentAlarmsStore from '@/store/recentAlarms';
import AccessRightsStore from '@/store/accessRights';

import store from '@/store/root'; // ✅

import { Guards } from '@/authorization'; // ✅

import GetRouter from '@/router'; // ✅

import ExactumCache from '@/cache'; // ✅
import { ParseEntityReference } from './util';
import { entries } from 'lodash';

export {
    store,
    GetRouter,
    ExactumCache,
    // Stores:
    LoginStore,
    AppMenusStore,
    HierarchyStore,
    LoadingStore,
    MeasurementHistoryStore,
    MeasurementsStore,
    DashboardStore,
    AccessRightsStore,
    RecentAlarmsStore,
    // Entities
    Customer,
    Location,
    MediaObject,
    Place,
    User,
    Me,
    Sensor,
    Measurement,
    MeasurementExport,
    AlarmDefinition,
    AlarmEvent,
    MeasurementLatest,
    MeasurementAverage,
    DashAlarms,
    AuditLogs,
    AlarmDefSensorComposite,
    MeasurementNote,
    UserGroup,
    // AlarmFilterView - Virtual entity
    AlarmFilterView,
    BuildAlarmFilterView,
    // Authorization
    Guards,
    // Etc
    GetImageUrl,
};
