
import Component from 'vue-class-component';

import { Prop, Vue } from 'vue-property-decorator';
import { SingleAuditLogEntry } from '@/types';

import UpdatedTimestampFormatter from '@/components/util/UpdatedTimestampFormatter.vue';

@Component({
    components: {
        UpdatedTimestampFormatter
    }
})
export default class UpdatedTimestampAuditExplainer extends Vue {

    @Prop() public selectedRow!: SingleAuditLogEntry;
    @Prop() public fieldName!: string;

    public get changes() {
        return (this.selectedRow as any).changes[this.fieldName];
    }

}
