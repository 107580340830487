import { GenerateProviders } from '@/errorHandler';
import {
    EntityDefinition,
    FetchMultipleQueryParams,
    DateTimeString,
    AttributeType,
    OptionSetEntityAttribute,
    EntityReferenceString,
    EntityPluginStep,
    SingleMeasurementExport,
} from '@/types';
import {
    ExportFrequencyOptionSet,
    ExportFormatOptionSet,
} from '@/typeDefs/optionSets';
import { DateTime } from 'luxon';

export const MeasurementExport: EntityDefinition = {
    primaryKey: 'title',
    getPrimaryKey: (row: any): string => {
        return row.title;
    },
    name: 'MeasurementExport',
    pluralName: 'MeasurementExports',
    routes: {
        single: 'settingsMeasurementExportsAddEdit',
        grid: 'settingsMeasurementExports',
    },
    routeBase: 'measurement_exports',
    endpointName: 'measurement_exports',
    isPrimaryResolver: true,
    lookupFields: {
        title: 'title',
    },

    providers: GenerateProviders(
        'measurement_exports',
        {
            entity: 'MeasurementExport',
            params: {
                attributes: [
                    'title',
                    'relLocation',
                    'relUser',
                    'locale',
                    'frequency',
                    'format',
                    'startTimestamp',
                    'endTimestamp',
                    'relCustomer',
                    'relSensors',
                    'timezone',
                    'settings',
                ],
            },
        },
        undefined,
        [
            {
                stage: 'Post',
                message: 'LoadSingle',
                priority: 1,
                async action(result: any) {
                    if (result.settings && result.settings.xlsxInterval) {
                        result['settings.xlsxInterval'] =
                            result.settings.xlsxInterval;
                    }

                    if (result.settings && result.settings.exportingDisabled) {
                        result['settings.exportingDisabled'] =
                            result.settings.exportingDisabled;
                    }

                    return result;
                },
            } as EntityPluginStep,
            {
                stage: 'Pre',
                message: 'Create',
                priority: 1,
                async action(awtData: any) {
                    const data = { ...awtData };

                    if (awtData.endTimestamp) {
                        data.endTimestamp = DateTime.fromISO(
                            awtData.endTimestamp,
                        )
                            .toUTC()
                            .toISO();
                    }

                    if (awtData.startTimestamp) {
                        data.startTimestamp = DateTime.fromISO(
                            awtData.startTimestamp,
                        )
                            .toUTC()
                            .toISO();
                    }

                    if (awtData['settings.xlsxInterval']) {
                        data.settings = {
                            xlsxInterval: awtData['settings.xlsxInterval'],
                        };

                        delete data['settings.xlsxInterval'];
                    }

                    return data;
                },
            } as EntityPluginStep,
            {
                stage: 'Pre',
                message: 'Update',
                priority: 1,
                async action(payload) {
                    const id = payload.id;
                    const rawData = payload.data;

                    // PAY ATTENTION!!
                    // The data is BLANK INITIALLY!! Endpoint only allows for changing the settings
                    const data: { [key: string]: any } = {};

                    if (rawData['settings.exportingDisabled']) {
                        data.settings = {
                            exportingDisabled:
                                rawData['settings.exportingDisabled'],
                        };

                        delete data['settings.exportingDisabled'];
                    }
                },
            },
        ],
    ),
};
