
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HierarchyItemDefinition, SecurityRole } from '@/types';

import SensorSignalIndicator from '../functional/SensorSignalIndicator.vue';
import SensorBatteryIndicator from '../functional/SensorBatteryIndicator.vue';
import QAEnabledIndicator from '../functional/QAEnabledIndicator.vue';

import moment from 'moment-timezone';
import {
    HierarchyNodeSensorValue,
    HierarchyNodeSensorUnit,
} from '@/util/sensorValues';
import { HierarchyItemAction } from '@/typeDefs/hierarchyItemActions';
import QAManageModal from '../qa/QAManageModal.vue';

interface SensorData {
    nodeType: string;
    data: {
        sensorType: string;
        online: boolean;
        name: string;
        positionName: string;
        value: string;
    };
    children: null;
}

@Component({
    name: 'SensorHierarchyItem',
    components: {
        SensorSignalIndicator,
        SensorBatteryIndicator,
        QAEnabledIndicator,
        QAManageModal,
    },
})
export default class SensorHierarchyItem extends Vue {
    @Prop() private nodeData!: HierarchyItemDefinition;

    private MM = moment;

    private newMsmt: number = Math.round(Math.random() * 100);

    private qaManageModalOpen = false;

    public performAction(type: HierarchyItemAction) {
        if (type === HierarchyItemAction.OpenQAManageModal) {
            this.qaManageModalOpen = true;
        }
    }

    private get isOffline() {
        return (
            !this.nodeData.measurementsData ||
            this.nodeData.measurementsData.numOffline > 0
        );
    }

    private get isUserAdmin() {
        return this.$store.getters.IsUserAtLeast(SecurityRole.RoleAdmin);
    }

    private get offlineDate() {
        const k = this.nodeData.measurementsData;
        if (k && k.readTimestamp) {
            return k.readTimestamp;
        }

        return null;
    }

    get sensorIcon(): string {
        let name = 'sensors/';
        name += this.nodeData.data.Type;
        if (this.isOffline) {
            name += '_o';
        }
        return name;
    }

    get sensorValue() {
        return HierarchyNodeSensorValue(this.nodeData);
    }

    get sensorUnit(): string {
        return HierarchyNodeSensorUnit(this.nodeData.data);
    }
}
