
import { DatetimeFormat } from '@/util';
import { DateTime } from 'luxon';
import Component from 'vue-class-component';

import { Prop, Vue } from 'vue-property-decorator';


@Component({

})
export default class UpdatedTimestampFormatter extends Vue {

    @Prop() public rawValue!: { date: string; timezone: string };

    public get formattedValue() {


        const tz = this.rawValue.timezone === 'UTC' ? 'Z' : this.rawValue.timezone;

        const parsedDateTime = DateTime.fromISO(this.rawValue.date.replace(/\.\d\d\d\d\d\d/g, '').replace(' ', 'T') + tz).setLocale('sl');
        return parsedDateTime.toLocaleString(DateTime.DATETIME_SHORT);
    }



}

