
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { SingleFormInputDefinition } from '@/types';
import BaseFormInput from './BaseFormInput';

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

@Component({
    components: {
        Datetime,
    },
})
export default class DateTimeFormInput extends BaseFormInput {}
