
import BaseFormInput from './BaseFormInput';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class OptionSetFormInput extends BaseFormInput {

    private translateOptions(opts: string[]): Array<{code: string, label: string}> {
        return opts.map((col: string) => {
            return {
                code: col,
                label: this.$t(col).toString(),
            };
        });
    }

}
