import { GenerateProviders } from '@/errorHandler';
import {
    EntityDefinition,
    EntityAttributesCollection,
    AttributeType,
    SingleMeasurementAverage,
} from '@/types';

const entity: EntityDefinition<SingleMeasurementAverage> = {
    primaryKey: 'ts',
    name: 'measurementAverage',
    pluralName: 'seasurementAverages',
    routeBase: 'measurement_averages',
    endpointName: 'measurement_averages',
    isPrimaryResolver: true,
    routes: {
        single: 'settingsMeasurementsAddEdit',
        grid: 'settingsMeasurements',
    },
    providers: GenerateProviders('measurement_averages', {
        entity: 'MeasurementAverage',
        params: {
            attributes: ['ts', 'min', 'max', 'avg'],
        },
    }),
    lookupFields: {
        title: 'ts',
    },
};

entity.providers.fetchAll = entity.providers.fetchMultiple;

export const MeasurementAverage = entity;
