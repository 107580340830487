import Vue from 'vue';
import Component from 'vue-class-component';

import { ExactumCache, store } from '@/internal';
import UnitManager from './units';
import { Location } from 'vue-router';
import { SingleUser } from './types';
import { LocaleToAPILocale } from './typeDefs/optionSets';
import { PublicRoutes } from './router';

@Component({})
export default class Init extends Vue {
    private showLoadingIndicator: boolean = true;

    private get isLoggedIn() {
        return this.$store.getters.isLoggedIn;
    }

    private get getInitLoadingReason() {
        return this.$store.getters.getInitLoadingReason;
    }

    private get didAppLoginChange(): null | { type: 'login' | 'logout' } {
        return this.$store.getters.didAppLoginChange;
    }

    private async sequence() {
        await this.globalInit();

        const versionMatches = await this.$store.dispatch('checkServerVersion');
        if (!versionMatches) {
            this.showLoadingIndicator = false;
            this.$store.commit(
                'setInitLoadingReason',
                'VersionMismatch. Please reload.',
            );

            return;
        }

        const isCacheInitialized = await ExactumCache.IsCacheInitialized();

        if (this.didAppLoginChange) {
            if (this.didAppLoginChange.type === 'login') {
                await this.preSuccessfulLoginDataLoading();
                await this.dataLoading();
                await this.postSuccessfulLoginInit();
            } else {
                await this.postLogoutInit();
            }
            this.$store.commit('resetAppInitLoginChange');
            await this.sequence();
        } else {
            if (!this.isLoggedIn || !isCacheInitialized) {
                await this.preLoginInit();
            } else {
                await this.postLoggedInInit();
            }

            // Init is completed.
            this.$store.commit('appInitCompleted', true);
        }
    }

    private async preSuccessfulLoginDataLoading() {
        if (this.$store.getters.getAdditionalUserAttributes) {
            const attrs: SingleUser =
                this.$store.getters.getAdditionalUserAttributes;
            if (attrs.locale && attrs.locale.trim() !== '') {
                const apiLocales = Object.entries(LocaleToAPILocale).filter(
                    ([k, v]) => v === attrs.locale,
                );
                if (apiLocales.length === 1) {
                    this.$root.$i18n.locale = apiLocales[0][0];
                }
            }
        }
    }

    private mounted() {
        this.$store.commit('setInitLoadingReason', 'Priprava');
        return this.sequence();
    }

    private async globalInit() {
        this.$store.commit('setInitLoadingReason', 'Nalaganje');
        // alert("Global init");

        const data = await ExactumCache.GetLoginData();

        if (data.language) {
            this.$i18n.locale = data.language;
        }
    }

    private async postSuccessfulLoginInit() {
        if (this.$route.query.returnPath) {
            const returnPath = this.$route.query.returnPath;
            this.$router.push({ path: returnPath } as Location);
        } else {
            this.$router.push({ name: 'dash' });
        }
    }

    private async postLogoutInit() {
        this.$store.commit('setInitLoadingReason', 'Odjavljanje');

        this.$store.commit('clearMeasurementHistorySelection');

        this.$store.commit('clearHierarchyViewState');
        this.$store.commit('clearLoadingStore');

        this.$store.dispatch('DisableInactivityWatchdog');
    }

    private async preLoginInit() {
        this.$store.commit('setInitLoadingReason', 'Pripravljanje prijave');

        const routeName = this.$route.name || '';
        // if (PublicRoutes.indexOf(routeName) < 0) {
        //     this.$router.push({name: 'login', query: {
        //         returnPath: this.$route.fullPath,
        //     }});
        // }

        return ExactumCache.ClearIfAvailable();
    }

    private async dataLoading() {
        this.$store.commit(
            'setInitLoadingReason',
            'Nalaganje podatkov aplikacije',
        );

        // this.loginLoadingInfo = 'Reloading cache';
        // this.showLoginLoadingInfo = true;
        await ExactumCache.ReloadWhole();
    }

    //
    // This method runs when the user is logged in and the data is initially loaded
    // Runs on page reload
    // Runs after login
    private async postLoggedInInit() {
        if (this.$store.getters.getInitForceFullReload) {
            await this.dataLoading();
            this.$store.commit('appInitDoReloadData', 'NO');
        }

        // Setup unit manager
        await UnitManager.Setup();

        this.$store.commit(
            'setInitLoadingReason',
            'Grajenje hierarhije, preračunavanje trenutne situacije',
        );

        await this.$store.dispatch('rebuildTree');

        const out = this;
        window.addEventListener('beforeunload', (event) => {
            out.$store.dispatch('saveHierarchyState');
            ExactumCache.StoreViewState(
                out.$store.getters.getHierarchySavedState,
                'hierarchy',
            );
        });
    }
}
