
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { SettingsLink } from '../util/settingsLink';
import { HierarchyItemDefinition } from '@/types';

@Component({})
export default class HierarchyItemMenuButton extends Vue {

    @Prop() private nodeData!: HierarchyItemDefinition;
    @Prop() private HamburgerClick!: HierarchyItemDefinition;

    private get settingsLink() {
        return SettingsLink;
    }
}
