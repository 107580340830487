import {
    HierarchyItemDefinition,
    HierarchyNodeType,
    SingleSensor,
    SingleLocation,
    SinglePlace,
    SingleCustomer,
    SingleAlarmDefinition,
    SingleEntityRecord,
} from '@/types';
import { ExactumCache, GetEntityByRef } from '@/internal';
import { walkTree, walkTreeUpstream } from '@/util/hierarchyUtils';
import { ParseEntityReference } from '@/util';
import Vue from 'vue';

const depthLimit = 20;
interface RefDictionary {
    places: SinglePlace[];
    locations: SingleLocation[];
    sensorsByID: Dict<SingleSensor>;
    customers: SingleCustomer[];
    sensors: SingleSensor[];
}

interface Dict<T> {
    [key: string]: T;
}

export default {
    async BuildHierarchyData(): Promise<HierarchyItemDefinition[]> {
        const data = await loadData();

        const tree = _fillCustomers(data, 0);

        for (const node of tree) {
            _walkSumSensors(node);
        }

        return tree;
    },
};

export const UpdateNodeData = async (node: HierarchyItemDefinition) => {
    const entityRef = (node.data as SingleEntityRecord).id;
    const er = ParseEntityReference(entityRef);
    // Determine the entity
    const entity = GetEntityByRef(entityRef);
    if (entity && er) {
        // Use findOne
        const newData = await entity.providers.fetchSingle(er.id);
        // refresh node data
        Vue.set(node, 'data', { ...node.data, ...newData });
    }
};

export const RefreshHierarchyData = async (
    subtree: HierarchyItemDefinition,
): Promise<any> => {
    await walkTree(subtree, UpdateNodeData);
};

export const RefreshUpstreamHierarchyData = async (
    subtree: HierarchyItemDefinition,
): Promise<any> => {
    await walkTreeUpstream(subtree, UpdateNodeData);
};

function _walkSumSensors(node: HierarchyItemDefinition): number {
    if (node.nodeType === HierarchyNodeType.Sensor) {
        node.measurementsData = {
            numAlarms: 0,
            numHiAlarms: 0,
            numLoAlarms: 0,
            numOffline: 1,
            numSensors: 1,
        };
        return 1;
    } else if (node.children) {
        let sum = 0;
        for (const childNode of node.children) {
            sum += _walkSumSensors(childNode);
        }
        node.measurementsData = {
            numAlarms: 0,
            numHiAlarms: 0,
            numLoAlarms: 0,
            numOffline: sum,
            numSensors: sum,
        };
        return sum;
    }

    return 0;
}

async function loadData(): Promise<RefDictionary> {
    const sensors: SingleSensor[] = await ExactumCache.GetAllEntityRefs(
        'sensor',
    );

    const arrAlarmDefs: SingleAlarmDefinition[] =
        await ExactumCache.GetAllEntityRefs('alarm');
    const alarmDefsBySensorId = Object.fromEntries(
        arrAlarmDefs.map((ad: SingleAlarmDefinition) => [ad.relSensor, ad]),
    );
    // Match alarmDefs with sensors
    sensors.forEach((s: SingleSensor) => {
        if (alarmDefsBySensorId[s.id]) {
            s.alarmDef = alarmDefsBySensorId[s.id];
        }
    });

    return {
        places: await ExactumCache.GetAllEntityRefs('place'),
        customers: await ExactumCache.GetAllEntityRefs('customer'),
        locations: await ExactumCache.GetAllEntityRefs('location'),
        sensorsByID: Object.fromEntries(
            sensors.map((s: SingleSensor) => [s.id, s]),
        ),
        sensors,
    } as RefDictionary;
}

function sortBy(array: any[], selector: (v: any) => string | number) {
    const compare = (a: any, b: any) => {
        const vA = selector(a);
        const vB = selector(b);
        if (vA < vB) {
            return -1;
        } else if (vA > vB) {
            return 1;
        }
        return 0;
    };

    return array.sort(compare);
}

function _fillTopCustomerEntry(
    parent: HierarchyItemDefinition,
    data: RefDictionary,
    customerId: string,
    numDepth: number,
): HierarchyItemDefinition[] {
    const recA = {
        nodeType: HierarchyNodeType.Top,
        data: {
            name: 'locations',
        },
        children: [],
        parent,
    } as HierarchyItemDefinition;
    recA.children = _fillLocations(recA, data, customerId, numDepth + 1);

    const recB = {
        nodeType: HierarchyNodeType.Top,
        data: {
            name: 'unassigned',
        },
        children: [],
        parent,
    } as HierarchyItemDefinition;
    recB.children = _fillUnclassifiedSensors(
        recB,
        data,
        customerId,
        numDepth + 1,
    );

    return [recA, recB];
}

function _fillCustomers(
    data: RefDictionary,
    numDepth: number,
): HierarchyItemDefinition[] {
    return sortBy(
        data.customers.map((customer: any): HierarchyItemDefinition => {
            const rec = {
                parent: null,
                nodeType: HierarchyNodeType.Customer,
                data: {
                    ...customer,
                    counts: {
                        all: 0,
                        online: 0,
                        alarmHigh: 0,
                        alarmLow: 0,
                        alarmAll: 0,
                    },
                },
                children: [] as HierarchyItemDefinition[],
            };

            rec.children = _fillTopCustomerEntry(
                rec,
                data,
                customer.id,
                numDepth + 1,
            );

            return rec;
        }),
        (c: HierarchyItemDefinition) => c.data.title,
    );
}

function _fillLocations(
    parent: HierarchyItemDefinition,
    data: RefDictionary,
    customerId: string,
    numDepth: number,
): HierarchyItemDefinition[] {
    return sortBy(
        data.locations.filter((l: any) => {
            return l.relCustomer === customerId;
        }),
        (v: SingleLocation) => v.title,
    ).map((loc: any): HierarchyItemDefinition => {
        const rec = {
            nodeType: HierarchyNodeType.Location,
            data: {
                parent,
                ...loc,
                counts: {
                    all: 0,
                    online: 0,
                    alarmHigh: 0,
                    alarmLow: 0,
                    alarmAll: 0,
                },
            },
            children: [] as HierarchyItemDefinition[],
        } as HierarchyItemDefinition;

        rec.children = _fillLocationPlaces(rec, data, loc.id, numDepth + 1);

        return rec;
    });
}

function _fillUnclassifiedSensors(
    parent: HierarchyItemDefinition,
    data: RefDictionary,
    customerId: string,
    numDepth: number,
): HierarchyItemDefinition[] {
    return data.sensors
        .filter((s: any) => !s.relPlace && s.relCustomer === customerId)
        .map((sens: any): HierarchyItemDefinition => {
            const rec = {
                parent,
                nodeType: HierarchyNodeType.Sensor,
                data: data.sensorsByID[sens.id],
                children: [],
            } as HierarchyItemDefinition;
            return rec;
        });
}

function _fillLocationPlaces(
    parent: HierarchyItemDefinition,
    data: RefDictionary,
    relLocation: string,
    numDepth: number,
): HierarchyItemDefinition[] {
    const locations = data.places.filter(
        (p: SinglePlace) => p.relLocation === relLocation && !p.parent,
    );
    // await ExactumCache.GetLocationPlaces(relLocation);

    return sortBy(locations, (v: SinglePlace) => v.title).map(
        (place: any): HierarchyItemDefinition => {
            const rec = {
                nodeType: HierarchyNodeType.Place,
                parent: parent,
                data: place,
                children: [] as HierarchyItemDefinition[],
            } as HierarchyItemDefinition;

            rec.children = _fillPlaceSiblings(
                rec,
                data,
                place.id,
                numDepth + 1,
            );

            return rec;
        },
    );
}

function _fillPlaceSiblings(
    parent: HierarchyItemDefinition,
    data: RefDictionary,
    relPlace: string,
    numDepth: number,
): HierarchyItemDefinition[] {
    if (numDepth > depthLimit) {
        return [];
    }

    return ([] as HierarchyItemDefinition[]).concat(
        _fillPlacePlaces(parent, data, relPlace, numDepth + 1),
        _fillPlaceSensors(parent, data, relPlace, numDepth + 1),
    );
}

function _fillPlacePlaces(
    parent: HierarchyItemDefinition,
    data: RefDictionary,
    relPlace: string,
    numDepth: number,
): HierarchyItemDefinition[] {
    // const p = '/places/';
    // if (
    //     relPlace === p + '510991a2-4f8f-11eb-8155-005056a5b9ab' || // Mikrobiološki laboratorij
    //     relPlace === p + '4e8cc9c0-4f8f-11eb-8155-005056a5b9ab' || // LABORATORIJ
    //     relPlace === p + '4dd1af27-4f8f-11eb-b456-005056a5781c'    // 1. Nadstropje
    //     ) {
    //     }

    if (numDepth > depthLimit) {
        return [];
    }

    const places = data.places.filter(
        (p: SinglePlace) => p.parent === relPlace,
    );

    return sortBy(places, (v: SinglePlace) => v.title).map(
        (place: any): HierarchyItemDefinition => {
            const rec = {
                nodeType: HierarchyNodeType.Place,
                data: place,
                parent,
                children: [],
            } as HierarchyItemDefinition;

            rec.children = _fillPlaceSiblings(
                rec,
                data,
                place.id,
                numDepth + 1,
            );

            return rec;
        },
    );
}

function _fillPlaceSensors(
    parent: HierarchyItemDefinition,
    data: RefDictionary,
    relPlace: string,
    numDepth: number,
): HierarchyItemDefinition[] {
    const sensors = data.sensors.filter(
        (s: SingleSensor) => s.relPlace === relPlace,
    );

    return sortBy(sensors, (v: SingleSensor) => v.title).map(
        (sensor: any): HierarchyItemDefinition => ({
            parent,
            nodeType: HierarchyNodeType.Sensor,
            data: data.sensorsByID[sensor.id],
            children: [],
        }),
    );
}
