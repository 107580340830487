import { Module } from 'vuex';
import { MeasurementHistoryState } from '@/types';

import Vue from 'vue';
import { DateTime, Duration } from 'luxon';

const measurementsState: MeasurementHistoryState = {

    chosenSensors: [],
    chosenDateInterval: {
        from: DateTime.now()
                        .minus(Duration.fromObject({days: 3}))
                        .minus(Duration.fromObject({minutes: 2})),
        to: DateTime.now(),
    },

    hierarchySensorSelectorOpened: false,

    lastRefresh: -1,
};


const MeasurementHistoryStore: Module<any, any> = {

    state: measurementsState,

    getters: {

        getMeasurementHistorySelectedSensors(state: MeasurementHistoryState) {
            return state.chosenSensors;
        },

        isMeasurementHistorySensorSelectionEnabled(state: MeasurementHistoryState) {
            return state.hierarchySensorSelectorOpened;
        },

        getMeasurementHistoryRedrawNotification(state: MeasurementHistoryState) {
            return state.lastRefresh;
        },

    },

    mutations: {
        showMeasurementHistorySensorSelection(state: MeasurementHistoryState) {
            state.hierarchySensorSelectorOpened = true;
        },
        hideMeasurementHistorySensorSelection(state: MeasurementHistoryState) {
            state.hierarchySensorSelectorOpened = false;
        },
        toggleMeasurementHistorySensorSelection(state: MeasurementHistoryState) {
            state.hierarchySensorSelectorOpened = !state.hierarchySensorSelectorOpened;
        },

        toggleSensorMeasurementHistorySelection(state: MeasurementHistoryState, sensorData: {id: string}) {
            if (state.chosenSensors.filter((value) => value.id === sensorData.id).length > 0) {
                Vue.set(state, 'chosenSensors', state.chosenSensors.filter((elId) => elId.id !== sensorData.id));
            } else {
                state.chosenSensors.push(sensorData);
            }
        },

        clearMeasurementHistorySelection(state: MeasurementHistoryState) {
            Vue.set(state, 'chosenSensors', []);
        },

        updateMeasurementHistoryGraph(state: MeasurementHistoryState) {
            state.lastRefresh = (new Date()).getTime();
        },

        updateMeasurementHistoryStartDateInterval(state: MeasurementHistoryState, {from, to}) {

            if (from) {
                state.chosenDateInterval.from = from;
            }

            if (to) {
                state.chosenDateInterval.to = to;
            }
        },
    },
};

export default MeasurementHistoryStore;
