import { ContextMenuOption, SecurityRole, SingleLocation } from '@/types';
import { HierarchyMenuOptionsContext } from '../../../typeDefs/HierarchyMenuOptionsContext';
import PrepareAndWaitForReport from '../commands/PrepareAndWaitForReport';
import { CustomerFeature } from '@/typeDefs/features';
import { HierarchyItemAction } from '@/typeDefs/hierarchyItemActions';

export default function LocationHierarchyMenuOptions(
    out: HierarchyMenuOptionsContext,
): ContextMenuOption[] {
    return [
        {
            label: 'Nastavitve lokacije',
            action: () => {
                const path = out.settingsLink(out.nodeData);
                if (path && out.CanUserAccessLink(path)) {
                    out.$router.push({ path });
                }
            },
            minRole: SecurityRole.RoleOwner,
            icon: 'cog',
        },
        {
            label: 'Pripravi enkratni delta izvoz',
            action: () => {
                PrepareAndWaitForReport(
                    'grouped_delta',
                    out.nodeData,
                    out.$router,
                );
            },
            icon: 'file',
            requiredFeature: CustomerFeature.GroupedLocationExport,
        },
        {
            label: 'Pripravi poročilo trenutnih vrednosti',
            action: () => {
                PrepareAndWaitForReport(
                    'grouped_current',
                    out.nodeData,
                    out.$router,
                );
            },
            icon: 'file',
            requiredFeature: CustomerFeature.GroupedLocationExport,
        },
        {
            label: 'Omogoči GMP',
            action: () => {
                out.performItemAction(HierarchyItemAction.OpenQAManageModal);
            },
            icon: 'check',
            requiredFeature: CustomerFeature.GMP,
            showIf(context) {
                const locationData = context.nodeData.data as SingleLocation;
                return !locationData.gmpEnabled;
            },
        },
        {
            label: 'ONEMOGOČI GMP',
            action: () => {
                out.performItemAction(HierarchyItemAction.OpenQAManageModal);
            },
            icon: 'times',
            requiredFeature: CustomerFeature.GMP,
            showIf(context) {
                const locationData = context.nodeData.data as SingleLocation;
                return locationData.gmpEnabled;
            },
        },
    ];
}
