import {
    EntityPluginStep,
    EntityPluginStepMessage,
    EntityPluginStepStage,
} from './types';

export function CorePluginAvailable(
    allPlugins: EntityPluginStep[],
    msg: EntityPluginStepMessage,
): boolean {
    return allPlugins.filter((p) => p.stage === 'Core').length > 0;
}

export default async function RunPlugins(
    allPlugins: EntityPluginStep[],
    msg: EntityPluginStepMessage,
    stage: EntityPluginStepStage,
    params: any,
) {
    const queue = await allPlugins
        .filter((p) => p.message === msg && p.stage === stage)
        .sort((a, b) => a.priority - b.priority);

    let result = params;
    for (const step of queue) {
        result = await step.action(result);
    }

    return result;
}
