
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class InlineResolver extends Vue {
    @Prop() private promise!: Promise<any>;
    private value: any = null;

    private resolved: boolean = false;

    @Watch('promise')
    private promiseChanged() {
        // this.$toasted.show('Resolving...');

        this.resolved = false;

        const out = this;
        this.promise.then((result: any) => {
            // this.$toasted.show('Resolved successfully!');
            out.value = result;
            out.resolved = true;
        });
    }

    private mounted() {
        this.promiseChanged();
    }
}
