import { GenerateProviders } from '@/errorHandler';
import { EntityDefinition, EntityReferenceString } from '@/types';

import { RoleParams } from '@/authorization';

export const Location: EntityDefinition = {
    primaryKey: 'title',
    endpointName: 'locations',
    isPrimaryResolver: true,
    getPrimaryKey: (row: any): string => {
        return row.title;
    },
    name: 'Location',
    pluralName: 'Locations',
    routes: {
        single: 'settingsLocationsAddEdit',
        grid: 'settingsLocations',
    },
    providers: GenerateProviders('locations', {
        entity: 'Location',
        params: {
            attributes: [
                'title',
                'address',
                'latLon',
                'relImage',
                'noAccessUsers',
                'relManager',
                'relCustomer',
            ],
        },
    }),
    lookupFields: {
        title: 'title',
        detailLeft: 'address',
    },
    roleBasedFilters: {
        ROLE_USER: RoleParams.UserCustomer,
        ROLE_OWNER: RoleParams.UserCustomer,
    },
};
