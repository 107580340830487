import {
    DefaultProviders,
    GETWithParams,
    GET,
    emptyMultipleResponse,
    _getEntityRoleBasedParams,
    saveToCache,
} from '@/errorHandler';
import {
    EntityDefinition,
    FetchMultipleQueryParams,
    APIMetadataResponse,
    ProgressTrackerData,
    SingleAlarmDefinition,
} from '@/types';
import { ProgressTracker } from '@/util';

import { ExactumCache } from '@/internal';
import { SingleSensor } from '@/types';
import { RoleParams } from '@/authorization';

interface DelayLevelDefinition {
    level: number;
    delay: number;
}

const entityLogicalName = 'alarms';
const baseURL = '/' + entityLogicalName;
const serializationData = {
    entity: 'Alarm',
    params: {
        attributes: [
            'id',
            'active',
            'alarmLow',
            'alarmHigh',
            'alarmOffline',
            'delayHighValue',
            'delayLowValue',
            'delayOffline',
            'delayBackToNormal',
            'subscribersLevel1',
            'subscribersLevel2',
            'subscribersLevel3',
            'relSensor',
            'relCustomer',
            'deltaInterval',
            'alarmDelta',
            'delaySubscriberLevel',
        ],
    },
};

const roleBasedFilters = {
    ROLE_USER: RoleParams.UserCustomer,
    ROLE_OWNER: RoleParams.UserCustomer,
};

const linkSensorWithDefinition = async (
    q: FetchMultipleQueryParams,
): Promise<APIMetadataResponse<SingleAlarmDefinition>> => {
    const k = await fetchMultiple(q);

    const queue = [];

    for (const alarmDef of k.data) {
        const sensorData: SingleSensor = await ExactumCache.ResolveReference(
            alarmDef.relSensor,
        );
        if (sensorData) {
            alarmDef.sensorName = sensorData.title;

            queue.push({
                ...sensorData,
                alarmDefinition: alarmDef,
            } as SingleSensor);
        }
    }

    saveToCache(k.data, entityLogicalName);
    saveToCache(queue, 'sensors');

    return k;
};

const fetchMultiple = async (
    q: FetchMultipleQueryParams,
): Promise<APIMetadataResponse<SingleAlarmDefinition>> => {
    const roleBased = await _getEntityRoleBasedParams(AlarmDefinition);
    const queryParams = { ...roleBased, ...q };

    try {
        let deserializetionOptions = { deserialize: true };
        if (serializationData) {
            deserializetionOptions = {
                deserialize: true,
                ...serializationData,
            };
        }

        const response: APIMetadataResponse<SingleAlarmDefinition> =
            await GETWithParams(
                baseURL,
                queryParams,
                undefined,
                deserializetionOptions,
                false,
            );

        for (const resp of response.data) {
            if (resp.delaySubscriberLevel) {
                for (const lvl of resp.delaySubscriberLevel as DelayLevelDefinition[]) {
                    (resp as any)['delayLevel' + lvl.level] = lvl.delay;
                }
            }
        }

        return response;
    } catch (e) {
        return emptyMultipleResponse(baseURL);
    }
};

const fetchAll = async (
    q: FetchMultipleQueryParams,
    p?: ProgressTrackerData,
): Promise<APIMetadataResponse<SingleAlarmDefinition>> => {
    let allData: any[] = [];

    const progress = new ProgressTracker(p);

    progress.setBounds(0, 1, 0.01);
    let batch = null;
    do {
        batch = await linkSensorWithDefinition(q);
        if (!batch) {
            throw new Error('Cannot retrieve measurements.');
        }

        progress.setBounds(0, batch.meta.totalItems, allData.length);

        allData = allData.concat(batch.data);
        q.page += 1;
    } while (batch.links.next);

    progress.setBounds(0, 1, 1);

    return {
        data: allData,
        links: { ...batch.links },
        meta: {
            currentPage: batch.meta.currentPage,
            itemsPerPage: batch.meta.currentPage,
            totalItems: allData.length,
        },
    };
};

const dp = DefaultProviders<SingleAlarmDefinition>(baseURL, serializationData);

const fetchSingle = async (id: string): Promise<SingleAlarmDefinition> => {
    const opts = {
        noMeta: true,
        ...serializationData,
    };
    const data = await GET(baseURL + '/' + id, undefined, opts, false);
    if (data.delaySubscriberLevel) {
        for (const lvl of data.delaySubscriberLevel as DelayLevelDefinition[]) {
            data['delayLevel' + lvl.level] = lvl.level;
        }
    }

    const sensorData: SingleSensor = await ExactumCache.ResolveReference(
        data.relSensor,
    );
    if (sensorData) {
        // Update Cache
        data.sensorName = sensorData.title;
        saveToCache(
            [
                {
                    ...sensorData,
                    alarmDefinition: data,
                } as SingleSensor,
            ],
            'sensors',
        );
    }

    saveToCache([data], baseURL.substr(1));

    return data;
};

const update = (id: string, data: any) => {
    data.delaySubscriberLevel = [];
    if (data.delayLevel2) {
        data.delaySubscriberLevel.push({
            level: 2,
            delay: data.delayLevel2,
        } as DelayLevelDefinition);
    }

    if (data.delayLevel3) {
        data.delaySubscriberLevel.push({
            level: 3,
            delay: data.delayLevel3,
        } as DelayLevelDefinition);
    }

    return dp.update(id, data);
};

export const AlarmDefinition: EntityDefinition<SingleAlarmDefinition> = {
    primaryKey: 'id',
    endpointName: 'alarms',
    isPrimaryResolver: true,
    getPrimaryKey(row: any) {
        if (row) {
            return row.id;
        }

        return 'unknown';
    },
    name: 'Alarm',
    pluralName: 'Alarms',
    routes: {
        single: 'settingsAlarmDefinitionsAddEdit',
        grid: 'settingsAlarmDefinitions',
    },
    providers: {
        fetchSingle,
        fetchMultiple: linkSensorWithDefinition,
        fetchAll,
        update,
        delete: dp.delete,
        create: dp.create,
        serializationData: { entity: serializationData.entity },
    },
    lookupFields: {
        title: 'id',
    },
    roleBasedFilters,
};
