
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { SingleFormInputDefinition } from '@/types';
import BaseFormInput from './BaseFormInput';

@Component({})
export default class DurationFormInput extends BaseFormInput {

    private hours: number   = 0;
    private minutes: number = 0;
    private seconds: number = 0;

    @Prop({default: false}) private enableTransparent!: boolean;

    private formInputChanged(currentHours: number, currentMinutes: number, currentSeconds: number) {
        const val = currentHours * 60 * 60 + currentMinutes * 60 + currentSeconds;

        this.$set(this.formData, this.columnDefinition.name, val);
    }

    private hoursChanged(value: InputEvent) {
        let currentHours: number = 0;
        const target = (value.target as HTMLInputElement);
        if (target.value) {
            currentHours = parseInt(target.value, 10);
        }

        this.formInputChanged(currentHours, this.minutes, this.seconds);
    }

    private minutesChanged(value: InputEvent) {
        let currentMinutes: number = 0;
        const target = (value.target as HTMLInputElement);
        if (target.value) {
            currentMinutes = parseInt(target.value, 10);
        }

        this.formInputChanged(this.hours, currentMinutes, this.seconds);
    }

    private secondsChanged(value: InputEvent) {
        let currentSeconds: number = 0;
        const target = (value.target as HTMLInputElement);
        if (target.value) {
            currentSeconds = parseInt(target.value, 10);
        }
        const currentMinutes = this.minutes;

        this.formInputChanged(this.hours, this.minutes, currentSeconds);
    }

    private get formValue() {
        return this.formData[this.columnDefinition.name];
    }

    @Watch('formValue')
    private valueChanged() {

        let val = this.formValue;
        if (!val || typeof val !== 'number') {
            val = 0;
        }

        const sec = val % 60;
        const min = ((val - sec) / 60) % 60;
        const hrs = Math.floor(val / (60 * 60));

        this.hours = hrs; this.minutes = min; this.seconds = sec;
    }

    private mounted() {
        this.valueChanged();
    }
}

