
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { ConstUnitInput, SingleFormInputDefinition, SingleSensor } from '@/types';
import BaseFormInput from './BaseFormInput';
import UnitManager from '@/units';

@Component({})
export default class ConstUnitNumberFormInput extends BaseFormInput {

    private unitColDef = this.columnDefinition as ConstUnitInput;

    private get inputSize() {
        const l = this.vall.toString().length;
        return l < 2 ? 2 : l;
    }

    @Prop({default: false}) private enableTransparent!: boolean;

    private vall: any = 0;

    public mounted() {
        this.loadValue();
    }

    public get sensorMeta(): SingleSensor | null {
        if (this.unitColDef) {
            return this.unitColDef.sensorMeta as SingleSensor;
        }

        return null;
    }

    public userChanged(ev: InputEvent) {
        if (ev && ev.target) {
            const t = ev.target as HTMLInputElement;
            const vaStr = t.value; // .replace(/(\d),(\d)/g, '$1.$2').replace(/[^0-9.,]/g, '');
            this.vall = vaStr;

            if (!this.sensorMeta) {
                Vue.set(this.formData, this.unitColDef.name, parseFloat(this.vall));
                return;
            }

            Vue.set(this.formData, this.unitColDef.name, UnitManager.GetInstance().ConvertValueToBaseUnit(
                this.vall,
                this.sensorMeta.Type,
                this.sensorMeta.presentationUnit,
            ));
        }
    }

    private get origVal() {
        return this.formData[this.unitColDef.name];
    }

    @Watch('origVal')
    private loadValue() {
        const newVal = this.origVal;

        if (!this.sensorMeta) {
            return newVal;
        }

        this.vall = UnitManager.GetInstance().ConvertValueFromBaseUnit(
            newVal,
            this.sensorMeta.Type,
            this.sensorMeta.presentationUnit,
        );
    }

    public get unit() {
        const sensorMeta = this.sensorMeta;
        if (sensorMeta) {
            return UnitManager.GetInstance().GetDisplaySymbol(sensorMeta.Type, sensorMeta.presentationUnit);
        }
        return '??';
    }
}
