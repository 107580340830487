import { ContextMenuOption, SecurityRole, SingleLocation } from '@/types';
import { HierarchyMenuOptionsContext } from '../../../typeDefs/HierarchyMenuOptionsContext';
import PrepareAndWaitForReport from '../commands/PrepareAndWaitForReport';
import { CustomerFeature } from '@/typeDefs/features';
import { HierarchyItemAction } from '@/typeDefs/hierarchyItemActions';
import { ShowAllSensorsHistory } from '../commands/ShowAllSensorsHistory';

export default function PlaceHierarchyMenuItems(
    out: HierarchyMenuOptionsContext,
): ContextMenuOption[] {
    return [
        {
            label: 'Nastavitve prostora',
            action: () => {
                const path = out.settingsLink(out.nodeData);
                if (path && out.CanUserAccessLink(path)) {
                    out.$router.push({ path });
                }
            },
            minRole: SecurityRole.RoleOwner,
            icon: 'cog',
        },
        {
            label: 'Prikaži zgodovino vseh senzorjev',
            action: () => {
                ShowAllSensorsHistory(out.nodeData, out.$router);
            },
            icon: 'history',
        },
        {
            label: 'Omogoči GMP',
            action: () => {
                out.performItemAction(HierarchyItemAction.OpenQAManageModal);
            },
            icon: 'check',
            requiredFeature: CustomerFeature.GMP,
            showIf(context) {
                const locationData = context.nodeData.data as SingleLocation;
                return !locationData.gmpEnabled;
            },
        },
        {
            label: 'ONEMOGOČI GMP',
            action: () => {
                out.performItemAction(HierarchyItemAction.OpenQAManageModal);
            },
            icon: 'times',
            requiredFeature: CustomerFeature.GMP,
            showIf(context) {
                const locationData = context.nodeData.data as SingleLocation;
                return locationData.gmpEnabled;
            },
        },
    ];
}
