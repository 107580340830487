
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import GuidConceal from './GuidConceal.vue';

// @ts-ignore
import JsonViewer from 'vue-json-viewer';

@Component({
    components: {GuidConceal, JsonViewer},
})
export default class RenderValue extends Vue {
    private type: 'plain' | 'guid' | 'complex' | null = null;

    @Prop() private value!: any;

    @Watch('value')
    private valueChanged() {

        const v = this.value;

        if (v === null || v === undefined) {
            this.type = null;
        } else if (typeof v === 'string' && v.split('-').length === 5) {
            this.type = 'guid';
        } else if (typeof v === 'string' || typeof v === 'number' || v === 'boolean' ) {
            this.type = 'plain';
        } else {
            this.type = 'complex';
        }
    }

    private mounted() {
        this.valueChanged();
    }
}
