
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import Component from 'vue-class-component';



import MeasurementHistoryHierarchyMenu from '@/components/hierarchy/menus/MeasurementHistoryHierarchyMenu.vue';

import { Location, MediaObject, MeasurementExport } from '@/internal';

import {
    HierarchyItemDefinition, HierarchyNodeType, RootModules,
    DashboardViewState, HierarchyViewState, IdObject,
    AlarmEventSummary, AlarmEventType, EntityReference,
    SingleMeasurementExport, SingleAlarmEvent, SingleMediaObject, SingleSensor
} from '@/types';

import HierarchyFilterView from './HierarchyFilterView.vue';

import moment from 'moment-timezone';

import { IconForAlarmType, ParseEntityReference } from '@/util';
import UnitManager from '@/units';
import { HierarchyMenuCommand } from './hierarchyTypes';
import HierarchyTreeView from './HierarchyTree.vue';
import HierarchyItemMenuButton from './menus/base/HierarchyItemMenuButton.vue';



@Component({
    components: {

        HierarchyFilterView,
        MeasurementHistoryHierarchyMenu,
        HierarchyTreeView,
        HierarchyItemMenuButton
    },
    computed: {
        ...mapState({
            treeData: (state: RootModules) => state.HierarchyStore.tree,
            measurementHistorySensorSelectionEnabled: (state: RootModules) => {
                return state.MeasurementHistoryStore.hierarchySensorSelectorOpened;
            },
            hierarchyChosenDateInterval: (state: RootModules) => state.MeasurementHistoryStore.chosenDateInterval,
        } as any),
        ...mapGetters([

            'getHierarchyStateRestoredNotification',
            'getMeasurementHistorySelectedSensors', 'hierarchyContextMenu',
            'isRecentAlarmsViewEnabled', 'recentAlarms', 'recentAlarmsSummary',
            'alarmEventOccuredNotification', 'isHierarchySensorSelectionEnabled',
            'IsViewerUser',
        ]),
    },
})
export default class HierarchyView extends Vue {


    private IsViewerUser!: boolean;

    private treeData!: HierarchyItemDefinition[];



    private sensorSelectionCallback: ((data: any) => void) | null = null;

    private isRecentAlarmsViewEnabled!: boolean;

    private alarmPollInProgress: boolean = true;
    private recentAlarms!: SingleAlarmEvent[];
    private recentAlarmsSummary!: AlarmEventSummary;

    private recentExportsList: SingleMeasurementExport[] = [];
    private recentExportsShown: boolean = false;
    private measurementHistoryMenuShown: boolean = false;

    private loadingRecentExports: boolean = true;

    private moment = require('moment');

    private filterBoxValue: string = '';

    private alarmPollInterval: number = -1;

    public get iconForAlarmTypeName() {
        return IconForAlarmType;
    }

    private get tz() {
        return this.$store.getters.getAdditionalUserAttributes.timezone;
    }

    public executeCommand(command: HierarchyMenuCommand, payload: any) {
        switch (command) {
            case 'disableSensorSelection':
                this.sensorSelectionCallback = null;
                break;

            case 'enableSensorSelection':
                this.sensorSelectionCallback = payload;
                break;

            case 'setFocusedElement':
                this.setFocusedElement(payload);
                break;

            case 'hierarchyItemClicked':
                const nodeData = payload as HierarchyItemDefinition;

                // If the hierarchy selection callback is set, override other actions.
                if (nodeData && this.$store.getters.getHierarchySensorSelectionListener) {

                    if (nodeData.nodeType === HierarchyNodeType.Sensor) {
                        this.$store.getters.getHierarchySensorSelectionListener(payload);
                    }

                    return true;
                }

                if (nodeData && nodeData.nodeType === HierarchyNodeType.Sensor && this.sensorSelectionCallback) {

                    this.sensorSelectionCallback(nodeData);
                    return true;
                }

                if (nodeData && nodeData.nodeType === HierarchyNodeType.Sensor) {
                    this.openLiveChart(nodeData);
                }

                break;

            case 'closeAllMenus':
                this.closeAllMenus();
                break;

            case 'openMenu':
                if (payload === 'measurementHistory') {
                    this.measurementHistoryMenuShown = true;
                }
                break;

            case 'getTreeObjectById':
                return this.$store.getters.getTreeObjectById(payload);

            default:
                alert('Command ' + command + ' is not implemented.   Payload: ' + JSON.stringify(payload));
                break;
        }
    }

    public openLiveChart(nodeData: HierarchyItemDefinition) {
        const ref = ParseEntityReference(nodeData.data.id);
        this.$router.push({ name: 'liveChart', params: { sensorId: ref!.id } });
    }

    public hierarchyScrolled() {
        const view = this.$refs.mainHierarchyView as {
            scrollLeft: number,
            scrollTop: number,
            clientHeight: number,
        };

        const y = view.scrollTop;
        const x = view.scrollLeft;
        const height = view.clientHeight;

        const scroll = `(${x},${y},${height})`;
        this.$store.commit('setHierarchyScrollView', scroll);
    }

    public closeAllMenus() {
        this.$store.commit('hideRecentAlarmsView');
        this.recentExportsShown = false;
        this.measurementHistoryMenuShown = false;
    }


    public toggleRecentAlarmsView() {
        this.$router.push({
            name: 'alarmEventsGrid',
        });
        this.$store.commit('hideAppMenu');
    }

    public toggleRecentExportsView() {
        this.recentExportsShown = !this.recentExportsShown;
        this.measurementHistoryMenuShown = false;
        this.$store.commit('hideRecentAlarmsView');
    }

    public openMeasurementHistory() {
        if (this.$route.name === 'measurementHistory') {
            this.$store.commit('hideAppMenu');
            this.$store.commit('updateMeasurementHistoryGraph');
        } else {
            this.$router.push({ name: 'measurementHistory' });
        }
    }

    public openExportsList() {
        this.$router.push({ name: 'settingsMeasurementExports' });
    }

    public openSpecificExport(id: string) {
        this.$router.push({
            name: 'settingsMeasurementExportsAddEdit', params: {
                uid: id,
            }
        });
    }

    public async mounted() {

        this.$store.commit('setIsAppLoading', +1);

        const out = this;

        (this.$refs.mainHierarchyView as any)
            .addEventListener('scroll', () => { out.hierarchyScrolled.apply(out); }, false);

        this.loadRecentExports();

        await out.$store.dispatch('restoreHierarchyState');

        this.$store.commit('setIsAppLoading', -1);

        this.startAlarmPolling();

    }

    public beforeDestroy() {
        clearTimeout(this.alarmPollInterval);
        this.$store.dispatch('saveHierarchyState');
    }

    public setFocusedElement(path: string) {
        this.$store.commit('setFocusedElement', path);
    }

    private async startAlarmPolling() {
        this.alarmPollInProgress = true;
        await this.$store.dispatch('doInitialAlarmPoll');
        await this.doAlarmPolling();

        this.alarmPollInProgress = false;
    }

    private async doAlarmPolling() {

        // const al = this.$toasted.info('Checking for new alarms...');

        this.alarmPollInProgress = true;

        await this.$store.dispatch('doSingleAlarmPoll');

        let pollInterval = 120; // seconds
        if (this.IsViewerUser) {
            pollInterval = 10;
        }

        clearTimeout(this.alarmPollInterval);
        this.alarmPollInterval = setTimeout((() => {
            this.doAlarmPolling();
        }).bind(this), pollInterval * 1000) as unknown as number;

        // al.goAway(0);
        // this.$toasted.success('Check successful');

        this.alarmPollInProgress = false;
    }

    private removeSensorFromSelection(sensor: IdObject) {
        this.$store.commit('toggleSensorMeasurementHistorySelection', {
            id: sensor.id,
        });
    }

    private async loadRecentExports() {
        this.loadingRecentExports = true;
        const k = await MeasurementExport.providers.fetchMultiple({
            'page': 1, 'itemsPerPage': 30, 'order[updatedTimestamp]': 'desc',
        });

        this.recentExportsList = k.data;

        const out = this;
        setTimeout(() => {
            out.loadingRecentExports = false;
        }, 500);
    }

    private async downloadMedia(ref: string) {

        const er = ParseEntityReference(ref) as EntityReference;
        const d = await MediaObject.providers.fetchSingle(er.id);

        if (d) {
            const k = d as SingleMediaObject;
            const URL = process.env.VUE_APP_API_BASE_URL + k.contentUrl;
            window.open(URL, '_blank');
        } else {
            alert(this.$t('Ni mogoče prenesti datoteke').toString());
        }

    }
}


