
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import {GridColumnDefinition, MappedGridColumnDefinition} from '@/types';

@Component({})
export default class MappedValueColumn extends Vue {

    @Prop() public columnDefinition!: MappedGridColumnDefinition;
    @Prop() public columnData!: any;

}
