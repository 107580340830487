
import Component from 'vue-class-component';

import RenderValue from '../RenderValue.vue';
import { Prop, Vue } from 'vue-property-decorator';
import { SingleAuditLogEntry, SingleCustomer } from '@/types';
import BlockResolver from '@/components/util/BlockResolver.vue';
import InlineResolver from '@/components/util/InlineResolver.vue';
import { ExactumCache, User } from '@/internal';
import GuidConceal from '../GuidConceal.vue';
import { DateTime } from 'luxon';


@Component({
    components: {
        GuidConceal
    }
})
export default class AlarmEventNotificationsSubExplainer extends Vue {

    @Prop() public changes!: any;

    public formatDateTime(dtStr: string) {
        return DateTime.fromISO(dtStr).toLocaleString(DateTime.DATETIME_SHORT);
    }

    public userPromise(uglyGuid: string) {
        const userId = this.addDashesToGuid(uglyGuid);
        return ExactumCache.ResolveReference("/users/" + userId).then((user) => {
            if (user && User.getPrimaryKey) {
                return User.getPrimaryKey(user);
            } else {
                return userId + " (ni mogoče pridobiti imena prejemnika)"
            }
        });
    }

    private addDashesToGuid(guid: string) {
        if (typeof guid !== 'string') {
            throw new Error('Input must be a string');
        }

        // Add dashes at appropriate positions
        return (guid.slice(0, 8) + '-' +
            guid.slice(8, 12) + '-' +
            guid.slice(12, 16) + '-' +
            guid.slice(16, 20) + '-' +
            guid.slice(20)).toLowerCase();
    }

}
