
import Component from 'vue-class-component';

import TopHierarchyItem from './TopHierarchyItem.vue';
import LocationHierarchyItem from './LocationHierarchyItem.vue';
import PlaceHierarchyItem from './PlaceHierarchyItem.vue';
import SensorHierarchyItem from './SensorHierarchyItem.vue';
import CustomerHierarchyItem from './CustomerHierarchyItem.vue';

import { mapGetters } from 'vuex';

import HierarchyItemBase from './HierarchyItemBase';

import HierarchyItemMenuButton from './menus/base/HierarchyItemMenuButton.vue';

@Component({
    name: 'HierarchyItem',
    components: {
        TopHierarchyItem,
        LocationHierarchyItem,
        PlaceHierarchyItem,
        SensorHierarchyItem,
        CustomerHierarchyItem,
        HierarchyItemMenuButton,
    },
    computed: {
        ...mapGetters([
            'getFocusedElement',
            'IsUserOwnerOrBetter',
            'CanUserAccessLink',
            'IsUserAtLeast',
            'isHierarchySensorSelectionEnabled',
            'getHierarchySensorSelectionListener',
        ]),
    },
})
export default class HierarchyItem extends HierarchyItemBase { }
