
import LookupFormInputBase from './LookupFormInputBase';
import Component from 'vue-class-component';
import { PlaceFilterOperator} from '@/types';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class CompoundLookupFormInput extends LookupFormInputBase {


    private operatorValue: {code: string, label: string} | null = null;
    private numberValue: string | null = null;

    private allOperators = PlaceFilterOperator;

    private get operators() {
        return Object.values(this.allOperators);
    }

    private get thisValue() {
        return {
            operator: this.operatorValue ? this.operatorValue.code : null,
            value: this.numberValue,
        };
    }

    @Watch('thisValue')
    private updateValue() {

        // Delete all keys which start with columnDefinition.name
        for (const key in this.formData) {
            if (this.formData.hasOwnProperty(key) && key.startsWith(this.columnDefinition.name)) {
                this.$delete(this.formData, key);
            }
        }

        if (this.operatorValue && this.numberValue) {
            this.$set(this.formData,
                this.columnDefinition.name + `[${this.operatorValue.code}]`,
                this.numberValue,
            );
        }
    }

    private get translatedOptions() {
        return this.operators.map((col: string) => {
            return {
                code: col,
                label: this.$t('operator.' + col),
            };
        });
    }

}
