import { EntityReference, EntityDefinition, EntityNames, ProgressTrackerData } from './types';
import { Entities } from '@/internal';
import { DateTime } from 'luxon';

export function ParseEntityReference(s?: string | null): EntityReference | null {
    if (!s) {
        return null;
    }

    const parts = s.split('/');
    if (parts.length < 3) {
        return null;
    }

    const id = parts[2];
    // const GuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    //
    // if (!GuidRegex.test(id)) {
    //     return null;
    // }


    const singular = parts[1].substr(0, parts[1].length - 1);

    return {
        type: parts[1],
        id: parts[2],
        singular,
    };
}

export function SnakeToCamel(snake: string) {
    if (!snake) {
        return null;
    }

    return snake.split('_').map((s) => Capitalize(s)).join('');
}

export function GetEntityNames(e: EntityDefinition): EntityNames {

    const pluralUppercase =  e.pluralName;
    const pluralLowercase =  Uncapitalize(e.pluralName);

    const singularUppercase = e.name;
    const singularLowercase = Uncapitalize(e.name);

    let routeBase = pluralLowercase;
    if (e.routeBase) {
        routeBase = e.routeBase;
    }

    return {
        pluralLowercase,
        pluralUppercase,
        routeBase,
        singularLowercase,
        singularUppercase,
    };
}

export class ProgressTracker {

    private p: ProgressTrackerData | null = null;

    constructor(pr?: ProgressTrackerData) {
        if (pr) {
            this.p = pr;
        }
    }

    public set(progress: number): void {

        if (this.p) {
            this.p.currentVal = progress;
        }

        this.updateValue();
    }

    public setBounds(min: number, max: number, current?: number): void {

        if (this.p) {
            this.p.maxVal = max;
            this.p.minVal = min;

            if (current !== null && current !== undefined) {
                this.p.currentVal = current;
            }

            this.updateValue();
        }
    }

    private updateValue = () => {

        if (this.p
            && this.p.maxVal !== undefined && this.p.maxVal !== null
            && this.p.minVal !== undefined && this.p.minVal !== null
            && this.p.currentVal !== undefined && this.p.currentVal !== null) {

            this.p.object.$set(this.p.object, this.p.key,
                parseInt('' + (100 - this.p.maxVal / this.p.currentVal), 10));
        }
    }
}

export const toNiceTimeFormat = (dt: number) => {
    return DateTime.fromMillis(dt).setLocale('sl').toLocaleString(DateTime.DATETIME_SHORT);
};

export function RoundNumber(num: number, dec: 1 | 2 | 3 | 4) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
}

export function GetEntityForReference(er: EntityReference): EntityDefinition | null {

    if (er.singular) {
        return (Entities as any)[Capitalize(er.singular)];
    } else {
        return null;
    }

}

export function Capitalize(value: any): string {
    if (!value) {
        return '';
    }

    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function Uppercase(value: any): string {
    if (!value) {
        return '';
    }

    value = value.toString();
    return value.toUpperCase();
}

export function Uncapitalize(value: any): string {
    if (!value) {
        return '';
    }

    value = value.toString();
    return value.charAt(0).toLowerCase() + value.slice(1);
}


export function IconForAlarmType(name: string) {

    const names = {
        lower:   'app/alarm_down',
        low_value:   'app/alarm_down',
        upper:   'app/alarm_up',
        high_value:   'app/alarm_up',
        offline: 'app/offline',
        normal:  'app/normal',
    } as any;

    if (name in names) {
        return names[name];
    }

    return 'app/normal';
}

export const ISODateFormat = 'YYYY-MM-DDTHH:mm:ss';
export const DatetimeFormat = 'DD. MM. YYYY - HH:mm';
export const DateFormat = 'DD. MM. YYYY';
export const TimeFormat = 'HH:mm';
