
import Vue from 'vue';
import Component from 'vue-class-component';

// @ts-expect-error
import Teleport from 'vue2-teleport';

@Component({
    components: { Teleport },
})
export default class Modal extends Vue {}
