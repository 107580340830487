import { GenerateProviders } from '@/errorHandler';
import { EntityDefinition, EntityReferenceString } from '@/types';

import { RoleParams } from '@/authorization';

export const MeasurementNote: EntityDefinition = {
    primaryKey: 'note',
    endpointName: 'measurement_notes',
    isPrimaryResolver: true,
    routeBase: 'measurement_notes',
    getPrimaryKey: (row: any): string => {
        return row.title;
    },
    name: 'MeasurementNote',
    pluralName: 'MeasurementNotes',
    routes: {
        single: 'settingsMeasurementNotes',
        grid: 'settingsMeasurementNotes',
    },
    providers: GenerateProviders('measurement_notes', {
        entity: 'MeasurementNote',
        params: {
            attributes: [
                'note',
                'createdTimestamp',
                'startTimestamp',
                'endTimestamp',
                'relSensor',
                'relCustomer',
                'relUser',
            ],
        },
    }),
    lookupFields: {
        title: ['startTimestamp', 'endTimestamp', 'note'],
        detailLeft: 'note',
    },
};
