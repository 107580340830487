
import Component from 'vue-class-component';

import RenderValue from '../RenderValue.vue';
import { Prop, Vue } from 'vue-property-decorator';
import { SingleAuditLogEntry, SingleCustomer } from '@/types';
import BlockResolver from '@/components/util/BlockResolver.vue';
import InlineResolver from '@/components/util/InlineResolver.vue';
import { ExactumCache } from '@/internal';


@Component({
    components: {

    }
})
export default class AdminCustomerIdsExplainer extends Vue {

    @Prop() public selectedRow!: SingleAuditLogEntry;

    public get changes() {
        return (this.selectedRow as any).changes.adminCustomerIds;
    }

    public customerPromise(adminCustomerId: string) {
        return ExactumCache.ResolveReference("/customers/" + adminCustomerId).then((customer) => {
            if (customer) {
                return customer.title;
            } else {
                return adminCustomerId + " (ni mogoče pridobiti imena stranke)"
            }
        });
    }

}
