
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CubeSpin from "@/components/util/loading/Diamond.vue";

@Component({
  components: {
    CubeSpin,
  },
})
export default class Loading extends Vue {
  @Prop({ default: "Nalaganje" }) private text!: string;
  @Prop({ default: false }) private failed!: boolean;

  @Prop({ default: "Zahteva je spodletela" }) private failureText!: string;

  @Prop() private disableFullscreen?: boolean;

  @Prop() private transparent?: boolean;

  @Prop() private percents?: number;

  private get positionStyle(): string {
    if (this.disableFullscreen) {
      return "static";
    }

    return "absolute";
  }

  private back(): void {
    this.$router.go(-1);
  }

  private reload(): void {
    window.location.reload();
  }
}
