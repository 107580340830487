
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { SingleFormInputDefinition, CompoundNumberFilterFormInputDefinition } from '@/types';
import BaseFormInput from './BaseFormInput';

@Component({})
export default class CompoundNumberFilterFormInput extends BaseFormInput {

    private operatorValue: {code: string, label: string} | null = null;
    private numberValue: number | null = null;

    private get operators() {
        const colDef = this.columnDefinition as CompoundNumberFilterFormInputDefinition;
        return Object.values(colDef.operators);
    }

    private get thisValue() {
        return {
            operator: this.operatorValue ? this.operatorValue.code : null,
            value: this.numberValue,
        };
    }

    @Watch('thisValue')
    private updateValue() {
        // Delete all keys which start with columnDefinition.name
        for (const key in this.formData) {
            if (this.formData.hasOwnProperty(key) && key.startsWith(this.columnDefinition.name)) {
                this.$delete(this.formData, key);
            }
        }

        if (this.operatorValue && this.numberValue) {
            this.$set(this.formData,
                this.columnDefinition.name + `[${this.operatorValue.code}]`,
                parseInt(this.numberValue + '', 10),
            );
        }
    }

    private get translatedOptions() {
        return this.operators.map((col: string) => {
            return {
                code: col,
                label: this.$t('operator.' + col),
            };
        });
    }

}
