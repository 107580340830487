
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseForm from '@/components/input/forms/BaseForm';

import GridForm from './forms/GridForm.vue';
import SingleForm from './forms/SingleForm.vue';
import MasterDetailForm from './forms/MasterDetailForm.vue';

import AlarmingSettingsForm from './forms/AlarmingSettingsForm.vue';

import { EntityDefinition, FormDefinition, LoadingInfo, RootModules } from '@/types';

import { mapState } from 'vuex';

@Component({
    components: {
        GridForm, SingleForm, AlarmingSettingsForm, MasterDetailForm,
    },
    computed: {
        ...mapState({
            loadingInfo: (state: RootModules) => state.LoadingStore.form,
        } as any),
    },
})
export default class IForm extends Vue {

    @Prop() public formDefinition!: FormDefinition;
    @Prop() public entityDefinition!: EntityDefinition;

    @Prop({ default: false }) public readOnly!: boolean;

    private emitDataLoaded(args: any) {
        this.$emit('dataLoaded', args);
    }

}
