import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import {
    LookupFormInputDefinition,
    FetchMultipleQueryParams,
    APIMetadataResponse,
    LookupFilterContext,
    LoginInfo,
} from '@/types';
import BaseFormInput from './BaseFormInput';

import { ExactumCache } from '@/internal';

import isEqual from 'lodash/isEqual';
import { GetEntityNames } from '@/util';

@Component({})
export default class LookupFormInputBase extends BaseFormInput {
    public lookupOptions: any[] = [];
    public allOptions: any[] = [];

    public isLoaded: boolean = false;

    public currentRelatedFieldValues: any = null;

    private selectedOption: any = [];

    private filteringInProgress: boolean = false;

    private queryParams: FetchMultipleQueryParams = {
        page: 1,
        itemsPerPage: 500,
    };

    private get lookupDefinition(): LookupFormInputDefinition<any> {
        return this.columnDefinition as LookupFormInputDefinition<any>;
    }

    private constructField(colDef: string | string[], row: any): string {
        if (Array.isArray(colDef)) {
            return colDef
                .map((colName) => {
                    return row[colName];
                })
                .join(' ');
        } else {
            return row[colDef];
        }
    }

    private get entityValueField(): string {
        let valueField = 'id';
        if (this.lookupDefinition.entity.lookupFields.value) {
            valueField = this.lookupDefinition.entity.lookupFields.value;
        }

        return valueField;
    }

    private userDeselectedOption() {
        this.selectedOption = null;

        this.$delete(this.formData, this.lookupDefinition.name);
    }

    private async loadOptions() {
        // const options = await this.lookupDefinition.entity.providers.fetchAll(this.queryParams);
        // const options = await this.lookupDefinition.entity.providers;
        const nams = GetEntityNames(this.lookupDefinition.entity);
        const options = await ExactumCache.GetAllEntityRefs(
            nams.singularLowercase,
        );
        if (options) {
            // Transform options into simple objects
            const opts = this.transformOptions(options);

            // Add unresolved options which users cannot see
            const unknown = this.mergeUnknownOptions(
                opts,
                'errors.data_unauthorized',
            );

            this.allOptions = [...opts, ...unknown];

            await this.filterOptions();
        }

        return [];
    }

    private mergeUnknownOptions(
        opts: Array<{ code: string; label: string }>,
        reason: string,
    ): Array<{ code: string; label: string }> {
        const selectedValues = this.formData[this.lookupDefinition.name];
        const out = this;

        if (selectedValues) {
            const presentData = Array.isArray(selectedValues)
                ? [...selectedValues]
                : [selectedValues];

            const allIds = opts.map((a) => a.code);

            // Filter absent ids
            return presentData
                .filter((id: string) => {
                    return allIds.indexOf(id) < 0;
                })
                .map((notPresentId: string) => {
                    // Create dummy entries
                    return {
                        code: notPresentId,
                        label: out.$t(reason).toString(),
                    };
                });
        }

        return [];
    }

    private transformOptions(options: any[]): any[] {
        if (this.lookupDefinition.mapOptions) {
            return options
                .map(this.lookupDefinition.mapOptions)
                .filter((opt) => !!opt);
        }

        const opts = options.map((row) => {
            return {
                ...row,
                label: this.constructField(
                    this.lookupDefinition.entity.lookupFields.title,
                    row,
                ),
                code: row[this.entityValueField],
            };
        });

        return opts;
    }

    private get relatedFieldValues() {
        const out = this;

        if (this.lookupDefinition.filter) {
            const filter = this.lookupDefinition.filter;
            if (filter.track) {
                const related = filter.track;
                const result = {} as any;
                related.forEach((relatedField) => {
                    result[relatedField] = this.formData[relatedField];
                });

                return result;
            }
        }

        return {};
    }

    @Watch('relatedFieldValues')
    private relatedFieldsChanged() {
        this.filteringInProgress = true;
        const newRelatedValues = this.relatedFieldValues;
        if (
            this.currentRelatedFieldValues === null ||
            !isEqual(newRelatedValues, this.currentRelatedFieldValues)
        ) {
            this.currentRelatedFieldValues = newRelatedValues;

            this.filterOptions();
        }
        this.filteringInProgress = false;
    }

    private async resolveRelatedFields(): Promise<{ [key: string]: any }> {
        const out = this;
        if (
            !this.lookupDefinition.filter ||
            !this.lookupDefinition.filter.resolve ||
            this.lookupDefinition.filter.resolve.length === 0
        ) {
            return {};
        }

        const v = await Promise.all(
            this.lookupDefinition.filter.resolve.map(
                async (relatedFieldName: string) => {
                    const fieldId = out.formData[relatedFieldName];
                    if (!fieldId) {
                        return await Promise.resolve([relatedFieldName, null]);
                    } else {
                        const ent = await ExactumCache.ResolveReference(
                            fieldId,
                        );
                        return [relatedFieldName, ent];
                    }
                },
            ),
        );

        return v.reduce((obj: any, [relatedFieldName, value]: any) => {
            obj[relatedFieldName] = value;
            return obj;
        }, {});
    }

    private async filterOptions() {
        // Get related fields values
        const relatedFields = await this.resolveRelatedFields();
        const formData = this.formData;
        const loginInfo = this.$store.getters.getLoginInfo as LoginInfo;

        let result = this.allOptions;
        const out = this;

        if (this.lookupDefinition.filter) {
            result = this.allOptions.filter(
                (currentOption: { label: string; code: string }) => {
                    if (this.lookupDefinition.filter) {
                        const conditions =
                            this.lookupDefinition.filter.conditions;

                        for (const c of conditions) {
                            if (
                                !c({
                                    relatedFields,
                                    formData,
                                    thisLookupOption: currentOption,
                                    thisRecord: currentOption as any,
                                    form: out,
                                    loginInfo,
                                })
                            ) {
                                return false;
                            }
                        }

                        return true;
                    } else {
                        return true;
                    }
                },
            );
        }

        this.lookupOptions = [
            ...result,
            ...this.mergeUnknownOptions(result, 'errors.data_invalid'),
        ];
    }

    private async mounted() {
        await this.loadOptions();
        this.isLoaded = true;
    }
}
