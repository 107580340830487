
import Vue from "vue";
import Component from "vue-class-component";
import CubeSpin from "@/components/util/loading/Diamond.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: { CubeSpin },
})
export default class InlineLoading extends Vue {
  @Prop({ required: false, default: "60px" }) private heightOverride!: string;
}
