
import Component from 'vue-class-component';

import RenderValue from '../RenderValue.vue';
import { Prop, Vue } from 'vue-property-decorator';
import { SingleAuditLogEntry, SingleCustomer } from '@/types';
import BlockResolver from '@/components/util/BlockResolver.vue';
import InlineResolver from '@/components/util/InlineResolver.vue';
import { ExactumCache, User } from '@/internal';
import GuidConceal from '../GuidConceal.vue';
import AlarmEventNotificationsSubExplainer from './AlarmEventNotificationsSubExplainer.vue';


@Component({
    components: {
        GuidConceal,
        AlarmEventNotificationsSubExplainer
    }
})
export default class AlarmEventNotificationsExplainer extends Vue {

    @Prop() public selectedRow!: SingleAuditLogEntry;

    public get changes() {
        return (this.selectedRow as any).changes.notifications;
    }

    public get oldChanges() {
        return Object.entries((this.selectedRow as any).changes.notifications.old);
    }

    public get newChanges() {
        return Object.entries((this.selectedRow as any).changes.notifications.new);
    }

    public userPromise(userId: string) {
        return ExactumCache.ResolveReference("/users/" + userId).then((user) => {
            if (user && User.getPrimaryKey) {
                return User.getPrimaryKey(user);
            } else {
                return userId + " (ni mogoče pridobiti imena prejemnika)"
            }
        });
    }

}
