import { Module } from 'vuex';
import { ParseEntityReference } from '@/util';

import { RecentAlarmsState, EntityReferenceString, AlarmEventType,
    AlarmEventSummary, SingleAlarmEvent, SingleAlarmDefinition, LoginInfo } from '@/types';

import moment, {Moment} from 'moment-timezone';
import Vue from 'vue';

import { ExactumCache, AlarmEvent, AlarmDefinition } from '@/internal';


const recentAlarmsState: RecentAlarmsState = {

    recentAlarmsViewEnabled: false,

    recentAlarmsSummary: {
        lower: 0,
        upper: 0,
        offline: 0,
    },
    recentAlarms: [],

    lastAlarmEventOccuredAt: -1,

    lastAlarmPollAt: (new Date()).getTime(),
    alarmPollingEnabled: false,

    alarmsAlreadyShownCache: [],
};


const RecentAlarmsStore: Module<any, any> = {

    state: recentAlarmsState,

    getters: {

        alarmsAlreadyShown(state: RecentAlarmsState) {
            return state.alarmsAlreadyShownCache;
        },

        isRecentAlarmsViewEnabled(state: RecentAlarmsState) {
            return state.recentAlarmsViewEnabled;
        },

        recentAlarms(state: RecentAlarmsState) {
            return state.recentAlarms;
        },

        recentAlarmsSummary(state: RecentAlarmsState) {
            return state.recentAlarmsSummary;
        },

        alarmEventOccuredNotification(state: RecentAlarmsState) {
            return state.lastAlarmEventOccuredAt;
        },

        latestAlarmEvent(state: RecentAlarmsState) {
            return state.recentAlarms[0];
        },

    },

    mutations: {
        markAlarmAsAlreadyShown(state: RecentAlarmsState, id: string) {
            state.alarmsAlreadyShownCache.push(id);
        },
        toggleRecentAlarmsView(state: RecentAlarmsState) {
            state.recentAlarmsViewEnabled = !state.recentAlarmsViewEnabled;
        },
        hideRecentAlarmsView(state: RecentAlarmsState) {
            state.recentAlarmsViewEnabled = false;
        },
        showRecentAlarmsView(state: RecentAlarmsState) {
            state.recentAlarmsViewEnabled = true;
        },
        mutation__setRecentAlarms(state: RecentAlarmsState, newAlarms: SingleAlarmEvent[]) {
            Vue.set(state, 'recentAlarms', newAlarms);
        },

        mutation__addRecentAlarm(state: RecentAlarmsState, event: SingleAlarmEvent) {
            // state.recentAlarms.unshift(event);

            const newAlarms = state.recentAlarms.slice(0, 9);
            newAlarms.unshift(event);

            Vue.set(state, 'recentAlarms', newAlarms);

            state.lastAlarmEventOccuredAt = (new Date()).getTime();
        },
        mutation__recentAlarms_updateSummary(state: RecentAlarmsState, newAlarms: SingleAlarmEvent[] | boolean) {

            let source: SingleAlarmEvent[] = state.recentAlarms.filter((r) => r.sensorMeta);
            if (newAlarms !== false) {
                source = newAlarms as SingleAlarmEvent[];
            }

            const a: AlarmEventSummary = {
                lower: 0,
                upper: 0,
                offline: 0,
            };

            source.forEach((value: SingleAlarmEvent) => {
                if (value._type === AlarmEventType.LOWER) {
                    a.lower++;
                } else if (value._type === AlarmEventType.UPPER) {
                    a.upper++;
                } else if (value._type === AlarmEventType.OFFLINE) {
                    a.offline++;
                }
            });

            Vue.set(state, 'recentAlarmsSummary', a);
        },
        mutation__setAlarmPollingStatus(state: RecentAlarmsState, payload: boolean) {
            Vue.set(state, 'alarmPollingEnabled', payload);
        },
        mutation_setLastPollingTime(state: RecentAlarmsState) {
            state.lastAlarmPollAt = (new Date()).getTime();
        },
        mutation__setLastAlarmPollingXHoursAgo(state: RecentAlarmsState) {
            state.lastAlarmPollAt = moment().add(-10, 'hours').unix();
        },
    },

    actions: {

        async setRecentAlarms({dispatch, commit}, newAlarms: SingleAlarmEvent[]) {

            const v = await Promise.all(newAlarms.map(async (ev: SingleAlarmEvent) => {

                const alarmMeta = await ExactumCache.ResolveReference(ev.relAlarm);

                const k = await ExactumCache.ResolveReference(alarmMeta.relSensor);
                ev.sensorMeta = k;

                return ev;
            }));

            const final = v.filter((t) => t.sensorMeta);
            commit('mutation__setRecentAlarms', final);
            commit('mutation__recentAlarms_updateSummary', final);

        },

        async alarmEventOccured({dispatch, commit}, event: SingleAlarmEvent) {

            const alarmMeta = await ExactumCache.ResolveReference(event.relAlarm);

            const meta = await ExactumCache.ResolveReference(alarmMeta.relSensor);
            if (meta) {
                event.sensorMeta = meta;

                commit('mutation__addRecentAlarm', event);
                commit('mutation__recentAlarms_updateSummary', false);
            }
        },

        async doInitialAlarmPoll({dispatch, commit, state}) {

            // Do single alarm polling
            const newEventsResponse = await AlarmEvent.providers.fetchMultiple({
                'page': 1,
                'itemsPerPage': 100,
                'updatedTimestamp[after]': moment().add(-3, 'hours').utc().format(),
            });

            let newEvents = newEventsResponse.data;
            newEvents = newEvents.filter((d) => d.notifications && Object.keys(d.notifications).length > 0);

            // Sort by updated date
            newEvents.sort(
                (a: SingleAlarmEvent, b: SingleAlarmEvent) => b.createdTimestamp.localeCompare(a.createdTimestamp),
            );

            newEvents.map((k: SingleAlarmEvent) => commit('markAlarmAsAlreadyShown', k.id));

            await dispatch('setRecentAlarms', newEventsResponse.data);
            commit('mutation_setLastPollingTime');
        },

        async doSingleAlarmPoll({dispatch, commit, rootGetters, state}) {

            const alarmNumLimit = 60;

            // Do single alarm polling
            const newEventsResponse = await AlarmEvent.providers.fetchMultiple({
                'relCustomer': (rootGetters.getLoginInfo as LoginInfo).user.relCustomer as string,
                'page': 1,
                'itemsPerPage': alarmNumLimit,
                'updatedTimestamp[after]': moment(state.lastAlarmPollAt).utc().format(),
            });

            //
            // WATCH OUT!!!
            // If an alarm produced no notifications, it should not appear as an alarm at all.
            //
            let newEvents: SingleAlarmEvent[] = newEventsResponse.data;
            newEvents = newEvents.filter((d) => d.notifications && Object.keys(d.notifications).length > 0);


            for (const e of newEvents) {
                if (state.alarmsAlreadyShownCache.filter((v: string) => v === e.id).length === 0) {
                    commit('markAlarmAsAlreadyShown', e.id);
                    dispatch('alarmEventOccured', e);
                }
            }

            commit('mutation_setLastPollingTime');
        },

    },
};

const alreadyHappenedStore = {} as any;

function eventIsOlderThanXMinutes(e: SingleAlarmEvent): boolean {

    return moment.duration(moment().diff(moment(e.updatedTimestamp))).asMinutes() > 10;
}

function eventHasAlreadyHappened(e: SingleAlarmEvent): boolean {

    if (e.id in alreadyHappenedStore) {
        const thisTime = moment(e.updatedTimestamp);
        const lastTime = moment(alreadyHappenedStore[e.id]);
        if (moment.duration(thisTime.diff(lastTime)).asSeconds() < 30) {
            return true;
        }
    }

    alreadyHappenedStore[e.id] = e.updatedTimestamp;
    return false;

}

export default RecentAlarmsStore;
