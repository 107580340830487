
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class IButton extends Vue {

    @Prop() private icon?: string;
    @Prop() private text?: string;

    @Prop() private color?: string;

}
