export type AvailableCustomerFeatures = CustomerFeature;

export enum CustomerFeature {
    GroupedLocationExport = 'grouped_location_export',
    Audit = 'audit',
    GMP = 'qa_management',
    Corrections = 'corrections',
    DisableXLSX = 'disable_xlsx_exports',
    OwnerPlus = 'owner_plus',
}
