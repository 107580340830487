
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { GridColumnDefinition, ReferenceColumnDefinition } from '@/types';
import { Location } from 'vue-router';
import { ExactumCache, Entities } from '@/internal';
import { ParseEntityReference, GetEntityForReference } from '@/util';



@Component({})
export default class ReferenceColumn extends Vue {

    @Prop() public columnDefinition!: ReferenceColumnDefinition;
    @Prop() public columnData!: any;

    private labelAvailable: boolean = false;
    private labelUnresolved: boolean = true;
    private resolvedLabel: string = '';

    private resolvedRef: string = '';

    private routeUnresolved: boolean = false;

    // THIS WILL BREAK IF THE VALUE CHANGES!

    private getLabel() {

        let refPrefix = this.columnDefinition.referencePrefix;
        if (!refPrefix) {
            refPrefix = '';
        }

        if (this.columnDefinition.resolveLabel && this.columnData[this.columnDefinition.name]) {
            const ref = refPrefix + this.columnData[this.columnDefinition.name];
            ExactumCache.ResolveReference(ref).then((row) => {
                // Have the object
                const er = ParseEntityReference(ref);
                if (er) {
                    const entity = GetEntityForReference(er);
                    if (entity) {
                        this.resolvedLabel = entity.getPrimaryKey!(row);
                    }
                }
                this.labelAvailable = true;
                this.labelUnresolved = false;
            }).catch((error) => {
                this.resolvedLabel = '???';
                this.labelUnresolved = true;
                this.labelAvailable = true;
            });
        } else {
            this.resolvedLabel = '';
            this.labelAvailable = true;
            this.labelUnresolved = false;
        }
    }

    private mounted() {
        this.getLabel();
    }

    private get getRoute() {

        let refPrefix = this.columnDefinition.referencePrefix;
        if (!refPrefix) {
            refPrefix = '';
        }

        const data = this.columnDefinition.route;

        if (typeof data === 'string') {

            if (!this.columnData[data]) {
                this.routeUnresolved = true;
                return null;
            }

            let url = '';

            if (this.columnDefinition.routePrefix) {
                url += this.columnDefinition.routePrefix;
            }

            url += refPrefix;
            url += this.columnData[data];

            return url;

        } else if (typeof data === 'object') {
            const location: Location = data as Location;
            const routeObj: Location = { name: location.name, params: {} };

            if (location.params) {
                for (const key in location.params) {
                    if (location.params.hasOwnProperty(key)) {
                        const columnDataKey = location.params[key];
                        routeObj.params![key] = this.columnData[columnDataKey];
                    }
                }
            }

            return routeObj;
        }
    }

}
