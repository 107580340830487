
import Component from 'vue-class-component';

import RenderValue from '../RenderValue.vue';
import { Prop, Vue } from 'vue-property-decorator';


@Component({
    components: {
        RenderValue
    }
})
export default class GenericAuditChangesExplainer extends Vue {

    @Prop() public changes!: any;
}
