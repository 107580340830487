import { store } from '@/internal';
import { Route } from 'vue-router';
import { LoginInfo } from './types';

async function _loggedInReq(to: Route, from: Route) {

    if (store.getters.isLoggedIn) {
        return true;
    }

    await store.dispatch('loginFromCookie');

    if (store.getters.isLoggedIn) {
        return true;
    }

    return {name: 'login', query: {
        returnPath: to.fullPath,
    }};

}

const authorizationReq = async (to: any, from: any) => {

    if (!store.getters.CanUserAccessLink(to.path)) {
        return {name: 'notAllowed'};
    }

    return true;
};

async function _genericGuard(to: any, from: any, next: any, reqs: Array<(to: any, from: any) => any>) {

    for (const req of reqs) {
        const reqResult = await req(to, from);
        if (reqResult !== true) {
            next(reqResult); return;
        }
    }

    next(); return;
}

async function _userCustomerRoleParam(): Promise<{[key: string]: string | number}> {

    if (store.getters.isLoggedIn) {
        const loginInfo = store.getters.getLoginInfo as LoginInfo;

        const customerGuid = loginInfo.user.relCustomer;
        if (customerGuid) {
            return {
                relCustomer: '/customers/' + customerGuid,
            };
        }
    }

    return {};

}

export const Guards = {
    GenericGuard: _genericGuard,
    SettingsGuard: async (to: any, from: any, next: any) => {

        _genericGuard(to, from, next, [
            _loggedInReq, authorizationReq,
        ]);
    },

    LoggedInGuard: async (to: Route, from: Route, next: any) => {

        _genericGuard(to, from, next, [
            _loggedInReq,
        ]);
    },
};

export const RoleParams = {
    UserCustomer: _userCustomerRoleParam,
};
