import { MaxPerciseHistoryRangeHours } from '@/const';
import {
    HierarchyItemDefinition,
    HierarchyNodeType,
    SingleSensor,
} from '@/types';
import { DateTime } from 'luxon';
import VueRouter from 'vue-router';

function addAllPlaceSensorsToSelection(thisPlace: HierarchyItemDefinition) {
    const sensors: SingleSensor[] = [];

    const walk = (nodeData: HierarchyItemDefinition) => {
        for (const child of nodeData.children as HierarchyItemDefinition[]) {
            if (child.nodeType === HierarchyNodeType.Place) {
                walk(child);
            } else {
                sensors.push(child.data);
            }
        }
    };

    walk(thisPlace);
    return sensors;
}

export function ShowAllSensorsHistory(
    thisPlace: HierarchyItemDefinition,
    $router: VueRouter,
) {
    const selectedSensors = addAllPlaceSensorsToSelection(thisPlace);
    $router.push({
        name: 'measurementHistory',
        query: {
            sensors: selectedSensors.map((s) => s.id).join(','),
            from:
                DateTime.now()
                    .minus({ hours: MaxPerciseHistoryRangeHours })
                    .toMillis() + '',
            to: DateTime.now().toMillis() + '',
        },
    });
}
