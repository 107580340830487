
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { LoginInfo, LocationBarState, RootModules } from '../types';

@Component({
    components: {},
    computed: {
        ...mapState({
            showAppMenu: (store: RootModules) =>
                store.AppMenusStore.showAppMenu,
            locationBar: (store: RootModules) =>
                store.AppMenusStore.locationBar,
            loginInfo: (store: RootModules) => store.LoginStore.loginInfo,
        } as any),
        ...mapGetters(['IsViewerUser', 'ShouldDisableHierarchy']),
    },
})
export default class Home extends Vue {
    public loginInfo!: LoginInfo;
    public locationBar!: LocationBarState;

    public appMenuWidth: number = 430;

    public handleInClick: boolean = false;

    public mounted() {
        this.$store.commit('showAppMenu');
    }

    public handleDragged(event: MouseEvent) {
        if (this.handleInClick) {
            const clientWidth = window.innerWidth;
            const newX = event.clientX;

            const minWidth = 200;
            const maxWidthPercents = 0.85;
            const maxWidth = Math.max(clientWidth * maxWidthPercents, minWidth);

            if (newX <= minWidth) {
                this.appMenuWidth = minWidth;
            } else if (newX >= maxWidth) {
                this.appMenuWidth = maxWidth;
            } else {
                this.appMenuWidth = event.clientX;
            }
        }
    }
}
