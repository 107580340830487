import {
    TopBarMenuInfo,
    LocationBarState,
    AppMenusStoreState,
    AccessRightScope,
    LocationBarAction,
    LocationBarActionType,
} from '@/types';
import { Module } from 'vuex';

import Vue from 'vue';
import { GetEntityNames } from '@/util';

const appMenusState: AppMenusStoreState = {
    topBarMenu: {
        settings: false,
        user: false,
    },

    locationBar: {
        text: 'EXACTUM',
        entity: null,
    },

    showAppMenu: true,

    appLoading: 0,
    appLoadingPercents: 0,
};

const AppMenusStore: Module<any, any> = {
    state: appMenusState,

    getters: {
        isAppMenuOpened(state: AppMenusStoreState) {
            return state.showAppMenu;
        },
        isAppLoading(state: AppMenusStoreState) {
            return state.appLoading;
        },

        appLoadingPercents(state: AppMenusStoreState) {
            return state.appLoadingPercents;
        },
    },

    mutations: {
        closeTopBarMenus: (state: AppMenusStoreState) => {
            state.topBarMenu.user = false;
            state.topBarMenu.settings = false;
        },

        openTopBarMenu: (state: AppMenusStoreState, type: string) => {
            state.topBarMenu[type] = true;
        },

        setAppMenuShowHide(state: AppMenusStoreState, newState: boolean) {
            state.showAppMenu = newState;
        },

        setIsAppLoading(state: AppMenusStoreState, newState: number) {
            state.appLoading += newState;
            state.appLoadingPercents = 0;

            if (state.appLoading < 0) {
                state.appLoading = 0;
            }
        },

        setAppLoadingPercents(state: AppMenusStoreState, percents: number) {
            state.appLoadingPercents = 0;
            if (percents && typeof percents === 'number') {
                state.appLoadingPercents = percents;
            }
        },

        toggleAppMenu: (state: AppMenusStoreState) => {
            state.showAppMenu = !state.showAppMenu;
        },
        hideAppMenu: (state: AppMenusStoreState) => {
            state.showAppMenu = false;
        },
        showAppMenu: (state: AppMenusStoreState) => {
            state.showAppMenu = true;
        },

        mutate_setLocationData: (
            state: AppMenusStoreState,
            newState: LocationBarState,
        ) => {
            state.locationBar = newState;
        },
    },

    actions: {
        setLocationData({ commit, getters }, data: LocationBarState) {
            const CanUserAccess = getters.CanUserAccess;

            commit('mutate_setLocationData', {
                text: data.text,
                actions: _filterAvailableActions(CanUserAccess, data),
            } as LocationBarState);
        },
    },
};

function _filterAvailableActions(
    CanUserAccess: (
        scope: AccessRightScope,
        entity: string,
        id?: string,
    ) => boolean,
    data: LocationBarState,
) {
    if (data.actions) {
        return data.actions!.filter((action) => {
            if (data.entity) {
                const names = GetEntityNames(data.entity);

                if (action.type === LocationBarActionType.New) {
                    return CanUserAccess(
                        AccessRightScope.Create,
                        names.routeBase,
                        data.recordId,
                    );
                }

                if (action.type === LocationBarActionType.Delete) {
                    return CanUserAccess(
                        AccessRightScope.Delete,
                        names.routeBase,
                        data.recordId,
                    );
                }

                if (action.type === LocationBarActionType.Update) {
                    return CanUserAccess(
                        AccessRightScope.Update,
                        names.routeBase,
                        data.recordId,
                    );
                }
            }
            return true;
        });
    }

    return [];
}

export default AppMenusStore;
