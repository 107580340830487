
import { HierarchyItemAction } from '@/typeDefs/hierarchyItemActions';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HierarchyItemDefinition } from '../../types';
import QAEnabledIndicator from '../functional/QAEnabledIndicator.vue';
import QAManageModal from '../qa/QAManageModal.vue';

@Component({
    name: 'PlaceHierarchyItem',
    components: { QAEnabledIndicator, QAManageModal },
})
export default class PlaceHierarchyItem extends Vue {
    @Prop() private nodeData!: HierarchyItemDefinition;
    @Prop() private numChildren!: number;

    private qaManageModalOpen = false;

    public performAction(type: HierarchyItemAction) {
        if (type === HierarchyItemAction.OpenQAManageModal) {
            this.qaManageModalOpen = true;
        }
    }

    private get offlineRatio() {
        const m = this.nodeData.measurementsData;
        if (m) {
            return `(${m.numSensors - m.numOffline} / ${m.numSensors})`;
        } else {
            return '??';
        }
    }
}
