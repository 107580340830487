import {
    LoginInfo,
    TokenResponse,
    TokenInfo,
    SecurityRole,
    LoginStoreState,
    SingleUser,
    SingleCustomer,
} from '@/types';

import axios, { AxiosResponse, AxiosError } from 'axios';

import { Customer, User } from '@/internal';

import jwt_decode from 'jwt-decode';

import { ExactumCache } from '@/internal';
import Vue from 'vue';

import { Module } from 'vuex';
import { ToastOptions } from 'vue-toasted';
import { ParseEntityReference } from '@/util';
import { AvailableCustomerFeatures } from '@/typeDefs/features';

const LoginStore: Module<any, any> = {
    state: {
        inactivityWatchdog: null,

        loginInfo: {
            success: false,
            user: {
                email: '',
                password: '',
            },
        },

        currentUserInfo: null,
        currentUserCustomerFeatures: [],

        inactivityWatchdogOverrideEnabled: false,
    },

    getters: {
        getLoginInfo(state: LoginStoreState) {
            return state.loginInfo;
        },
        getAdditionalUserAttributes(state: LoginStoreState) {
            return state.currentUserInfo;
        },
        getCurrentUserCustomerFeatures(state: LoginStoreState) {
            return state.currentUserCustomerFeatures;
        },
        isLoggedIn(state: LoginStoreState) {
            return state.loginInfo.success;
        },
        IsUserAdminOrBetter(state: LoginStoreState) {
            return (
                state.loginInfo.user.roles != null &&
                state.loginInfo.user.roles.indexOf(SecurityRole.RoleAdmin) > -1
            );
        },
        IsUserOwnerOrBetter(state: LoginStoreState) {
            return (
                state.loginInfo.user.roles != null &&
                (state.loginInfo.user.roles.indexOf(SecurityRole.RoleOwner) >
                    -1 ||
                    state.loginInfo.user.roles.indexOf(
                        SecurityRole.RoleOwnerPlus,
                    ) > -1 ||
                    state.loginInfo.user.roles.indexOf(SecurityRole.RoleAdmin) >
                        -1)
            );
        },
        MaxUserRole(state: LoginStoreState): SecurityRole {
            if (state.loginInfo.user.roles != null) {
                const roles = state.loginInfo.user.roles;
                if (roles.indexOf(SecurityRole.RoleAdmin) > -1) {
                    return SecurityRole.RoleAdmin;
                } else if (roles.indexOf(SecurityRole.RoleOwnerPlus) > -1) {
                    return SecurityRole.RoleOwnerPlus;
                } else if (roles.indexOf(SecurityRole.RoleOwner) > -1) {
                    return SecurityRole.RoleOwner;
                }
            }

            return SecurityRole.RoleUser;
        },
        IsUserAtLeast(
            state: LoginStoreState,
            getters: any,
        ): (role: SecurityRole) => boolean {
            return (role: SecurityRole) => {
                if (
                    state.loginInfo &&
                    state.loginInfo.user &&
                    state.loginInfo.user.roles
                ) {
                    const roles = [
                        SecurityRole.RoleUser,
                        SecurityRole.RoleOwner,
                        SecurityRole.RoleOwnerPlus,
                        SecurityRole.RoleAdmin,
                        SecurityRole.RoleGOD,
                    ];
                    return (
                        roles.indexOf(getters.MaxUserRole) >=
                        roles.indexOf(role)
                    );
                } else {
                    return false;
                }
            };
        },
        IsInactivityWatchdogOverrideEnabled(state: LoginStoreState, getters) {
            return (
                state.inactivityWatchdogOverrideEnabled || getters.IsViewerUser
            );
        },

        IsViewerUser(state: LoginStoreState) {
            if (state.loginInfo.user.roles) {
                const roles = state.loginInfo.user.roles;
                return (
                    roles.filter((e) => e === SecurityRole.RoleViewer).length >
                    0
                );
            }
            return false;
        },
        ShouldDisableHierarchy(state: LoginStoreState, getters) {
            if (state.currentUserInfo && state.currentUserInfo.dashboard) {
                const dInfo = state.currentUserInfo.dashboard;
                return (
                    getters.IsViewerUser && dInfo.uiSettings.disableHierarchy
                );
            }
            return false;
        },
    },

    mutations: {
        SetLoginInfo(state: LoginStoreState, newInfo: LoginInfo) {
            state.loginInfo = { ...state.loginInfo, ...newInfo };
        },

        mutation__SetCurrentUserInfo(
            state: LoginStoreState,
            newCurrentUserInfo: SingleUser | null,
        ) {
            state.currentUserInfo = newCurrentUserInfo;
        },

        SetCurrentUserCustomerFeatures(
            state: LoginStoreState,
            features: AvailableCustomerFeatures[],
        ) {
            state.currentUserCustomerFeatures = features;
        },

        mutation__ResetInactivityWatchdog(state: LoginStoreState, to: any) {
            if (state.inactivityWatchdog) {
                clearTimeout(state.inactivityWatchdog);
            }

            state.inactivityWatchdog = to;
        },

        ToggleWatchdogOverride(state: LoginStoreState) {
            state.inactivityWatchdogOverrideEnabled =
                !state.inactivityWatchdogOverrideEnabled;
        },
    },

    actions: {
        async logout({ state, commit }, reason?: string): Promise<any> {
            commit('SetLoginInfo', {
                error: reason ? reason : '',
                success: false,
                token: '',
                user: {
                    email: '',
                    password: '',
                },
            });

            commit('mutation__SetCurrentUserInfo', null);

            await ExactumCache.UpdateLoginData({
                token: null,
            });
        },

        async fetchCurrentUserInfo({ state, commit }) {
            const li: LoginInfo = state.loginInfo;
            if (li.user.id) {
                const info: SingleUser = await User.providers.fetchSingle(
                    li.user.id,
                );
                commit('mutation__SetCurrentUserInfo', info);
            }
        },

        async LoadCurrentUserCustomerFeatures({ state, commit }) {
            const li: SingleUser = state.currentUserInfo;
            if (li.relCustomer) {
                const cER = ParseEntityReference(li.relCustomer);
                if (cER) {
                    const customerInfo: SingleCustomer =
                        await Customer.providers.fetchSingle(cER.id);
                    commit(
                        'SetCurrentUserCustomerFeatures',
                        customerInfo.features,
                    );
                }
            }
        },

        async login(
            { state, commit, dispatch },
            loginParams: { language: string },
        ) {
            // Reset login info
            commit('SetLoginInfo', {
                // success: false,
                error: 'NWAIT',
                token: '',
            });

            return new Promise<void>((resolve, reject) => {
                if (
                    !state.loginInfo.user.email ||
                    !state.loginInfo.user.password
                ) {
                    state.loginInfo.error = 'EUEMAILPASSMISSING';
                    reject();
                } else {
                    axios
                        .post('/login', {
                            email: state.loginInfo.user.email,
                            password: state.loginInfo.user.password,
                        })
                        .then(async (response: AxiosResponse) => {
                            if (response.data.token) {
                                try {
                                    await ExactumCache.UpdateLoginData({
                                        username: state.loginInfo.user.email!,
                                        password:
                                            state.loginInfo.user.password!,
                                        language: loginParams.language,
                                    });
                                } catch (e) {
                                    let b = e;
                                    b = b + 'x';
                                }

                                await dispatch('saveToken', response.data);

                                dispatch('ResetInactivityWatchdog');
                                resolve();
                                return;
                            } else {
                                commit('SetLoginInfo', {
                                    success: false,
                                    error: 'ERGOTNOTOKEN',
                                });
                                reject();
                            }
                        })
                        .catch((error: AxiosError) => {
                            commit('SetLoginInfo', { success: false });

                            if (
                                error &&
                                error.response &&
                                error.response.status === 400
                            ) {
                                commit('SetLoginInfo', {
                                    success: false,
                                    error: 'ERBADREQUEST',
                                });
                                reject();
                            } else if (
                                error &&
                                error.response &&
                                error.response.status === 401
                            ) {
                                commit('SetLoginInfo', {
                                    success: false,
                                    error: 'ERWRONGEMAILPASSWORD',
                                });
                                reject();
                            } else if (
                                error &&
                                error.response &&
                                error.response.status === 403
                            ) {
                                commit('SetLoginInfo', {
                                    success: false,
                                    error: 'ERNOTALLOWED',
                                });
                                reject();
                            } else {
                                commit('SetLoginInfo', {
                                    success: false,
                                    error: 'ERUNRECOGNIZEDCODE',
                                });
                                reject(error);
                            }
                        });
                }
            });
        },

        async refreshToken({ state, commit, dispatch }) {
            const loginData = await ExactumCache.GetLoginData();
            const user = loginData.username;
            const pass = loginData.password;

            if (!user || !pass) {
                alert('Prosim, ponovno se prijavite. Vaša seja je potekla.');

                window.location.href = '/';
                dispatch('logout');
            }

            const response: AxiosResponse = await axios.post('/login', {
                email: user,
                password: pass,
            });

            try {
                if (response.data.token) {
                    const data: TokenResponse = response.data;
                    const decoded: TokenInfo = jwt_decode(data.token);
                    axios.defaults.headers.common.Authorization =
                        'bearer ' + data.token + '';

                    const now = Math.round(new Date().getTime() / 1000);
                    const duration = (decoded.exp - now - 1 * 60) * 1000;
                    if (state.loginInfo.timeout) {
                        clearTimeout(state.loginInfo.timeout);
                    }

                    commit('SetLoginInfo', {
                        timeout: setTimeout(() => {
                            dispatch('refreshToken');
                        }, duration),
                        token: data.token,
                    });

                    await ExactumCache.UpdateLoginData({ token: data.token });

                    await dispatch('checkAppVersionBlocking');
                } else {
                    commit('SetLoginInfo', {
                        success: false,
                        error: 'ERGOTNOTOKEN',
                    });
                }
            } catch (error) {
                if (error && error.response && error.response.status === 400) {
                    commit('SetLoginInfo', {
                        success: false,
                        error: 'ERBADREQUEST',
                    });
                } else if (
                    error &&
                    error.response &&
                    error.response.status === 401
                ) {
                    commit('SetLoginInfo', {
                        success: false,
                        error: 'ERWRONGEMAILPASSWORD',
                    });
                } else {
                    commit('SetLoginInfo', {
                        success: false,
                        error: 'ERUNRECOGNIZEDCODE',
                    });
                }
            }
        },

        async saveToken({ state, commit, dispatch }, data: TokenResponse) {
            try {
                const decoded: TokenInfo = jwt_decode(data.token);

                const now = Math.round(new Date().getTime() / 1000);
                if (decoded.exp - now < 0) {
                    throw new Error('ETOKENEXPIRED');
                }

                const duration = (decoded.exp - now - 1 * 60) * 1000;
                if (state.loginInfo.timeout) {
                    clearTimeout(state.loginInfo.timeout);
                }

                /**
                 * 2024-02-16 THE API STARTED RETURNING ROLES AS AN OBJECT, WHICH IS NOT ALIGNED WITH THE SPECIFICATION.
                 */
                const theRoles = Array.isArray(decoded.roles)
                    ? decoded.roles
                    : Object.values(decoded.roles);

                commit('SetLoginInfo', {
                    timeout: setTimeout(() => {
                        dispatch('refreshToken');
                    }, duration),
                    token: data.token,
                    success: true,

                    user: {
                        email: decoded.email,
                        roles: theRoles,
                        id: decoded.userId,
                        relCustomer: decoded.customerId,
                    },
                });

                axios.defaults.headers.common.Authorization =
                    'bearer ' + data.token + '';

                await ExactumCache.UpdateLoginData({
                    token: data.token,
                });

                await dispatch('fetchCurrentUserInfo');
                await dispatch('LoadCurrentUserCustomerFeatures');
            } catch (e) {
                if (e.message === 'ETOKENEXPIRED') {
                    dispatch('logout', 'ETOKENEXPIRED');
                    return;
                } else {
                    dispatch('logout', 'EFAULTYTOKEN');
                }
            }
        },

        async loginFromCookie({ state, commit, dispatch }) {
            const loginData = await ExactumCache.GetLoginData();
            if (loginData.token) {
                dispatch('ResetInactivityWatchdog');
                await dispatch('saveToken', { token: loginData.token });
            }
        },

        DisableInactivityWatchdog({ commit }) {
            commit('mutation__ResetInactivityWatchdog', null);
        },

        ResetInactivityWatchdog({ state, commit, dispatch, getters }) {
            const timeWindow = 1000 * 60 * 10;

            commit(
                'mutation__ResetInactivityWatchdog',
                setTimeout(() => {
                    if (getters.IsInactivityWatchdogOverrideEnabled) {
                        dispatch('ResetInactivityWatchdog');
                        return;
                    }

                    Vue.toasted.error('Logged out due to inactivity', {
                        duration: 1000 * 20,
                    } as ToastOptions);
                    dispatch('logout', 'Logged out due to inactivity');
                }, timeWindow),
            );
        },
    },
};

export default LoginStore;
