
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import {CheckboxColumnDefinition, GridColumnDefinition} from '@/types';

@Component({})
export default class TextColumn extends Vue {

    @Prop() public columnDefinition!: GridColumnDefinition;
    @Prop() public columnData!: any;

    private value: boolean = false;

    private valueChanged(value: boolean) {

        this.value = value;

        const def = this.columnDefinition as CheckboxColumnDefinition;

        if (def.onSelect) {
            def.onSelect(value, this.columnData);
        }
    }

}
