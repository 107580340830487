import { GenerateProviders } from '@/errorHandler';
import { EntityDefinition, EntityReferenceString } from '@/types';
import { RoleParams } from '@/authorization';

export const AlarmEvent: EntityDefinition = {
    primaryKey: 'id',
    endpointName: 'alarm_events',
    isPrimaryResolver: true,
    getPrimaryKey(row: any) {
        if (row) {
            return row.id;
        }

        return 'unknown';
    },
    name: 'AlarmEvent',
    pluralName: 'AlarmEvents',
    routes: {
        single: 'settingsAlarmEventsAddEdit',
        grid: 'settingsAlarmEvents',
    },
    providers: GenerateProviders('alarm_events', {
        entity: 'AlarmEvent',
        params: {
            attributes: [
                'id',
                'type',
                'initialValue',
                'latestValue',
                'createdTimestamp',
                'updatedTimestamp',
                'finishedTimestamp',
                'confirmedTimestamp',
                'reviewedTimestamp',
                'confirmationNote',
                'reviewNote',
                'notifications',
                'relConfirmationUser',
                'relReviewUser',
                'relAlarm',
                'relCustomer',
                'relQaApproveUser',
                'qaApprovedTimestamp',
                'qaApprovalNote',
            ],
        },
        useV2Deserializer: true,
    }),
    lookupFields: {
        title: 'id',
    },
    roleBasedFilters: {
        ROLE_USER: RoleParams.UserCustomer,
        ROLE_OWNER: RoleParams.UserCustomer,
    },
};
