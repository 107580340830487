import Vue from 'vue';
import { Prop, Emit } from 'vue-property-decorator';
import Component from 'vue-class-component';

import {
    AccessRightScope,
    EntityDefinition,
    FormActionDefinition,
    FormDefinition,
    LoadingInfo,
    SecurityRole,
    SingleFormDefinition,
    SingleFormInputDefinition,
} from '@/types';
import { AvailableCustomerFeatures } from '@/typeDefs/features';

@Component({})
export default class BaseForm extends Vue {
    @Prop() public formDefinition!: FormDefinition;
    @Prop() public entityDefinition!: EntityDefinition;

    @Prop({ default: false }) public readOnly!: boolean;

    public availableCustomerFeatures: AvailableCustomerFeatures[] = [];

    public get UserRole() {
        return this.$store.getters.MaxUserRole;
    }

    public get isNewForm(): boolean {
        return this.$route.params.uid === '-1';
    }

    public formData: any = {};

    public loading(change: LoadingInfo): void {
        this.$store.commit('formLoadingChange', change);
    }

    public HasAllRequiredFeatures(allRequired: AvailableCustomerFeatures[]) {
        return allRequired.filter(
            (requiredFeature: AvailableCustomerFeatures) =>
                this.availableCustomerFeatures.indexOf(requiredFeature) >= 0,
        );
    }

    public FilterAvailableInputs(
        inputs: SingleFormInputDefinition[],
        formContextData: any,
    ) {
        return inputs
            .filter((input: SingleFormInputDefinition) => {
                // Pass if
                //      form data is not loaded
                //      or
                //      form data is loaded and relCustomer is not null
                //

                return (
                    !formContextData ||
                    (formContextData && !input.security) ||
                    (formContextData &&
                        input.security &&
                        (!input.security.requiredFeatures ||
                            input.security.requiredFeatures.length === 0)) ||
                    (formContextData &&
                        input.security &&
                        input.security.requiredFeatures &&
                        input.security.requiredFeatures.length > 0 &&
                        this.availableCustomerFeatures.length > 0 &&
                        this.HasAllRequiredFeatures(
                            input.security.requiredFeatures,
                        ))
                );
            })
            .filter((input: SingleFormInputDefinition) => {
                // Pass if
                //      input security is null and UserRole >= SecurotyRole.RoleOwner
                //      or
                //      input security is not null and minReadRole <= UserRole
                //      or
                //      customer has all required
                return (
                    (!input.security &&
                        this.$store.getters.IsUserAtLeast(
                            SecurityRole.RoleOwner,
                        )) ||
                    (input.security &&
                        input.security.minReadRole &&
                        this.$store.getters.IsUserAtLeast(
                            input.security.minReadRole,
                        ))
                );
            });
    }

    public get AvailableInputs() {
        const sfd = this.formDefinition as SingleFormDefinition;
        if (!sfd.inputs) {
            return [];
        }

        return this.FilterAvailableInputs(sfd.inputs, this.formData);
    }

    public get AvailableActions() {
        const sfd = this.formDefinition as SingleFormDefinition;

        if (!sfd.actions) {
            return [];
        }

        return this.actionFilter(sfd.actions, this.entityDefinition);
    }

    private actionFilter(actions: any[], entity: EntityDefinition) {
        const IsUserAtLeast = this.$store.getters.IsUserAtLeast;
        const CanUserAccess = this.$store.getters.CanUserAccess;

        return actions.filter((action: FormActionDefinition) => {
            if (
                action.security &&
                action.security.minRole &&
                !IsUserAtLeast(action.security.minRole)
            ) {
                return false;
            }

            if (this.isNewForm) {
                const basePrivileges =
                    ['save', 'clear', 'openSettings'].indexOf(action.name) >=
                        0 || action.name.startsWith('custom_');

                const entityName = entity.endpointName;

                if (basePrivileges) {
                    if (
                        action.name === 'save' &&
                        !CanUserAccess(AccessRightScope.Create, entityName)
                    ) {
                        return false;
                    }

                    return true;
                }

                return false;
            } else {
                const basePrivileges =
                    ['save', 'new', 'delete', 'openSettings'].indexOf(
                        action.name,
                    ) >= 0 || action.name.startsWith('custom_');

                if (action.name === 'save' && this.readOnly) {
                    return false;
                }

                if (basePrivileges) {
                    const entityName = entity.endpointName;

                    // Manual override
                    if (
                        action.security &&
                        IsUserAtLeast(action.security.minRole)
                    ) {
                        return true;
                    }

                    if (
                        action.name === 'save' &&
                        !CanUserAccess(AccessRightScope.Update, entityName)
                    ) {
                        return false;
                    } else if (
                        action.name === 'new' &&
                        !CanUserAccess(AccessRightScope.Create, entityName)
                    ) {
                        return false;
                    } else if (
                        action.name === 'delete' &&
                        !CanUserAccess(AccessRightScope.Delete, entityName)
                    ) {
                        return false;
                    }

                    return true;
                }

                return false;
            }
        });
    }
}
