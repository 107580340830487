var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row w-100"},[_c('div',{staticClass:"hierarchy-place-item-text text-nowrap"},[_vm._v(" "+_vm._s(_vm.nodeData.data.title)+" ")]),(_vm.nodeData.measurementsData)?_c('div',{staticClass:"hierarchy-location-item-device-info text-nowrap ml-2"},[_vm._v(" "+_vm._s(_vm.offlineRatio)+" ")]):_vm._e(),(
            _vm.nodeData.measurementsData &&
                _vm.nodeData.measurementsData.numHiAlarms
        )?_c('div',{staticClass:"hierarchy-location-item-device-info text-nowrap ml-2 d-flex"},[_c('icon',{attrs:{"icon":"app/alarm_up","width":"25"}}),_vm._v(" ("+_vm._s(_vm.nodeData.measurementsData.numHiAlarms)+") ")],1):_vm._e(),(
            _vm.nodeData.measurementsData &&
                _vm.nodeData.measurementsData.numLoAlarms
        )?_c('div',{staticClass:"hierarchy-location-item-device-info text-nowrap ml-2 d-flex"},[_c('icon',{attrs:{"icon":"app/alarm_down","width":"20"}}),_vm._v(" ("+_vm._s(_vm.nodeData.measurementsData.numLoAlarms)+") ")],1):_vm._e(),(_vm.nodeData.data.gmpEnabled)?_c('div',{staticStyle:{"margin-left":"10px"}},[_c('QAEnabledIndicator')],1):_vm._e(),(_vm.qaManageModalOpen)?_c('QAManageModal',{attrs:{"actionType":_vm.nodeData.data.gmpEnabled
                ? 'DisableHierarchy'
                : 'EnableHierarchy',"recordRef":_vm.nodeData.data.id,"closeMe":() => {
                _vm.qaManageModalOpen = false;
            }}}):_vm._e(),_c('div',[_c('a',[(_vm.nodeData.data.relImage)?_c('font-awesome-icon',{staticClass:"ml-2",attrs:{"icon":"image"}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }