
import { Vue, Prop, Component } from 'vue-property-decorator';
import NiceButton from '@/components/elements/NiceButton.vue';
import { RawPATCH } from '@/errorHandler';
import { resolveValue } from '../input/forms/util/resolveValue';

import { walkTree } from '@/util/hierarchyUtils';

import {
    HierarchyItemDefinition,
    HierarchyNodeType,
    SingleLocation,
    SinglePlace,
    SingleSensor,
} from '@/types';
import { RefreshHierarchyData } from '@/store/hierarchyBuilding';

// This modal shows when spawned. Its appearance is controlled through v-if on the parent element
// The modal can ask parent to close itself
// It has a required param - Action Type.
// The action type can be:
// - DisableSingle - Just shows the loading spinner and refreshes the hierarchy data
// - EnableSingle - Just shows the loading spinner and refreshes the hierarchy data
// - EnableHierarchy - Shows the warning that this action will affect underlying hierarchy
// - DisableHierarchy - Shows the warning that this action will affect underlying hierarchy

@Component({
    components: {
        NiceButton,
    },
})
export default class QAManagedModal extends Vue {
    @Prop({ default: 'DisableHierarchy' }) private actionType!:
        | 'DisableSingle'
        | 'EnableSingle'
        | 'DisableHierarchy'
        | 'EnableHierarchy';
    @Prop({ default: '/locations/TEST' }) private recordRef!: string;

    @Prop({ default: () => () => undefined }) private closeMe!: () => void;

    private loading = false;

    private async recordName() {
        return resolveValue(this.recordRef, '??');
    }

    private async confirm() {
        this.loading = true;

        const newState = this.actionType.startsWith('Enable');
        try {
            await this.changeQAState(this.recordRef, newState);
            await this.updateHierarchy(this.recordRef);
        } finally {
            this.loading = false;
            this.closeMe();
        }
    }

    private async changeQAState(recordRef: string, newState: boolean) {
        const req = await RawPATCH(
            recordRef,
            {
                gmpEnabled: newState,
            },
            {
                'Content-Type': 'application/merge-patch+json',
                'Accept': 'application/vnd.api+json',
            },
        );

        if (!req.ok) {
            const errorText = await req.text();
            Vue.toasted.error(
                this.$t('Napaka pri nastavljanju stanja GMP: ') + errorText,
            );
            /* tslint:disable-next-line */
            console.error(errorText);
            throw new Error(errorText);
        }
    }

    private async updateHierarchy(recordRef: string) {

        // Due to API quirks, I've added a delay for the new state to settle.
        await new Promise((resolve, reject) => {
            setTimeout(() => resolve(''), 2000);
        });

        await this.$store.dispatch('refreshSubtreeDataByRef', recordRef);
        await this.$store.dispatch('refreshUpstreamTreeDataByRef', recordRef);
    }
}
