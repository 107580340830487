import { ExactumCache } from '@/internal';
import { GetEntityForReference, ParseEntityReference } from '@/util';

export const resolveValue = async (
    rel: string,
    defaultLabel?: string,
): Promise<string> => {
    let label = defaultLabel || '??';

    try {
        const resolved = await ExactumCache.ResolveReference(rel);

        if (resolved) {
            const er = ParseEntityReference(rel);
            if (er) {
                const entity = GetEntityForReference(er);
                if (entity && entity.getPrimaryKey) {
                    label = entity.getPrimaryKey(resolved);
                }
            }
        }
    } catch (e) {
        label = '--';
    }

    return label;
};
