import { GenerateProviders } from '@/errorHandler';
import {
    EntityDefinition,
    EntityAttributesCollection,
    AttributeType,
    SingleMeasurement,
} from '@/types';

export const MeasurementLatest: EntityDefinition<SingleMeasurement> = {
    primaryKey: 'id',
    name: 'measurementLatest',
    pluralName: 'measurementLatests',
    routeBase: 'measurement_latests',
    endpointName: 'measurement_latests',
    isPrimaryResolver: true,
    routes: {
        single: 'settingsMeasurementsAddEdit',
        grid: 'settingsMeasurements',
    },
    providers: GenerateProviders('measurement_latests', {
        entity: 'MeasurementLatest',
        params: {
            attributes: ['value', 'relSensor', 'relCustomer', 'readTimestamp'],
        },
    }),
    lookupFields: {
        title: 'value',
    },
};
