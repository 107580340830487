
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { SingleFormInputDefinition } from '@/types';
import BaseFormInput from './BaseFormInput';
import { CustomerFeature } from '@/typeDefs/features';

@Component({})
export default class TextFormInput extends BaseFormInput {

    private groupedValueExport: boolean = false;

    private get value() {
        return this.formData[this.columnDefinition.name];
    }

    private features = [
        {
            name: CustomerFeature.GroupedLocationExport,
            label: 'feature.enableSpecialReportTrigger',
            description: 'feature.enableSpecialReportDescription',
        },
        {
            name: CustomerFeature.Audit,
            label: 'feature.enableAuditLogTrigger',
            description: 'feature.enableAuditLogDescription',
        },
        {
            name: CustomerFeature.Corrections,
            label: 'feature.enableCorrectionsTrigger',
            description: 'feature.enableCorrectionsDescription',
        },
        {
            name: CustomerFeature.GMP,
            label: 'feature.enableQAManagementTrigger',
            description: 'feature.enableQAManagementDescription',
        },
        {
            name: CustomerFeature.DisableXLSX,
            label: 'feature.disableXLSXExportsTrigger',
            description: 'feature.disableXLSXExportsDescription',
        },
        {
            name: CustomerFeature.OwnerPlus,
            label: 'feature.ownerPlusLabel',
            description: 'feature.ownerPlusDescription',
        },
    ];

    private get isEnabled() {
        return (feature: string) => {
            const k = this.formData[this.columnDefinition.name];
            if (!k || k.length === 0) {
                return null;
            }

            const values = k as string[];

            return values.indexOf(feature) >= 0;
        };
    }

    private check(feature: string, currentValue: boolean) {
        const k: string[] = this.formData[this.columnDefinition.name];
        const id = k.indexOf(feature);
        if (currentValue && id < 0) {
            // Add to array if not there
            k.push(feature);
        } else if (!currentValue && id >= 0) {
            // Remove from array
            k.splice(id, 1);
        }
    }

}
