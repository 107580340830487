
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import {GridColumnDefinition, DateTimeColumnDefinition} from '@/types';
import { DatetimeFormat } from '@/util';

import moment from 'moment-timezone';

@Component({})
export default class ReferenceColumn extends Vue {

    @Prop() public columnDefinition!: GridColumnDefinition;
    @Prop() public columnData!: any;

    private momentRef = moment;

    private get tz() {
        return this.$store.getters.getAdditionalUserAttributes.timezone;
    }

    private get dateFormat() {
        if ((this.columnDefinition as DateTimeColumnDefinition).format) {
            return (this.columnDefinition as DateTimeColumnDefinition).format;
        }

        return DatetimeFormat;
    }

}
