import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { SingleFormInputDefinition, SecurityRole } from '@/types';

@Component({})
export default class BaseFormInput extends Vue {

    @Prop() public columnDefinition!: SingleFormInputDefinition;
    @Prop() public formData!: any;

    @Prop({default: false}) public readOnly!: boolean;

    public get IsWritable() {

        if (this.readOnly) {
            return false;
        }

        const security = this.columnDefinition.security;
        const isUserAtLeast = this.$store.getters.IsUserAtLeast;
        return (!security && isUserAtLeast(SecurityRole.RoleOwner)) ||
            (security && security.minWriteRole && isUserAtLeast(security.minWriteRole));
    }

}
