
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Icon extends Vue {

    @Prop() private icon!: string;
    @Prop() private width?: number;

    get iconWidth() {
        if (this.width) {
            return this.width + 'px';
        }

        return '40px';
    }

    get iconPath() {
        return require(`@/assets/icons/${this.icon}.png`);
    }

}
