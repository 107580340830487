import Vue from 'vue';
import Component from 'vue-class-component';

import {
    EntityDefinition,
    FetchMultipleQueryParams,
    FormDefinition,
    GridFormDefinition,
    PaginationInfo,
    PaginationLinks,
    APIMetadataResponse,
    LocationBarState,
    LocationBarActionType,
    LookupFormInputDefinition,
    SingleFormInputDefinition,
    GridFormFilterLayout,
    GridOrderSettings,
    GridColumnDefinition,
    LoadingInfo,
    LocationBarAction,
} from '@/types';
import { Prop, Watch } from 'vue-property-decorator';

import BaseForm from '@/components/input/forms/BaseForm';

// Components
import ReferenceColumn from './grid/ReferenceColumn.vue';
import TextColumn from './grid/TextColumn.vue';
import LocalizedTextColumn from './grid/LocalizedTextColumn.vue';
import StaticLinkColumn from './grid/StaticLinkColumn.vue';
import ProgressColumn from './grid/ProgressColumn.vue';
import DateTimeColumn from './grid/DateTimeColumn.vue';
import MediaDownloadColumn from './grid/MediaDownloadColumn.vue';
import MappedValueColumn from './grid/MappedValueColumn.vue';
import CheckboxColumn from './grid/CheckboxColumn.vue';
import FormulaColumn from './grid/FormulaColumn.vue';

import DateTimeFormInput from '@/components/input/forms/single/DateTimeFormInput.vue';
import LookupFormInput from '@/components/input/forms/single/LookupFormInput.vue';
import MultiLookupFormInput from '@/components/input/forms/single/MultiLookupFormInput.vue';
import TextFormInput from '@/components/input/forms/single/TextFormInput.vue';
import OptionSetFormInput from '@/components/input/forms/single/OptionSetFormInput.vue';
import LocalizedOptionSetFormInput from '@/components/input/forms/single/LocalizedOptionSetFormInput.vue';
import CompoundNumberFilterInput from '@/components/input/forms/single/CompoundNumberFilterFormInput.vue';
import CompoundLookupFormInput from '@/components/input/forms/single/CompoundLookupFormInput.vue';
import BooleanFormInput from '@/components/input/forms/single/BooleanFormInput.vue';

import debounce from 'lodash/debounce';
import processMapOutput from './util/processMapOutput';
import { ExportOptions } from '@/typeDefs/exportOptions';
import { CustomerFeature } from '@/typeDefs/features';

@Component({
    components: {
        TextColumn,
        ReferenceColumn,
        LocalizedTextColumn,
        StaticLinkColumn,
        ProgressColumn,
        DateTimeColumn,
        MediaDownloadColumn,
        MappedValueColumn,
        CheckboxColumn,
        FormulaColumn,

        // Filters
        LookupFormInput,
        MultiLookupFormInput,
        TextFormInput,
        OptionSetFormInput,
        CompoundNumberFilterInput,
        LocalizedOptionSetFormInput,
        BooleanFormInput,
        CompoundLookupFormInput,
        DateTimeFormInput,
    },
})
export default class BaseGridForm extends BaseForm {
    public filterValues: { [key: string]: any } = {};

    public queryParams: FetchMultipleQueryParams = {
        itemsPerPage: 30,
        page: 1,
    };

    private showExportModal: boolean = false;

    private paginationInfo: PaginationInfo | null = null;
    private paginationLinks: PaginationLinks | null = null;

    private enableFiltering: boolean = false;

    private debouncedReload = debounce(this.reloadGrid, 350);

    private adjustableColumnInfo: { [key: string]: boolean } | null = null;

    private sortingSettings: GridOrderSettings = {
        enabled: false,
        orderBy: '',
        orderDirection: 'ASC',
    };

    private get colSortingKey() {
        return (col: GridColumnDefinition) => {
            if (col.sortKey) {
                return col.sortKey;
            }

            return col.name;
        };
    }

    private loadingInfo: LoadingInfo = {
        isLoading: false,
        loadingFailed: false,
        progress: 0,
        loadingText: 'Nalaganje...',
        loadingFailureText: 'Napaka pri nalagnju vsebine',
    };

    public loading(change: LoadingInfo) {
        this.loadingInfo.progress = 0;
        if (change.progress) {
            this.loadingInfo.progress = change.progress;
        }

        if (!change.loadingFailed) {
            this.loadingInfo.loadingFailed = false;
        } else {
            this.loadingInfo.loadingFailed = change.loadingFailed;
        }

        this.loadingInfo.isLoading = change.isLoading;

        if (change.loadingFailureText) {
            this.loadingInfo.loadingFailureText = change.loadingFailureText;
        }

        if (change.loadingText) {
            this.loadingInfo.loadingText = change.loadingText;
        }
    }

    public async export() {
        const query = this.PrepareReloadQuery();

        if (this.entityDefinition.providers.export) {
            this.loading({ isLoading: true });

            await this.entityDefinition.providers.export(query);

            this.loading({ isLoading: false });
        }
    }

    public get filteringIsEnabled() {
        const formDef = this.formDefinition as GridFormDefinition;
        return this.enableFiltering || formDef.filteringAlwaysOn;
    }

    private get sortingParam() {
        if (this.sortingSettings.enabled) {
            const sortingKey = `order[${this.sortingSettings.orderBy}]`;
            const v = {} as any;
            v[sortingKey] = this.sortingSettings.orderDirection;
            return v;
        }

        return {};
    }

    public columnClicked(colDef: GridColumnDefinition) {
        // Check if sorting is enabled and act accordingly
        // DISABLED - ASC - DESC
        if (colDef.sortable) {
            const key = colDef.sortKey ? colDef.sortKey : colDef.name;

            if (this.sortingSettings.orderBy === key) {
                if (!this.sortingSettings.enabled) {
                    this.sortingSettings.enabled = true;
                    this.sortingSettings.orderDirection = 'ASC';
                } else {
                    if (this.sortingSettings.orderDirection === 'ASC') {
                        // DESC
                        this.sortingSettings.enabled = true;
                        this.sortingSettings.orderDirection = 'DESC';
                    } else {
                        // Disable
                        this.sortingSettings.enabled = false;
                    }
                }
            } else {
                this.sortingSettings.enabled = true;
                this.sortingSettings.orderBy = key;
                this.sortingSettings.orderDirection = 'ASC';
            }

            this.queryParams.page = 1;
            return this.reloadGrid();
        }

        return Promise.resolve();
    }

    public focusGrid() {
        (this.$refs.theGrid as any).scrollIntoView();
    }

    public gotoFirstPage() {
        if (this.paginationLinks && this.paginationLinks.prev) {
            this.queryParams.page = 1;
            return this.reloadGrid();
        }
    }

    public get isOnLastPage() {
        return this.paginationLinks && !this.paginationLinks.next;
    }

    public get currentPage() {
        if (this.paginationInfo) {
            return this.paginationInfo.currentPage;
        }
        return 0;
    }

    public nextPage(): Promise<any> {
        if (this.paginationLinks && this.paginationLinks.next) {
            this.queryParams.page++;
            return this.reloadGrid() || Promise.resolve();
        }

        return Promise.resolve();
    }

    private get colLabelByName() {
        const formDef = this.formDefinition as GridFormDefinition;

        return (name: string) => {
            return formDef.columns.filter((col) => {
                return col.name === name;
            })[0].label;
        };
    }

    private get urlFilters() {
        return this.$route.query;
    }

    private enableDefaultSort() {
        const formDef = this.formDefinition as GridFormDefinition;
        const defaultSortColumn = formDef.columns.find((c) => c.defaultSort);
        if (defaultSortColumn && defaultSortColumn.defaultSort) {
            this.sortingSettings.enabled = true;
            this.sortingSettings.orderBy = defaultSortColumn.name;
            this.sortingSettings.orderDirection = defaultSortColumn.defaultSort;
        }
    }

    @Watch('urlFilters')
    private urlValuesUpdated() {
        const f = this.urlFilters;

        const out = this;

        let setAtLeastOne = false;

        Object.entries(f).forEach((entry) => {
            const key = entry[0];
            const value = entry[1];

            if (key in out.filterValues) {
                setAtLeastOne = true;
                out.$set(out.filterValues, key, value);
            }
        });

        if (setAtLeastOne && !this.filteringIsEnabled) {
            this.toggleFilters();
        }
    }

    private setupAdjustableColums() {
        const formDef = this.formDefinition as GridFormDefinition;

        if (formDef.adjustableColumns) {
            this.adjustableColumnInfo = {};

            for (const fieldName in formDef.adjustableColumns) {
                if (formDef.adjustableColumns[fieldName]) {
                    const colDef = formDef.adjustableColumns[fieldName];
                    if (colDef.always) {
                        this.$set(this.adjustableColumnInfo, fieldName, true);
                    } else {
                        this.$set(
                            this.adjustableColumnInfo,
                            fieldName,
                            colDef.default || false,
                        );
                    }
                }
            }
        }
    }

    private get hasFilterRowsLayout() {
        const formDef = this.formDefinition as GridFormDefinition;
        return formDef.filterLayout === GridFormFilterLayout.RowUntilBreak;
    }

    private get filterRows() {
        const formDef = this.formDefinition as GridFormDefinition;

        if (!formDef.filters) {
            return [];
        }

        const rows = [];
        let row = [];
        for (const item of formDef.filters) {
            row.push(item);
            if (item.rowBreak) {
                rows.push(row);
                row = [];
            }
        }

        rows.push(row);

        return rows;
    }

    private get availableColumns() {
        const formDef = this.formDefinition as GridFormDefinition;
        const IsUserAtLeast = this.$store.getters.IsUserAtLeast;

        const customerFeatures: CustomerFeature[] =
            this.$store.getters.getCurrentUserCustomerFeatures;

        const out = this;

        const roleFiltered = formDef.columns
            .filter((col) => {
                if (col.security) {
                    return IsUserAtLeast(col.security.minRole);
                }
                return true;
            })
            .filter((col) => {
                if (col.requiredFeature) {
                    return col.requiredFeature.every(
                        (required) => customerFeatures.indexOf(required) >= 0,
                    );
                }

                return true;
            });

        if (formDef.adjustableColumns && this.adjustableColumnInfo) {
            return roleFiltered.filter((col) => {
                return (
                    !!out.adjustableColumnInfo &&
                    col.name in out.adjustableColumnInfo &&
                    out.adjustableColumnInfo[col.name]
                );
            });
        }

        return roleFiltered;
    }

    private get currentPageOffset() {
        if (this.paginationInfo) {
            return (
                this.paginationInfo.itemsPerPage *
                (this.paginationInfo.currentPage - 1)
            );
        }
        return 0;
    }

    private get currentPageEndOffset() {
        return this.currentPageOffset + this.formData.length;
    }

    private get totalRecords() {
        if (this.paginationInfo) {
            return this.paginationInfo.totalItems;
        }

        return 0;
    }

    private prevPage(): void {
        if (this.paginationLinks && this.paginationLinks.prev) {
            this.queryParams.page--;
            this.reloadGrid();
        }
    }

    private PrepareReloadQuery() {
        const gridDef = this.formDefinition as GridFormDefinition;

        let reqParams = {
            ...this.sortingParam,
            ...gridDef.defaultQueryParams,
        };

        if (this.filteringIsEnabled) {
            const fv = processMapOutput(this.filterValues, gridDef);

            reqParams = { ...reqParams, ...fv };
        }

        return { ...this.queryParams, ...reqParams };
    }

    private async reloadGrid(): Promise<any> {
        if (this.entityDefinition.providers.fetchMultiple) {
            const query = this.PrepareReloadQuery();

            this.loading({ isLoading: true });

            const data = await this.entityDefinition.providers.fetchMultiple(
                query,
            );

            this.loading({ isLoading: false });

            this.paginationLinks = data.links;
            this.paginationInfo = data.meta;

            this.formData = data.data;
        }
    }

    private openSingleForm(uid: string) {
        if (this.formDefinition.routes && this.formDefinition.routes.single) {
            this.$router.push({
                name: this.formDefinition.routes.single,
                params: { uid },
            });
        }
    }

    private setLocationInfo() {
        const sfd = this.formDefinition as GridFormDefinition;

        const state: LocationBarState = {
            entity: this.entityDefinition,
            recordId: this.$route.params.uid,
            text: 'Seznam',
            actions: [
                {
                    type: LocationBarActionType.New,
                    text: 'Novo',
                    icon: 'plus',
                    func: () => {
                        this.openSingleForm('-1');
                    },
                },
            ],
        };

        if (sfd.locationBar && sfd.locationBar.actions && state.actions) {
            state.actions.push(
                ...this.ProcessAdditionalActions(sfd.locationBar.actions),
            );
        }

        this.$store.dispatch('setLocationData', state);
    }

    private populateFilterFields() {
        const form = this.formDefinition as GridFormDefinition;

        if (form.filters) {
            const a: { [key: string]: any } = {};
            form.filters.forEach((v: SingleFormInputDefinition) => {
                a[v.name] = null;
            });

            this.$set(this, 'filterValues', a);
        }
    }

    private ProcessAdditionalActions(
        actions: LocationBarAction[],
    ): LocationBarAction[] {
        for (const action of actions) {
            if (action.type === LocationBarActionType.GridExportPDF) {
                action.text = 'Izvozi PDF';
                action.icon = 'app/pdf';
                action.func = () => {
                    this.ExportGridPDF(action.options || {});
                };
            }

            if (action.type === LocationBarActionType.GridExportXLSX) {
                action.text = 'Izvozi Excel';
                action.icon = 'app/xlsx';
                action.func = this.ExportGridXLSX;
            }
        }

        const myFeatures: CustomerFeature[] =
            this.$store.getters.getCurrentUserCustomerFeatures;
        const xlsxDisabled = myFeatures.includes(CustomerFeature.DisableXLSX);
        if (xlsxDisabled) {
            return actions.filter(
                (a) => a.type != LocationBarActionType.GridExportXLSX,
            );
        }

        return actions;
    }

    private async ExportGridPDF(options: ExportOptions) {
        this.showExportModal = true;

        // tslint:disable-next-line: max-line-length
        const util = await import(
            // tslint:disable-next-line:trailing-comma
            /* webpackChunkName: "pdfGridExport" */ '@/components/functional/PDFExport/PDFExport'
        );

        const definition = this.formDefinition as GridFormDefinition;
        let title = definition.title;
        if (definition.dynamicTitle) {
            title = await definition.dynamicTitle({
                filterData: this.filterValues,
                formDefinition: this.formDefinition,
                formData: this.formData,
            });
        }

        let cols = [
            ...this.availableColumns,
            ...(options.additionalColumns || []),
        ];
        const customColumnSort = options.columnOrder;
        if (customColumnSort) {
            cols = cols.filter((c) => customColumnSort.indexOf(c.name) >= 0);
            cols.sort(
                (col1, col2) =>
                    customColumnSort.indexOf(col1.name) -
                    customColumnSort.indexOf(col2.name),
            );
        }

        util.ExportGridPDF(
            this.entityDefinition,
            this.PrepareReloadQuery(),
            cols,
            {
                title,
                subtitle: this.formDefinition.subtitle,
            },
            this.formDefinition as GridFormDefinition,
        );
    }

    private async ExportGridXLSX() {
        this.showExportModal = true;

        // tslint:disable-next-line: max-line-length
        const util = await import(
            // tslint:disable-next-line:trailing-comma
            /* webpackChunkName: "xlsxGridExport" */ '@/components/functional/XLSXExport/XLSXExport'
        );

        const definition = this.formDefinition as GridFormDefinition;
        let title = definition.title;
        if (definition.dynamicTitle) {
            title = await definition.dynamicTitle({
                filterData: this.filterValues,
                formDefinition: this.formDefinition,
                formData: this.formData,
            });
        }

        util.ExportGridXLSX(
            this.entityDefinition,
            this.PrepareReloadQuery(),
            this.availableColumns,
            {
                title,
                subtitle: this.formDefinition.subtitle,
            },
            this.formDefinition as GridFormDefinition,
        );
    }

    private mounted(): void {
        this.setLocationInfo();
        this.setupAdjustableColums();
        this.populateFilterFields();
        this.enableDefaultSort();
        this.urlValuesUpdated();
        this.reloadGrid();
    }

    private toggleFilters() {
        if (!this.filteringIsEnabled) {
            // Enable filtering
            this.enableFiltering = true;
        } else {
            // Disable filtering
            this.enableFiltering = false;
            this.populateFilterFields();
            this.debouncedReload();
        }
    }

    @Watch('filterValues', { deep: true })
    private filterValuesChanged() {
        if (this.filteringIsEnabled) {
            this.queryParams.page = 1;
            this.debouncedReload();
        }
    }
}
