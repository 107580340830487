import { GenerateProviders } from '@/errorHandler';
import { EntityDefinition, EntityReferenceString } from '@/types';
import { RoleParams } from '@/authorization';

/**
 * [
 * {
 *   "id": "string",
 *   "title": "string",
 *   "position": "string",
 *   "image": "string",
 *   "parent": "string",
 *   "children": [
 *     "string"
 *   ],
 *   "noAccessUsers": [
 *     "string"
 *   ],
 *   "relLocation": "string",
 *   "relCustomer": "string"
 * }
 * ]
 */

export const Place: EntityDefinition = {
    primaryKey: 'title',
    getPrimaryKey(row: any) {
        return row.title;
    },
    name: 'Place',
    pluralName: 'Places',
    routes: {
        single: 'settingsPlacesAddEdit',
        grid: 'settingsPlaces',
    },
    endpointName: 'places',
    isPrimaryResolver: true,
    providers: GenerateProviders('places', {
        entity: 'Place',
        params: {
            attributes: [
                'title',
                'position',
                'relImage',
                'parent',
                'noAccessUsers',
                'relLocation',
                'relCustomer',
            ],
        },
    }),
    lookupFields: {
        title: ['title'],
    },
    roleBasedFilters: {
        ROLE_USER: RoleParams.UserCustomer,
        ROLE_OWNER: RoleParams.UserCustomer,
    },
};
