import { ContextMenuOption, SecurityRole, SingleLocation } from '@/types';
import { HierarchyMenuOptionsContext } from '../../../typeDefs/HierarchyMenuOptionsContext';
import PrepareAndWaitForReport from '../commands/PrepareAndWaitForReport';
import { CustomerFeature } from '@/typeDefs/features';
import { HierarchyItemAction } from '@/typeDefs/hierarchyItemActions';
import { ShowAllSensorsHistory } from '../commands/ShowAllSensorsHistory';

export default function CustomerHierarchyMenuItem(
    out: HierarchyMenuOptionsContext,
): ContextMenuOption[] {
    return [
        {
            label: 'Nastavitve stranke',
            action: () => {
                const path = out.settingsLink(out.nodeData);
                if (path && out.CanUserAccessLink(path)) {
                    out.$router.push({ path });
                }
            },
            minRole: SecurityRole.RoleOwner,
            icon: 'cog',
        },
        {
            label: 'Osveži podatke meritev',
            action: () => {
                out.performItemAction(
                    HierarchyItemAction.UpdateSubtreeMeasurements,
                );
            },
            icon: 'sync',
        },
        {
            label: 'Izvozi meritev',
            action: () => {
                out.$router.push({
                    name: 'settingsMeasurementExports',
                    query: {
                        relCustomer: out.nodeData.data.id,
                    },
                });
            },
            icon: 'cloud-download-alt',
        },
    ];
}
