import { Module } from 'vuex';
import {
    SecurityRole,
    AccessRightScope,
    LoginInfo,
    AccessRightsRootState,
} from '@/types';
import {
    AvailableCustomerFeatures,
    CustomerFeature,
} from '@/typeDefs/features';

const RootState: AccessRightsRootState = {
    entities: {
        customers: {
            read: SecurityRole.RoleAdmin,
            create: SecurityRole.RoleAdmin,
            update: SecurityRole.RoleAdmin,
            delete: SecurityRole.RoleAdmin,
            hierarchySettings: SecurityRole.RoleUser,
        },
        sensors: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleAdmin,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleUser,

            changeImageCoordinates: SecurityRole.RoleOwner,
        },
        places: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleOwner,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleUser,

            changeImageCoordinates: SecurityRole.RoleOwner,
        },
        locations: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleOwner,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleUser,
        },
        users: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleOwner,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleOwner,
        },
        measurement_exports: {
            read: SecurityRole.RoleUser,
            create: SecurityRole.RoleUser,
            update: SecurityRole.RoleAdmin,
            delete: SecurityRole.RoleUser,
            hierarchySettings: SecurityRole.RoleUser,
        },
        media_objects: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleOwner,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleOwner,
        },
        alarming: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleOwner,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleOwner,
        },
        me: {
            read: SecurityRole.RoleUser,
            create: SecurityRole.RoleAdmin,
            update: SecurityRole.RoleUser,
            delete: SecurityRole.RoleAdmin,
            hierarchySettings: SecurityRole.RoleUser,
        },
        audits: {
            read: SecurityRole.RoleUser,
            create: SecurityRole.RoleGOD,
            update: SecurityRole.RoleGOD,
            delete: SecurityRole.RoleGOD,
            hierarchySettings: SecurityRole.RoleUser,
        },
        dashboard: {
            read: SecurityRole.RoleAdmin,
            create: SecurityRole.RoleAdmin,
            update: SecurityRole.RoleAdmin,
            delete: SecurityRole.RoleAdmin,
            hierarchySettings: SecurityRole.RoleAdmin,
        },
        corrections: {
            read: SecurityRole.RoleUser,
            create: SecurityRole.RoleAdmin,
            update: SecurityRole.RoleAdmin,
            delete: SecurityRole.RoleAdmin,
            hierarchySettings: SecurityRole.RoleUser,
        },
        userGroups: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleOwner,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleOwner,
        },
        user_groups: {
            read: SecurityRole.RoleOwner,
            create: SecurityRole.RoleOwner,
            update: SecurityRole.RoleOwner,
            delete: SecurityRole.RoleOwner,
            hierarchySettings: SecurityRole.RoleOwner,
        },
        measurement_notes: {
            read: SecurityRole.RoleUser,
            create: SecurityRole.RoleGOD,
            update: SecurityRole.RoleGOD,
            delete: SecurityRole.RoleGOD,
            hierarchySettings: SecurityRole.RoleUser,
        },
        certificates: {
            read: SecurityRole.RoleUser,
            create: SecurityRole.RoleGOD,
            update: SecurityRole.RoleGOD,
            delete: SecurityRole.RoleGOD,
            hierarchySettings: SecurityRole.RoleUser,
        },
    },
};

const AccessRightsStore: Module<any, any> = {
    state: RootState,

    getters: {
        CanUserAccess(
            state: AccessRightsRootState,
            getters: any,
            rootState: any,
            rootGetters: any,
        ) {
            // debugger; // Uncomment this to debug user access checks - security permissions
            return (scope: AccessRightScope, entity: string, id?: string) => {
                if (scope === AccessRightScope.Settings) {
                    return _resolveSettingsScope(
                        state,
                        rootGetters,
                        entity,
                        id,
                    );
                }

                if (scope === AccessRightScope.HierarchySettings) {
                    return _resolveHierarchySettingsScope(
                        state,
                        rootGetters,
                        entity,
                        id,
                    );
                }

                if (scope === AccessRightScope.Create) {
                    return _resolvePermission(state, getters, entity, 'create');
                }

                if (scope === AccessRightScope.Delete) {
                    return _resolvePermission(state, getters, entity, 'delete');
                }

                if (scope === AccessRightScope.Update) {
                    return _resolvePermission(state, getters, entity, 'update');
                }

                if (scope === AccessRightScope.ChangeImageCoordinates) {
                    return _resolvePermission(
                        state,
                        getters,
                        entity,
                        'changeImageCoordinates',
                    );
                }
            };
        },

        CanUserAccessLink(
            state: AccessRightsRootState,
            getters: any,
            rootState: any,
            rootGetters: any,
        ) {
            return (link: string) => {
                // debugger; // Uncomment this to debug link access checks eg. /user_groups/id
                if (link[0] === '/') {
                    link = link.substr(1);
                }

                const toParts = link.split('/');
                if (toParts && toParts.length > 0) {
                    const scope = toParts[0];
                    const entity = toParts[1];

                    let id = '';
                    if (toParts.length >= 3) {
                        id = toParts[2];
                    }

                    if (!getters.CanUserAccess(scope, entity, id)) {
                        return false;
                    }
                }

                return true;
            };
        },
    },
};

function _resolvePermission(
    state: AccessRightsRootState,
    g: any,
    entity: string,
    permission: string,
): boolean {
    // debugger; // If other breakpoints are not hit when checking permission issues, use this one.
    if (entity in state.entities) {
        return g.IsUserAtLeast(state.entities[entity][permission]);
    } else {
        return false;
    }
}

function _resolveSettingsScope(
    state: AccessRightsRootState,
    g: any,
    entity: any,
    id?: string,
): boolean {
    // exception - users can see themselves
    const loginInfo: LoginInfo = g.getLoginInfo;
    if (entity === 'users' && id === loginInfo.user.id) {
        return true;
    }

    const features: AvailableCustomerFeatures[] =
        g.getCurrentUserCustomerFeatures;
    if (entity === 'audits') {
        return (
            _resolvePermission(state, g, entity, 'read') &&
            features.indexOf(CustomerFeature.Audit) >= 0
        );
    }

    // else
    return _resolvePermission(state, g, entity, 'read');
}

function _resolveHierarchySettingsScope(
    state: AccessRightsRootState,
    g: any,
    entity: any,
    id?: string,
): boolean {
    return _resolvePermission(state, g, entity, 'hierarchySettings');
}

export default AccessRightsStore;
