
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import NiceButton from '../../elements/NiceButton.vue';

@Component({
    components: { NiceButton },
})
export default class GuidConceal extends Vue {
    public visible: boolean = false;

    @Prop() private value!: string;

    @Watch('value')
    private valueChanged() {
        this.visible = false;
    }
}
