
import { OptionSetFormInputContext, OptionSetFormInputDefinition, OptionSetOption } from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseFormInput from './BaseFormInput';

@Component({})
export default class OptionSetFormInput extends BaseFormInput {

    public get opts(): Promise<any[]> {
        if (!this.columnDefinition) {
            return Promise.resolve([]);
        }

        const d = this.columnDefinition as OptionSetFormInputDefinition;

        if (typeof d.options === 'function') {
            const m = d.options as (context: OptionSetFormInputContext<any>) => Promise<any>;
            return m({record: this.formData});
        }

        return Promise.resolve(d.options);
    }

    // private translateOptions(opts: string[] | OptionSetOption[]): OptionSetOption[] {
    //     return opts.map((col: string | OptionSetOption) => {
    //         return {
    //             code: col,
    //             label: this.$t(col).toString(),
    //         };
    //     });
    // }
}
