
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { Watch } from 'vue-property-decorator';

@Component({
    computed: {},
})
export default class HierarchyContextMenu extends Vue {

    private get context() {
        return this.$store.getters.hierarchyContextMenu;
    }

    private closeContextMenu() {
        this.$store.commit('closeContextMenu');
    }

    private get positionStyle() {

        const menuObj: HTMLDivElement = this.$refs.cMenu as HTMLDivElement;

        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const menuWidth = menuObj ? menuObj.clientWidth : 200;
        const menuHeight = menuObj ? menuObj.clientHeight : 200;

        let xPos = this.context.position.x;
        let yPos = this.context.position.y;

        xPos = xPos + menuWidth > screenWidth ? screenWidth - menuWidth : xPos;
        yPos = yPos + menuHeight > screenHeight ? screenHeight - menuHeight : yPos;

        // alert("Y: " + this.context.position.y + ", Height: " + menuHeight);

        return {
            left:  `${xPos}px`,
            top: `${yPos}px`,
        };
    }

    private doAction(action: any) {
        action();
        this.closeContextMenu();
    }

}
