
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { SingleFormInputDefinition } from '@/types';
import BaseFormInput from './BaseFormInput';

import 'leaflet-geosearch/assets/css/leaflet.css';
import 'leaflet/dist/leaflet.css';


import * as L from 'leaflet';
import { LatLng } from 'leaflet';
delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import { GeoSearchControl, OpenStreetMapProvider, BingProvider } from 'leaflet-geosearch';

@Component({})
export default class MapLocationFormInput extends BaseFormInput {

    private map?: L.Map;

    private centerMarker?: any;

    private inputTimeout: any;

    private fieldDirty: boolean = false;
    private dirtyValue: string = '';

    private newFieldValue: string = '';

    private mounted() {

        const searchControl = new GeoSearchControl({
            provider: new BingProvider({
                params: {
                    key: 'AhgEjjjO9mzQKc6XSd7HgEOQYBDViD3SGIuWEF93mFDwQtUWji5CUGCuK4XXJkVj',
                },
            }),
            autoClose: true,
        });


        this.map = L.map('inputMap');
        this.map.addControl(searchControl);

        this.setCenterToCurrentValue();

        const that = this;
        setTimeout(() => {
            this.setCenterToCurrentValue();
        }, 500);

        if (this.IsWritable) {
            this.map.on('move', () => { that.mapMoved.apply(that); });
        }

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
            tileSize: 512,
            maxZoom: 18,
            zoomOffset: -1,
            id: 'mapbox/streets-v11',
            accessToken: 'pk.eyJ1IjoibHN0ZWxwIiwiYSI6ImNrMjBzODQ2MzEyOHQzY212MGNlOXc1N2IifQ.HbgIEnfNPgwkbRm-dITXgA',
        }).addTo(this.map);

        this.centerMarker = L.marker(this.map.getCenter()).addTo(this.map);
    }

    private parseLatLng(ll?: string): LatLng {

        if (!ll) {
            return new LatLng(46.04946743766943, 14.506523609161379); // Ljubljana
        } else {
            const vals = ll.split(',').map((a: string) => parseFloat(a));
            return new LatLng(vals[0], vals[1]);
        }
    }

    private setCenterToCurrentValue() {

        if (this.map) {
            if (this.centerMarker) {
                this.centerMarker.setLatLng(this.map.getCenter());
            }

            this.map.setView(this.parseLatLng(this.formData[this.columnDefinition.name]), 15);
        }
    }

    private keyboardEntered(e: any) {

        this.fieldChanged(e.target.value).then(() => {
            if (this.map) {
                this.map.panTo(this.parseLatLng(this.formData[this.columnDefinition.name]));
            }
        });
    }

    private applyDirtyValue() {
        const a = this.columnDefinition.name;
        Vue.set(this.formData, this.columnDefinition.name, this.dirtyValue);

        this.fieldDirty = false;
        this.dirtyValue = '';
    }

    private fieldChanged(newValue?: string) {

        if (!this.IsWritable) {
            return Promise.resolve();
        }

        if (this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }

        const out = this;

        return new Promise((resolve, reject) => {
            this.inputTimeout = setTimeout(() => {
                out.fieldDirty = true;
                out.dirtyValue = newValue ? newValue : '';
                // const a = out.columnDefinition.name;
                // Vue.set(out.formData, out.columnDefinition.name, newValue);
                resolve(void 0);
            }, 250);
        });
    }

    private printLatLng(ll: LatLng): string {
        return ll.lat + ', ' + ll.lng;
    }

    private mapMoved() {

        if (this.map) {
            if (this.centerMarker) {
                this.centerMarker.setLatLng(this.map.getCenter());
            }

            const {lat, lng} = this.map.getCenter();
            this.fieldChanged([lat, lng].join(', '));
        }

    }

}
