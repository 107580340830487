
import { mapState, mapGetters } from 'vuex';
import {
    UserInfo,
    LoginInfo,
    TopBarMenuInfo,
    RootModules,
    SingleUser,
} from './types';
import { Component, Vue, Watch } from 'vue-property-decorator';

import HierarchyContextMenu from '@/components/hierarchy/HierarchyContextMenu.vue';

import AppInitScreen from '@/views/Init.vue';

import { ExactumCache, store } from '@/internal';

import throttle from 'lodash/throttle';
import NiceBanner from './components/elements/NiceBanner.vue';

function ___resetWatchdogTimer() {
    store.dispatch('ResetInactivityWatchdog');
}

const resetWatchdogTimer = throttle(___resetWatchdogTimer, 5000);

interface ServerData {
    address: string;
}

@Component({
    components: { HierarchyContextMenu, AppInitScreen, NiceBanner },
    computed: {
        ...mapState({
            loginInfo: (state: RootModules) => state.LoginStore.loginInfo,
            topBarMenu: (state: RootModules) => state.AppMenusStore.topBarMenu,
        } as any),
        ...mapGetters([
            'IsUserOwnerOrBetter',
            'IsUserAdminOrBetter',
            'MaxUserRole',
            'isAppLoading',
            'hierarchyContextMenu',
            'appLoadingPercents',
            'didAppInitSucceed',
            'IsInactivityWatchdogOverrideEnabled',
            'getAdditionalUserAttributes',
            'IsViewerUser',
        ]),
    },
})
export default class Home extends Vue {
    private publicPath: string = process.env.BASE_URL || '/';

    private didAppInitSucceed!: boolean;

    private loginInfo!: LoginInfo;
    private getAdditionalUserAttributes!: SingleUser | null;

    private IsInactivityWatchdogOverrideEnabled!: boolean;

    private topBarMenu!: TopBarMenuInfo;

    private showLanguageSelector: boolean = false;

    private get username() {
        const li: SingleUser | null = this.getAdditionalUserAttributes;
        if (li && li.firstname && li.lastname) {
            return li.firstname + ' ' + li.lastname;
        }
    }

    private get timezone() {
        const li: SingleUser | null = this.getAdditionalUserAttributes;
        if (li && li.timezone) {
            return li.timezone;
        }
    }

    private get version() {
        return process.env.VUE_APP_VERSION;
    }

    private get isLoggedIn() {
        return this.$store.getters.isLoggedIn;
    }

    @Watch('isLoggedIn')
    private loginStateChanged() {
        if (!this.isLoggedIn) {
            this.removeInactivityWatchdogEventListeners();
            this.doLogout();
        } else {
            this.setupInactivityWatchdogEventListeners();
        }
    }

    private removeInactivityWatchdogEventListeners() {
        // inactivity watchdog timer reseting
        window.removeEventListener('mousemove', resetWatchdogTimer);
        window.removeEventListener('click', resetWatchdogTimer);
        window.removeEventListener('keypress', resetWatchdogTimer);
        window.removeEventListener('touchstart', resetWatchdogTimer);
    }

    private setupInactivityWatchdogEventListeners() {
        // inactivity watchdog timer setting
        window.addEventListener('mousemove', resetWatchdogTimer);
        window.addEventListener('click', resetWatchdogTimer);
        window.addEventListener('touchstart', resetWatchdogTimer);
        window.addEventListener('keypress', resetWatchdogTimer);
    }

    private get settingsOpened() {
        const r = this.$route.path.split('/');
        return r[1] && r[1] === 'settings';
    }

    @Watch('$i18n.locale')
    private localeChanged(): Promise<any> {
        this.showLanguageSelector = false;
        if (this.isLoggedIn) {
            return ExactumCache.UpdateLoginData({
                language: this.$i18n.locale,
            });
        } else {
            return Promise.resolve();
        }
    }

    private closeMenus(): void {
        this.$store.commit('closeTopBarMenus');
    }

    private menuClick(type: string): void {
        if (this.topBarMenu[type]) {
            this.closeMenus();
        } else {
            this.closeMenus();
            this.$store.commit('openTopBarMenu', type);
        }
    }

    private openSettings(): void {
        this.reroute({ name: 'settingsMe' }).then(() => {
            this.$store.commit('showAppMenu');
        });
    }

    private openDashboard(): void {
        this.$store.commit('appInitDoReloadData');
        this.$store.commit('appInitCompleted', false);
        this.reroute({ name: 'dash' }).then(() => {
            this.$store.commit('showAppMenu');
        });
    }

    private doLogout() {
        const out = this;
        this.$store.commit('appInitLoginChange', 'logout');
        this.$store.dispatch('logout').then(() => {

            // This is what causes the login redirect on Watchdog trigger
            out.$router.push({
                name: 'login', query: {
                    returnPath: out.$route.fullPath
                }
            });
        });
    }

    private menuItemClick(type: string): void {
        this.closeMenus();

        try {
            if (type === 'usersettings') {
                if (this.loginInfo.user != null) {
                    this.reroute('/settings/users/' + this.loginInfo.user.id);
                }
            } else if (type === 'logout') {
                this.doLogout();
            } else if (type === 'english') {
                this.$i18n.locale = 'en';
            } else if (type === 'disableWatchdog') {
                this.$store.commit('ToggleWatchdogOverride');
            }
        } catch (e) {
            throw new Error('Menu error happened.');
        }
    }

    private reroute(route: any): Promise<any> {
        return this.$router.push(route);
    }

    @Watch('$route', { immediate: true, deep: true })
    private onUrlChange(newVal: any) {
        // this.$store.commit('hideAppMenu');
        this.$store.commit('closeTopBarMenus');
    }
}
