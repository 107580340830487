import axios from 'axios';
import {
    APIMetadataResponse,
    SingleSensorTypeMeta,
    SensorUnitDefinitionCollection,
    SingleChildUnitDefinition,
} from './types';
import { RoundNumber } from './util';

import { compile } from 'mathjs';

let instance: UnitManager | null = null;

export default class UnitManager {
    public static async Setup() {
        if (instance === null) {
            instance = new UnitManager();
            await instance.Init();
        }

        return instance;
    }

    public static GetInstance() {
        if (instance === null) {
            throw new Error('UnitManager not initialized');
        }

        return instance;
    }

    // private static instance: UnitManager | null = null;
    private defs: SensorUnitDefinitionCollection | null = null;
    private unitSymbols: { [name: string]: string } | null = null;

    private constructor() {}

    public ConvertValue(
        value: number,
        sensorType: string,
        fromUnit: string,
        toUnit: string,
    ) {
        const to = this.resolveDef(sensorType, toUnit);
        const from = this.resolveDef(sensorType, fromUnit);

        if (!to.fromBase) {
            throw new Error(`Unit ${toUnit} is missing a conversion function`);
        }

        if (!from.toBase) {
            throw new Error(
                `Unit ${fromUnit} is missing a conversion function`,
            );
        }

        return this.fixValue(to.fromBase(from.toBase(value)));
    }

    public ConvertValueToBaseUnit(
        value: number,
        sensorType: string,
        fromUnit: string,
    ) {
        const from = this.resolveDef(sensorType, fromUnit);

        if (!from.toBase) {
            throw new Error(
                `Unit ${fromUnit} is missing a conversion function`,
            );
        }

        return this.fixValue(from.toBase(value));
    }

    public ConvertValueFromBaseUnit(
        value: number,
        sensorType: string,
        toUnit: string,
    ) {
        const to = this.resolveDef(sensorType, toUnit);

        if (!to.fromBase) {
            throw new Error(`Unit ${toUnit} is missing a conversion function`);
        }

        return this.fixValue(to.fromBase(value));
    }

    public GetDisplaySymbol(sensorType: string, unit: string) {
        return this.resolveDef(sensorType, unit).symbol;
    }

    public GetUnitNamePairs(
        sensorType: string,
    ): Array<{ label: string; code: string }> {
        if (!this.defs || !this.defs[sensorType]) {
            return [];
        }

        const v = this.defs[sensorType];
        return Object.entries(v.units).map(([name, data]) => ({
            label: data.symbol,
            code: name,
        }));
    }

    public GetSensorTypes(): string[] {
        if (!this.defs) {
            return [];
        }

        return Object.entries(this.defs).map(([name]) => name);
    }

    public GetAvailableSensorUnits(sensorType: string): string[] {
        if (!this.defs || !this.defs[sensorType]) {
            return [];
        }

        const v = this.defs[sensorType];
        return Object.entries(v.units).map(([name]) => name);
    }

    public ConstructConverter(
        sensorType: string,
        fromUnit: string,
        toUnit: string,
        roundTo: 1 | 2 | 3 | 4,
    ) {
        const to = this.resolveDef(sensorType, toUnit);
        const from = this.resolveDef(sensorType, fromUnit);

        return (value: number) => {
            if (!from.toBase) {
                throw new Error(
                    `Unit ${fromUnit} is missing a conversion function`,
                );
            }

            if (!to.fromBase) {
                throw new Error(
                    `Unit ${toUnit} is missing a conversion function`,
                );
            }

            return RoundNumber(to.fromBase(from.toBase(value)), roundTo);
        };
    }

    public ConstructBaseToUnitConverter(
        sensorType: string,
        toUnit: string,
        roundTo: 1 | 2 | 3 | 4,
    ) {
        const to = this.resolveDef(sensorType, toUnit);

        return (value: number) => {
            if (!to.fromBase) {
                throw new Error(
                    `Unit ${toUnit} is missing a conversion function`,
                );
            }

            return RoundNumber(to.fromBase(value), roundTo);
        };
    }

    public GetSymbolForName(name: string): string {
        if (!this.unitSymbols) {
            throw new Error(
                'Unit Symbols are not prepared. Did you call the Setup method?',
            );
        }

        return this.unitSymbols[name];
    }

    private fixValue(value: number | string) {
        const num = typeof value === 'string' ? parseFloat(value) : value;
        return parseFloat(num.toPrecision(10));
    }

    private resolveDef(
        sensorType: string,
        toUnit: string,
    ): SingleChildUnitDefinition {
        const defs = this.defs as SensorUnitDefinitionCollection;

        if (!defs[sensorType]) {
            throw new Error('Sensor Type ' + sensorType + ' is not defined!');
        }

        const sensorMeta = defs[sensorType];
        if (!sensorMeta.units[toUnit]) {
            throw new Error(
                `Unit ${toUnit} of senosr type ${sensorType} not defined`,
            );
        }

        return sensorMeta.units[toUnit];
    }

    private async Init() {
        const dataR = (await this.loadData()) as SensorUnitDefinitionCollection;

        this.prepareData(dataR as any);

        if (!this.defs) {
            throw new Error('Unti metadata was not loaded.');
        }
    }

    private async loadData(): Promise<SensorUnitDefinitionCollection> {
        // return demoAPIdata;

        const baseUrl = process.env.VUE_APP_API_BASE_URL as string;
        const url = baseUrl + 'sensors/units';
        const unitsResponse = await fetch(url, {
            headers: {
                Authorization:
                    axios.defaults.headers.common.Authorization?.toString() ||
                    '',
            },
        });
        const data = await unitsResponse.json();
        return data;
    }

    private prepareData(v: SensorUnitDefinitionCollection) {
        this.defs = v;

        // Flatten the unit symbols
        this.unitSymbols = Object.fromEntries(
            Object.entries(v)
                .map(([type, def]) =>
                    Object.entries(def.units).map(([unitName, unitDef]) => [
                        unitName,
                        unitDef.symbol,
                    ]),
                )
                .flat(),
        );

        // Prepare conversion functions

        for (const typeName in v) {
            if (v.hasOwnProperty(typeName)) {
                const def = v[typeName];

                const defaultConversion = (v: number) => v;

                for (const unitName in def.units) {
                    if (def.units.hasOwnProperty(unitName)) {
                        const unitDef = def.units[unitName];

                        if (unitDef.conversion === 'UNIT') {
                            unitDef.fromBase = defaultConversion;
                            unitDef.toBase = defaultConversion;
                        } else {
                            const fromFunc = compile(unitDef.conversion);
                            unitDef.fromBase = (v: number) => {
                                return fromFunc.evaluate({ UNIT: v });
                            };

                            const toFunc = compile(unitDef.reverseConversion);
                            unitDef.toBase = (v: number) => {
                                return toFunc.evaluate({ UNIT: v });
                            };
                        }
                    }
                }
            }
        }
    }
}
