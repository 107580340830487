import { GenerateProviders } from '@/errorHandler';
import {
    EntityDefinition,
    SecurityRole,
    EntityReferenceString,
    SingleCustomer,
} from '@/types';

export const Customer: EntityDefinition = {
    primaryKey: 'title',
    endpointName: 'customers',
    isPrimaryResolver: true,
    getPrimaryKey(row: any) {
        if (row) {
            return row.title;
        }

        return 'unknown';
    },
    name: 'Customer',
    pluralName: 'Customers',
    routes: {
        single: 'settingsCustomersAddEdit',
        grid: 'settingsCustomers',
    },
    providers: GenerateProviders('customers', {
        entity: 'Customer',
        params: {
            attributes: [
                'erpId',
                'erpDb',
                'notes',
                'password',
                'title',
                'username',
                'features',
            ],

            transform(row: any) {
                const k = { ...row };
                if (k.erpId) {
                    k.erpId = parseInt(k.erpId, 10);
                }

                if (k.erpDb === '') {
                    k.erpDb = null;
                }

                return k;
            },
        },
    }),
    fetchMultipleOverrides: [SecurityRole.RoleUser, SecurityRole.RoleOwner],
    lookupFields: {
        title: 'title',
        detailLeft: 'erpId',
    },
};
