
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';
import {GridColumnDefinition} from '@/types';

@Component({})
export default class ProgressColumn extends Vue {

    @Prop() public columnDefinition!: GridColumnDefinition;
    @Prop() public columnData!: any;

    private get formValue() {
        return this.columnData[this.columnDefinition.name];
    }

    @Watch('formValue')
    private scrollToThisLine() {
        const v = this.formValue;

        if (!this.$parent) {
            return;
        }

        const grid     = this.$parent.$refs.theGrid as HTMLElement;
        const gridBody = this.$parent.$refs.gridBody as HTMLElement;

        const thisEl   = this.$el as HTMLElement;
        const elParent = thisEl.parentElement as HTMLElement;

        const gridHeight   = grid.clientHeight;
        const scrollHeight = gridBody.scrollHeight;

        const newX = elParent.offsetTop - 240;

        if (v === 'loading') {
            gridBody.scrollTo({
                top: newX,
                behavior: 'smooth',
            });
        }
    }

}
