import { GenerateProviders } from '@/errorHandler';
import {
    EntityDefinition,
    EntityAttributesCollection,
    AttributeType,
    GUID,
    EntityReferenceString,
    SingleMeasurement,
} from '@/types';

export const Measurement: EntityDefinition<SingleMeasurement> = {
    primaryKey: 'title',
    name: 'measurement',
    pluralName: 'measurements',
    endpointName: 'measurements',
    isPrimaryResolver: true,
    routes: {
        single: 'settingsMeasurementsAddEdit',
        grid: 'settingsMeasurements',
    },
    providers: GenerateProviders('measurements', {
        entity: 'Measurement',
        params: {
            attributes: [
                'title',
                'Type',
                'unit',
                'position',
                'alarmLow',
                'alarmHigh',
                'deviceIds',
                'noAccessUsers',
                'alarmSubscribers',
                'relPlace',
                'relCustomer',
            ],
        },
    }),
    lookupFields: {
        title: ['relSensor', 'readTimestamp'],
        detailLeft: ['type', 'unit'],
    },
};
