
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class NiceBanner extends Vue {
    public get stillRelevant() {
        const current = new Date().getTime();
        const due = new Date(2023, 4, 11, 22, 30, 0).getTime();

        return due - current > 0;
    }
}
