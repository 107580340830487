import { Module } from 'vuex';

import { LoadingInfo, LoadingStoreState, LoginChangeType } from '@/types';

const formLoadingInfo: LoadingInfo = {
    isLoading: false,
    loadingFailed: false,

    progress: 0,

    loadingText: 'Nalaganje...',
    loadingFailureText: 'Napaka pri nalagnju vsebine',
};

const RootState: LoadingStoreState = {
    form: formLoadingInfo,

    init: false,
    initForceFullReload: false,
    initLoginChange: null,
    initLoadingReason: 'Nalaganje...',
};

const LoadingStore: Module<any, any> = {

    state: RootState,
    getters: {
        didAppInitSucceed(state: LoadingStoreState) {
            return state.init;
        },
        didAppLoginChange(state: LoadingStoreState) {
            return state.initLoginChange;
        },
        getInitLoadingReason(state: LoadingStoreState) {
            return state.initLoadingReason;
        },
        getInitForceFullReload(state: LoadingStoreState) {
            return state.initForceFullReload;
        },
    },
    mutations: {
        clearLoadingStore(state: LoadingStoreState) {
            state.form = {
                isLoading: false,
                loadingFailed: false,
                progress: 0,
                loadingText: 'Nalaganje...',
                loadingFailureText: 'Napaka pri nalagnju vsebine',
            };
        },

        appInitDoReloadData(state: LoadingStoreState, val: string) {
            if (!val || val === 'YES') {
                state.initForceFullReload = true;
            } else if (val === 'NO') {
                state.initForceFullReload = false;
            }
        },

        formLoadingChange(state: LoadingStoreState, change: LoadingInfo): void {

            // throw new Error('👌');

            state.form.progress = 0;
            if (change.progress) {
                state.form.progress = change.progress;
            }

            if (!change.loadingFailed) {
                state.form.loadingFailed = false;
            } else {
                state.form.loadingFailed = change.loadingFailed;
            }

            state.form.isLoading = change.isLoading;

            if (change.loadingFailureText) {
                state.form.loadingFailureText = change.loadingFailureText;
            }

            if (change.loadingText) {
                state.form.loadingText = change.loadingText;
            }

            // state.form = {
            //     ...state.form,
            //     ...change,
            // };
        },

        appInitCompleted(state: LoadingStoreState, success: boolean): void {
            state.initLoginChange = null;
            state.init = success;
        },

        appInitLoginChange(state: LoadingStoreState, ct: LoginChangeType): void {
            state.init = false;
            state.initLoginChange = { type: ct };
        },

        resetAppInitLoginChange(state: LoadingStoreState): void {
            state.init = false;
            state.initLoginChange = null;
        },

        setInitLoadingReason(state: LoadingStoreState, reason: string) {
            state.initLoadingReason = reason;
        },
    },

    actions: {
        async checkServerVersion() {

            let scriptID = '';

            return new Promise((resolve) => {

                scriptID = 'vcheckscript-' + (new Date()).getTime();
                // Cache buster
                const newScript = document.createElement('script');
                newScript.src = '/exactum_version.js?t=' + (new Date()).getTime();
                newScript.id = scriptID;
                newScript.onload = () => {
                    resolve(true);
                };
                document.body.appendChild(newScript);

            }).then(() => {

                const sv = (window as any).EXACTUM_SERVER_VERSION;
                const p = process.env;

                const el = document.getElementById(scriptID);
                if (el) {
                    document.body.removeChild(el);
                }

                // Dev env override.
                if (p.NODE_ENV === 'development') {
                    return true;
                }

                return sv === p.VUE_APP_VERSION;

            });
        },

        async checkAppVersionBlocking({ dispatch, commit }) {
            const res = await dispatch('checkServerVersion');
            if (!res) {
                commit('resetAppInitLoginChange');
            }
        },

    },

};

export default LoadingStore;
